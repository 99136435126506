import React from 'react'
import StyleClass from './transport.module.css'
import { Services } from '../../../Services';
import { SuccessSwal, warningSwal } from '../../../components/utils/ToastMessages';
const DeleteModal = ({ isOpen, setIsOpen, onClose, TransportNames, selectedId, setSelectedId }) => {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const deleteHandler = async () => {
        try {
            const res = await Services.deleteTransport("DELETE", null, token, selectedId);
            if (res.Status === 1) {
                SuccessSwal("Transport deleted successfully", res.Message);
                onClose();
                TransportNames();

            } else if (res.Status === 0) {
                warningSwal("Warning", res.Message);
            }
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <div className={StyleClass["reject_modal"]}>
            <div
                onClick={() => onClose()}
                className={StyleClass["overlaye"]}
            ></div>
            <div className={StyleClass["reject_modal_content"]}>
                <h2 className={StyleClass["modal_header"]}>Delete Transport</h2>
                <div className={StyleClass["reject_modal_body"]}>
                    <p>Are you sure you want to delete this Transport?</p>
                    <div className={StyleClass["button_group"]}>
                        <button onClick={onClose} className={StyleClass["reject_btn"]}>Cancel</button>
                        <button onClick={() => deleteHandler()} className={StyleClass["accept_btn"]}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal