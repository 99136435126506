// Authorization: `token ${token}`,
import { ToastError, warningSwal } from "./components/utils/ToastMessages";
const httpcall = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      ...token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data?.Status === 0 && data?.isTokenExpired === 1) {
        localStorage.clear();
        warningSwal("Warning", data?.Message);
        window.location.href = "/login";
      } else if (data?.Status === 0) {
        warningSwal("Warning", data?.Message);
        if (data?.Message === "Token expired") {
          window.location.href = "/";
          localStorage.clear();
        }
      }
      return data;
    });
};

//-----Token checking---//
const TokenCheck = (method, body, token) => {
  return httpcall("users/checkTokenIsValid", method, body, token);
};
//--------SignUp--------//
const signupOtp = (method, body) => {
  return httpcall("users/verifySignupMobileNumber", method, body);
};
const verifySignupOtp = (method, body) => {
  return httpcall("users/verifySignupOtp", method, body);
};
const UserSignup = (method, body) => {
  return httpcall("users/userSignup", method, body);
};
// Reset Password
const ResetPSW = (method, body, token) => {
  return httpcall("users/resetPassword", method, body, token);
};

const getCompanyTypes = (method, body) => {
  return httpcall("users/getCompanyTypes", method, body);
};
// Subcription
const Subscription = (method, body, token) => {
  return httpcall("users/getSubcriptionsTypes", method, body, token);
};

const SubcriptionPlan = (method, body, token) => {
  return httpcall("users/subscribeToplan", method, body, token);
};
const BillDetails = (method, body, token) => {
  return httpcall("users/getBillDetails", method, body, token);
};

const SubscriptionStatus = (method, body, token) => {
  return httpcall("users/getUserSubscriptionStatus", method, body, token);
};

const SubscriptionDetails = (method, body, token) => {
  return httpcall("users/getMySubscriptions", method, body, token);
};

const SingleSubscription = (method, body, token, id) => {
  return httpcall(`users/billDetailsById/${id}`, method, body, token);
};

const Conformation = (method, body, token) => {
  return httpcall("users/confirmSubcription", method, body, token);
};
const Subcriptiondetails = (method, body) => {
  return httpcall("users/getSubcriptionDetails", method, body);
};

const AddPaymentMode = (method, body, token) => {
  return httpcall("users/addPaymentTransaction", method, body, token);
};

const DeactivateSubscription = (method, body, token) => {
  return httpcall("users/deactivateSubscription", method, body, token);
};

const contactAdmin = (method, body) => {
  return httpcall("users/RecieveCostomerMail", method, body);
};

// ------------Notifications-------//
const UnreadNotifications = (method, body, token) => {
  return httpcall("manufacturer/unreadNotifications", method, body, token);
};
const ReadNotifications = (method, body, token) => {
  return httpcall("manufacturer/markAsReadNotifications", method, body, token);
};

//-------------Login -------------//
const loginOtp = (method, body) => {
  return httpcall("users/loginOtp", method, body);
};

const userlogin = (method, body) => {
  return httpcall("users/userlogin", method, body);
};

// -----------Manufacturer-------------------------//
const getUser = (method, body, token) => {
  return httpcall("manufacturer/getUser", method, body, token);
};

const EditProfile = (method, body, token) => {
  return httpcall(`manufacturer/updateProfile`, method, body, token);
};

const getGroups = (method, body, token) => {
  return httpcall("manufacturer/getGroups", method, body, token);
};

const getAllGroupsProducts = (method, body, token) => {
  return httpcall("manufacturer/allGroupsProducts", method, body, token);
};
const viewAllProducts = (method, body, token, groupID) => {
  return httpcall(
    `manufacturer/getGroupProducts/${groupID}`,
    method,
    body,
    token
  );
};
const AccountTyes = (method, body, token) => {
  return httpcall("distributor/getAccountTypes", method, body, token);
};

const getManageProducts = (method, body, token, status) => {
  return httpcall(`manufacturer/getProducts`, method, body, token);
};

const AddProduct = (method, body, token) => {
  return httpcall("manufacturer/addProduct", method, body, token);
};
// dropdowns
const productCategories = (method, body, token) => {
  return httpcall("manufacturer/getProductCategories", method, body, token);
};

const priceUnits = (method, body, token) => {
  return httpcall("manufacturer/getProductPriceUnits", method, body, token);
};

const getProduct = (method, body, token, productID) => {
  return httpcall(`manufacturer/getProduct/${productID}`, method, body, token);
};

const Editproduct = (method, body, token, productID) => {
  // if we change the format of this   it will shows  token empty error
  return httpcall(
    `manufacturer/updateProduct/${productID}`,
    method,
    body,
    token
  );
};

const deleteProduct = (method, body, token, productID) => {
  return httpcall(
    `manufacturer/deleteProduct/${productID}`,
    method,
    body,
    token
  );
};

const orderStatus = (method, body, token, status) => {
  return httpcall(
    `manufacturer/getSuplierOrdersStatus/${status}`,
    method,
    body,
    token
  );
};

const getOrderdetails = (method, body, token) => {
  return httpcall("manufacturer/getOrderDetails", method, body, token);
};

const updateOrderStatus = (method, body, token) => {
  return httpcall(
    `manufacturer/updateSuplierOrdersStatus`,
    method,
    body,
    token
  );
};

const approveAndrejectOrder = (method, body, token) => {
  return httpcall("manufacturer/rejectApproveOrders", method, body, token);
};

const updatePayment = (method, body, token) => {
  return httpcall("manufacturer/updatePayment", method, body, token);
};

const getGroupUsers = (method, body, token, groupID) => {
  return httpcall(`manufacturer/getGroupUsers/${groupID}`, method, body, token);
};

const getOwnUsers = (method, body, token) => {
  return httpcall("manufacturer/getOwnUsers", method, body, token);
};

const getUsers = (method, body, token) => {
  return httpcall("manufacturer/getUsers", method, body, token);
};
const AddOwnUsers = (method, body, token) => {
  return httpcall("manufacturer/addUsers ", method, body, token);
};

const createGroup = (method, body, token) => {
  return httpcall("manufacturer/createGroup", method, body, token);
};

const AddGroupProducts = (method, body, token) => {
  return httpcall("manufacturer/addProductsToGroup", method, body, token);
};

const getSingleGroupUser = (method, body, token, groupId, userId) => {
  return httpcall(
    `manufacturer/getSingleGroupUser/${groupId}/${userId}`,
    method,
    body,
    token
  );
};
const EditGroupName = (method, body, token) => {
  return httpcall("manufacturer/editGroupName", method, body, token);
};

const userPermissions = (method, body, token, userId) => {
  return httpcall(
    `manufacturer/getUserPermissions/${userId}`,
    method,
    body,
    token
  );
};

const updatePermissions = (method, body, token) => {
  return httpcall(
    "manufacturer/addOrRemoveStaffPermissions",
    method,
    body,
    token
  );
};

const deleteGroupUser = (method, body, token) => {
  return httpcall("manufacturer/removeUser", method, body, token);
};

const deleteGroup = (method, body, token, groupId) => {
  return httpcall(`manufacturer/deleteGroup/${groupId}`, method, body, token);
};

const ownUserDetails = (method, body, token, userId) => {
  return httpcall(
    `manufacturer/getOwnUserDetails/${userId}`,
    method,
    body,
    token
  );
};

const getSingleGroupProduct = (method, body, token, groupId, productId) => {
  return httpcall(
    `manufacturer/getSingeleGroupProduct/${groupId}/${productId}`,
    method,
    body,
    token
  );
};
const AddPrice = (method, body, token) => {
  return httpcall("manufacturer/addPrice", method, body, token);
};

const updatePrice = (method, body, token, productPricesId) => {
  return httpcall(
    `manufacturer/updatePrice/${productPricesId}`,
    method,
    body,
    token
  );
};

// payments apI
const pendingPayments = (method, body, token) => {
  return httpcall("manufacturer/getPendingPayments", method, body, token);
};
// Notifications  //

const Notifications = (method, body, token) => {
  return httpcall("manufacturer/getNotifications", method, body, token);
};

const Faqs = (method, body, token) => {
  return httpcall("manufacturer/getFAQs", method, body, token);
};

const searchUsers = (method, body, token, uid) => {
  return httpcall(
    `manufacturer/searchUsers?searchUID=${uid}`,
    method,
    body,
    token
  );
};

const UserActiveInActive = (method, body, token, status) => {
  return httpcall(
    `manufacturer/isActiveOrinActive/${status}`,
    method,
    body,
    token
  );
};

const searchOwnUers = (method, body, token) => {
  return httpcall("manufacturer/searchOwnUsers", method, body, token);
};

// Tickets apis

const Tickets = (method, body, token, status) => {
  return httpcall(
    `manufacturer/getTicketsByStatus/${status}`,
    method,
    body,
    token
  );
};
const getTicket = (method, body, token, id) => {
  return httpcall(`manufacturer/getTicketsById/${id}`, method, body, token);
};

const CreateTickect = (method, body, token) => {
  return httpcall("manufacturer/raiseTicket", method, body, token);
};

// Permissions for Office

const OfficePermissions = (method, body, token) => {
  return httpcall("users/getPermissions", method, body, token);
};

// Send Reminder
const SendReminder = (method, body, token) => {
  return httpcall("manufacturer/sendReminderWeb", method, body, token);
};

//  Banner  images   api

const BannerImage = (method, body, token, imagesId) => {
  return httpcall(
    `manufacturer/getDashBoardImageById/${imagesId}`,
    method,
    body,
    token
  );
};

const BannerEdit = (method, body, token, imagesId) => {
  return httpcall(
    `manufacturer/updateDashBoardImage/${imagesId}
  `,
    method,
    body,
    token
  );
};
// const BannerDelete = (method, body, token, imagesId) => {
//   return httpcall(
//     `manufacturer/deletedashBoardImage/${imagesId}`,
//     method,
//     body,
//     token
//   );
// };

const AddBanner = (method, body, token) => {
  return httpcall("manufacturer/addDashBoardImage", method, body, token);
};

// update qty
const updateQTY = (method, body, token) => {
  return httpcall("manufacturer/updateProductQty", method, body, token);
};

// AddDiscount

const AddFinalAmount = (method, body, token, orderId) => {
  return httpcall(
    `manufacturer/addFinalAmount/${orderId}`,
    method,
    body,
    token
  );
};

//  SalesApi

const SalesApi = (method, body, token) => {
  return httpcall("manufacturer/getSalesReport", method, body, token);
};

const AllUserslist = (method, body, token, status) => {
  return httpcall(`manufacturer/getAllUsers/${status}`, method, body, token);
};

const BuyerIndividualdata = (method, body, token) => {
  return httpcall("manufacturer/getIndividualBuyerReport", method, body, token);
};

// Phase - 2

const AddTransport = (method, body, token) => {
  return httpcall("manufacturer/addTransport", method, body, token);
};

const getTransports = (method, body, token) => {
  return httpcall("manufacturer/getTransports", method, body, token);
};

const EditTransport = (method, body, token, Id) => {
  return httpcall(`manufacturer/updateTransports/${Id}`, method, body, token);
};
const deleteTransport = (method, body, token, Id) => {
  return httpcall(`manufacturer/deleteTransports/${Id}`, method, body, token);
};

const ProductSubCategories = (method, body, token, Id) => {
  return httpcall(`manufacturer/getSubCategories/${Id}`, method, body, token);
};

const PackingBags = (method, body, token) => {
  return httpcall(`manufacturer/getPackingBags`, method, body, token);
};

const OrderTypes = (method, body, token) => {
  return httpcall(`manufacturer/getOrderType`, method, body, token);
};

const DeliveryTypes = (method, body, token) => {
  return httpcall(`manufacturer/getDeliveryType`, method, body, token);
};

const OrderThrough = (method, body, token) => {
  return httpcall(`manufacturer/getDeliveryThrough`, method, body, token);
};

const UpdateOrder = (method, body, token) => {
  return httpcall(`manufacturer/updateOrder`, method, body, token);
};

const PaymentTypes = (method, body, token) => {
  return httpcall(`distributor/getPaymentMethods`, method, body, token);
};

// Dashbord Apis
const DashbordUsersList = (method, body, token, accountType) => {
  return httpcall(
    `distributor/getDashBoardUsers/${accountType}`,
    method,
    body,
    token
  );
};

const UserOrdersList = (method, body, token, userId, tranport) => {
  return httpcall(
    `distributor/getOrdersList/${userId}?transport=${tranport}`,
    method,
    body,
    token
  );
};

const AccountTypes = (method, body, token) => {
  return httpcall(`distributor/getAccountTypes`, method, body, token);
};

const DeleteOrderItems = (method, body, token) => {
  return httpcall(`distributor/deleteOrederedItems`, method, body, token);
};
const QualityDifference = (method, body, token) => {
  return httpcall(`distributor/riseQualityDiffrence`, method, body, token);
};

export const Services = {
  TokenCheck,
  ResetPSW,
  signupOtp,
  verifySignupOtp,
  getCompanyTypes,
  UserSignup,
  loginOtp,
  userlogin,
  // manufacturer apis
  getUser,
  EditProfile,
  getGroups,
  getAllGroupsProducts,
  viewAllProducts,
  AddProduct,
  getProduct,
  Editproduct,
  productCategories,
  priceUnits,
  deleteProduct,
  getManageProducts,
  orderStatus,
  getGroupUsers,
  getOwnUsers,
  getUsers,
  AddOwnUsers,
  createGroup,
  AddGroupProducts,
  getSingleGroupUser,
  userPermissions,
  updatePermissions,
  deleteGroupUser,
  ownUserDetails,
  getSingleGroupProduct,
  AddPrice,
  updatePrice,
  pendingPayments,
  getOrderdetails,
  AccountTyes,
  updateOrderStatus,
  approveAndrejectOrder,
  updatePayment,
  Notifications,
  searchUsers,
  Subscription,
  SubscriptionStatus,
  deleteGroup,
  searchOwnUers,
  UserActiveInActive,
  SubcriptionPlan,
  BillDetails,
  SubscriptionDetails,
  Subcriptiondetails,
  SingleSubscription,
  Conformation,
  AddPaymentMode,
  DeactivateSubscription,
  Faqs,
  Tickets,
  CreateTickect,
  SendReminder,
  getTicket,
  OfficePermissions,
  contactAdmin,
  BannerImage,
  BannerEdit,
  // BannerDelete,
  AddBanner,
  updateQTY,
  AddFinalAmount,
  EditGroupName,
  UnreadNotifications,
  ReadNotifications,
  SalesApi,
  AllUserslist,
  BuyerIndividualdata,

  // Phase - 2
  AddTransport,
  getTransports,
  EditTransport,
  deleteTransport,

  ProductSubCategories,
  PackingBags,

  OrderTypes,
  DeliveryTypes,
  OrderThrough,
  UpdateOrder,
  PaymentTypes,

  // Dashboard
  DashbordUsersList,
  UserOrdersList,
  AccountTypes,
  DeleteOrderItems,
  QualityDifference,
};
