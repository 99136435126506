import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import UserClasses from "../../pages/Users/user.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { SuccessSwal, ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import Search from "../../assets/homeimgs/search.png";
import { override } from "../Home/helper";
import { InfinitySpin } from "react-loader-spinner";

const AddOwnUsers = () => {
  const Navigate = useNavigate();
  const [usersData, setUsersData] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const accountType = localStorage.getItem("AccountType");
  const handleCheckboxChange = (user) => {
    const updatedUsers = selectedUsers.includes(user)
      ? selectedUsers.filter(
        (selectedUser) => selectedUser.userId !== user.userId
      )
      : [...selectedUsers, user];

    setSelectedUsers(updatedUsers);
  };

  const AddUsers = async () => {
    if (selectedUsers.length === 0) {
      alert("Please select users ");
      return;
    }

    const body = {
      users: selectedUsers,
    };
    try {
      const res = await Services.AddOwnUsers(
        "POST",
        JSON.stringify(body),
        token
      );

      if (res.Status === 1) {
        console.log(res.Message, selectedUsers);
        Navigate(-1);
        SuccessSwal("Success", res.Message);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    } finally {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    let getUID;
    if (uid && uid.length >= 7) {
      setLoading(true);
      getUID = setTimeout(() => {
        setLoading(true);
        Services.searchUsers("GET", null, token, uid)
          .then((res) => {
            setLoading(false);
            if (res.Status === 1) {
              setUsersData(res.users);
            }
          })
          .catch((err) => {
            alert(
              "Something went wrong while fetching user details. Please try again."
            );
            setLoading(false);
            console.error(err);
          });
      }, 1000);
    } else {
      setLoading(false);
      setUsersData(null);
    }
    return () => clearTimeout(getUID);
  }, [uid]);

  const getBackgroundColor = (index) => {
    const colors = ["#2EADFF", "#FF8780", "#AEF368", "#FBBC05"];
    return colors[index % colors.length];
  };

  return (
    <Layout Active={"Users & Groups"}>
      {accountType == 4 || accountType == 5 ? (
        <div className={UserClasses["Buyer_wrapper"]}>
          <div className={UserClasses["Add_user_container"]}>
            <div className={UserClasses["Add__user_header"]}>
              <button
                className={UserClasses["Add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={UserClasses["Add_user_header_h1"]}>Add Users</h1>
            </div>
            <div className={UserClasses["Add_users_form_container"]}>
              <div className={UserClasses["Add_users_form"]}>
                <div className={UserClasses["Add-serch-usear"]}>
                  <div className={UserClasses["e_image_container"]}>
                    <img
                      className={UserClasses["view_search_img"]}
                      src={Search}
                      alt="Search"
                    />
                  </div>
                  <input
                    type={"text"}
                    placeholder="Search by UID"
                    value={uid}
                    // onChange={(evenet) => setUid(evenet.target.value)}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const regex = /^[0-9]*$/;
                      if (regex.test(inputValue)) {
                        const numberValue = parseInt(inputValue, 10);
                        setUid(inputValue);
                      } else {
                        alert("UID must be a number");
                      }
                    }}
                  />
                </div>
                <div className={UserClasses["users_select_c"]}>
                  {loading ? (
                    <div className={UserClasses["loader"]}>
                      <InfinitySpin
                        color={"#4285f4"}
                        loading={true}
                        css={override}
                        width={100}
                        height={100}
                        size={100}
                      />
                    </div>
                  ) : usersData && usersData.length > 0 ? (
                    usersData.map((user, index) => (
                      <div className={UserClasses["user_add_container"]}>
                        <div
                          key={user.userId}
                          className={UserClasses["user_checkbox_container"]}
                        >
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(user)}
                            checked={selectedUsers.some(
                              (selectedUser) => selectedUser.userId === user.userId
                            )}
                          />
                          <div className={UserClasses["Add_users_info_box"]}>
                            <div className={UserClasses["Add_image_container"]}>
                              {user.Photo !== "NA" ? (
                                <img
                                  className={UserClasses["Add_user_image"]}
                                  src={user.Photo}
                                  alt="image"
                                />
                              ) : (
                                <div className={UserClasses["Add_user_initial"]} style={{ backgroundColor: getBackgroundColor(index) }}>
                                  {user.name ? user.name.charAt(0) : ""}
                                </div>
                              )}
                            </div>
                            <div className={UserClasses["Add_users_info_text"]}>
                              <p id={UserClasses["Add_companytype"]}>{user.name}</p>
                              <p id={UserClasses["Add_companytype"]}>
                                {user.businessName}
                              </p>
                              <p id={UserClasses["user_UID"]}>UID: {user.UID}</p>
                              <p id={UserClasses["Add_number"]}>
                                {user.mobileNumber}
                              </p>
                              <p id={UserClasses["Add_a_type"]}>
                                {user.accountType === 1
                                  ? "Manufacturer "
                                  : user.accountType === 2
                                    ? "Distributor "
                                    : user.accountType === 3
                                      ? "Retailer"
                                      : user.accountType === 4
                                        ? "Marketing Specialist"
                                        : user.accountType === 5
                                          ? "Agents"
                                          : user.accountType === 6
                                            ? "Office Team "
                                            : "null"}
                              </p>
                              <p id={UserClasses["add_address"]}>
                                Place: {user.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p id={UserClasses["users_loading"]}></p>
                  )}
                  <button
                    className={UserClasses["Add_users_btn"]}
                    onClick={() => AddUsers()}
                    disabled={addLoading}
                  >
                    {addLoading ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={UserClasses["User_wrapper"]}>
            <div className={UserClasses["Add_user_container"]}>
              <div className={UserClasses["Add__user_header"]}>
                <button
                  className={UserClasses["Add_back_btn"]}
                  type="button"
                  onClick={() => {
                    Navigate(-1);
                  }}
                >
                  <GoArrowLeft />
                </button>
                <h1 id={UserClasses["Add_user_header_h1"]}>Add Users</h1>
              </div>
              <div className={UserClasses["Add_users_form_container"]}>
                <div className={UserClasses["Add_users_form"]}>
                  <div className={UserClasses["Add-serch-usear"]}>
                    <div className={UserClasses["e_image_container"]}>
                      <img
                        className={UserClasses["view_search_img"]}
                        src={Search}
                        alt="Search"
                      />
                    </div>
                    <input
                      type={"text"}
                      placeholder="Search by UID"
                      value={uid}
                      // onChange={(evenet) => setUid(evenet.target.value)}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const regex = /^[0-9]*$/;
                        if (regex.test(inputValue)) {
                          const numberValue = parseInt(inputValue, 10);
                          setUid(inputValue);
                        } else {
                          alert("UID must be a number");
                        }
                      }}
                    />
                  </div>
                  {loading ? (
                    <div className={UserClasses["loader"]}>
                      <InfinitySpin
                        color={"#4285f4"}
                        loading={true}
                        css={override}
                        width={100}
                        height={100}
                        size={100}
                      />
                    </div>
                  ) : usersData ? (
                    usersData.map((user, index) => (
                      <div className={UserClasses["user_add_container"]}>
                        <div
                          key={user.userId}
                          className={UserClasses["user_checkbox_container"]}
                        >
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(user)}
                            checked={selectedUsers.some(
                              (selectedUser) =>
                                selectedUser.userId === user.userId
                            )}
                          />
                          <div className={UserClasses["Add_users_info_box"]}>
                            <div className={UserClasses["Add_image_container"]}>
                              {user.Photo !== "NA" ? (
                                <img
                                  className={UserClasses["Add_user_image"]}
                                  src={user.Photo}
                                  alt="image"
                                />
                              ) : (
                                <div className={UserClasses["Add_user_initial"]}
                                  style={{ backgroundColor: getBackgroundColor(index) }}>
                                  {user.name ? user.name.charAt(0) : ""}
                                </div>
                              )}
                            </div>
                            <div className={UserClasses["Add_users_info_text"]}>
                              <p id={UserClasses["Add_companytype"]}>
                                {user.name}
                              </p>
                              <p id={UserClasses["Add_companytype"]}>
                                {user.businessName}
                              </p>
                              <p id={UserClasses["user_UID"]}>UID: {user.UID}</p>
                              <p id={UserClasses["Add_number"]}>
                                {user.mobileNumber}
                              </p>
                              <p id={UserClasses["Add_a_type"]}>
                                {user.accountType === 1
                                  ? "Manufacturer "
                                  : user.accountType === 2
                                    ? "Distributor "
                                    : user.accountType === 3
                                      ? "Retailer"
                                      : user.accountType === 4
                                        ? "Marketing Specialist"
                                        : user.accountType === 5
                                          ? "Agents"
                                          : user.accountType === 6
                                            ? "Office Team "
                                            : "null"}
                              </p>
                              <p id={UserClasses["add_address"]}>
                                Place: {user.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p id={UserClasses["users_loading"]}></p>
                  )}
                  <button
                    className={UserClasses["Add_users_btn"]}
                    onClick={() => AddUsers()}
                    disabled={addLoading}
                  >
                    {addLoading ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default AddOwnUsers;
