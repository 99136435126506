import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/layout'
import StyleClass from './dashboard.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Tabstyles from '../../components/Tabs/Tabs.module.css'
import { Services } from '../../Services'
import { RotatingLines } from 'react-loader-spinner'
import { override } from '../Home/helper'
import Permissionimg from '../../assets/NoPermissions.png'
const Dashboard = () => {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dashboardLoading, setDashboardLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState('');
    const [dashbordUsers, setDashboardUsers] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const localAccountType = Number(localStorage.getItem('AccountType'));

    useEffect(() => {
        getAccountTypes();
    }, []);

    useEffect(() => {
        const getPermissions = async () => {
            try {
                const res = await Services.OfficePermissions("GET", null, token);
                if (res.Status === 1) {
                    localStorage.setItem("permissions", JSON.stringify(res.permissions));
                }
            } catch (err) {
                console.error(err);
            }
        };
        getPermissions();
    }, []);

    // Permissions for Agent office team
    const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
    const addPermissions = addPermissionsdata ? addPermissionsdata : [];
    const DashPermission = addPermissions.filter(
        (item) => item.permissionId === 19
    );
    const Permission = DashPermission.length > 0 ? DashPermission[0].status : 1;

    const getAccountTypes = async () => {
        try {
            const res = await Services.AccountTyes("GET", null, token);
            setDashboardLoading(false);
            if (res.Status === 1 && res.accounts.length > 0) {
                let data = res.accounts.map(account => {
                    if (account.accountType === 5 && account.accountType === localAccountType) {
                        return { ...account, accountName: 'Sub Broker' };
                    }
                    return account;
                });

                setAccountTypes(data);
                const defaultTab = searchParams.get('tab') || res.accounts[0].accountName;
                setActiveTab(defaultTab);

                const defaultAccountType = getAccountTypeByName(defaultTab, data);
                fetchDashboardUsers(defaultAccountType);
            } else {
                console.error('Failed to load account types or no account types found');
            }
        } catch (err) {
            console.error('Error fetching account types:', err);
        }
    };

    const getAccountTypeByName = (accountName, accounts = accountTypes) => {
        const account = accounts.find(acc => acc.accountName === accountName);

        if (account) {
            localStorage.setItem("selectedTab", JSON.stringify(account));
            return account.accountType;
        }
        return null;
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        const selectedAccount = accountTypes.find(account => account.accountName === tab);
        if (selectedAccount) {
            localStorage.setItem('selectedTab', JSON.stringify(selectedAccount));
            fetchDashboardUsers(selectedAccount.accountType);
        }
    };

    useEffect(() => {
        if (activeTab) {
            searchParams.set('tab', activeTab);
            setSearchParams(searchParams);
        }
    }, [activeTab, searchParams, setSearchParams]);

    const fetchDashboardUsers = async (accountType) => {
        if (!accountType) {
            console.error('Invalid account type:', accountType);
            return;
        }

        setLoading(true);
        try {
            const res = await Services.DashbordUsersList("GET", null, token, accountType);
            setLoading(false);
            if (res.Status === 1) {
                setDashboardUsers(res.users);
            }
        } catch (err) {
            setLoading(false);
            console.error('Error fetching dashboard users:', err);
        }
    };

    const Orders = (userId) => {
        Navigate(`/buyerOrders/${userId}`, { state: activeTab });
        console.log(userId);
    };
    // <div className={StatusStyles["Order_container"]}>
    //         {accountType == 6 && PermissionStatus == 0 ? (
    //           <div className={StatusStyles["permission_container"]}>
    //             <h2>
    //               we,re sorry, But you dont have required permissions to access
    //               this page.Please contact the site administrator.
    //             </h2>
    //             <img src={Permissionimg} alt="image" />
    //           </div>

    return (
        <Layout Active={"Dashboard"}>
            <div className={StyleClass.home_wrapper}>
                <div className={StyleClass["Order_container"]}>
                    {localAccountType === 6 && Permission == 0 ? (
                        <div className={StyleClass["permission_container"]}>
                            <h2>
                                we,re sorry, But you dont have required permissions to access
                                this page.Please contact the site administrator.
                            </h2>
                            <img src={Permissionimg} alt="image" />
                        </div>
                    ) : (
                        <div className={StyleClass["Add_main_container"]}>
                            <div className={StyleClass["Add_header"]}>
                                <h1 id={StyleClass["add_header_h2"]}>Dashboard</h1>
                            </div>
                            {/* Tabs Navigator */}
                            <div className={Tabstyles["Navigator"]}>
                                {accountTypes.map((tab) => (
                                    <button
                                        key={tab.type}
                                        type="button"
                                        className={activeTab === tab.accountName ? Tabstyles["active"] : ""}
                                        onClick={() => handleTabChange(tab.accountName)}
                                    >
                                        {tab.accountName}
                                    </button>
                                ))}
                            </div>

                            {/* Content Area */}
                            <div className={StyleClass["wrapper"]}>
                                <div className={StyleClass["container"]}>
                                    <h1 id={StyleClass["add_header_h2"]}>{activeTab}</h1>

                                    {/* Loader */}
                                    {loading ? (
                                        <div className={StyleClass["loader"]}>
                                            <RotatingLines
                                                color="#4285f4"
                                                loading={true}
                                                size={100}
                                                width={100}
                                                height={100}
                                                strokeWidth={4}
                                                animationDuration={0.75}
                                            />
                                        </div>
                                    ) : (
                                        <div className={StyleClass["datacontainer"]}>
                                            {/* Dashboard Users */}
                                            {dashbordUsers.length > 0 ? (
                                                dashbordUsers.map((item, index) => (
                                                    <div key={index} className={StyleClass["data_item"]} onClick={() => Orders(item?.userId)}>
                                                        <p className={StyleClass.name}>{item?.businessName}</p>
                                                        <p>UID: {item?.UID}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={StyleClass.emptyaccounts}>
                                                    <p>No Users here</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>


        </Layout>
    )
}
export default Dashboard