import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import { Services } from "../../Services";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import { NavLink, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeClasses from "../../pages/Home/home.module.css";
import { override } from "./helper";
import emptygroups from "../../assets/emptygroups.png";
import emptyproducts from "../../assets/emptyproducts.png";
import { RotatingLines } from "react-loader-spinner";
import Image from '../../assets/noplot.png'
import BannerEdit from "./Banner/BannerEdit";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Home = () => {
  const [dashboardImages, setDashboardImages] = useState({});
  const [allGroupsProducts, setAllGroupsProducts] = useState([]);
  const [groupID, setGroupID] = useState();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [bussinessName, setBussinessName] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [isNew, setIsNew] = useState();
  const [cancelSubscription, setCancelSubscription] = useState();
  const [banners, setBanners] = useState({});

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");


  useEffect(() => {
    if (accountType == 1 || accountType == 2) {
      fetchSubscriptionStatus()
    }
  }, [accountType, token]);

  const fetchSubscriptionStatus = async () => {
    Services.SubscriptionStatus("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setIsNew(res.isNew);
        setPaymentStatus(res?.paymentStatus);
        setIsSubscribed(res?.isSubscribed);
        setCancelSubscription(res?.cancelSubscription);
        localStorage.setItem("paymentStatus", res?.paymentStatus);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const FreeTrailActivate = () => {
    Services.SubcriptionPlan("POST", null, token)
      .then((res) => {
        if (res.Status === 1) {
          SuccessSwal("Success", res.Message);
          fetchSubscriptionStatus();
        }
      }).catch((err) => {
        console.log(err);
        warningSwal("Warning", "Something Went Wrong");
      })
  }

  useEffect(() => {
    getcategories();
    if (accountType == 6) {
      getPermissions();
    }
  }, []);

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        setPermissions(res.permissions);
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
        localStorage.setItem("permissionAccount", res?.accountType);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getcategories = async () => {
    try {
      const res = await Services.getAllGroupsProducts("GET", null, token);
      setLoading(false);
      if (res.Status === 1) {
        setAllGroupsProducts(res.groupProducts);
        setDashboardImages(res.dashboardImages);
        setBussinessName(res.businessName);
        localStorage.setItem("officeBName", res.businessName);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const viewAllpage = (groupID) => {
    Navigate(`/viewall/${groupID}`);
  };

  const getProduct = (grouId, productId) => {
    Navigate(`/groupproductdetails/${grouId}/${productId}`);
  };

  const BannerModal = (item) => {
    setShowModal(true);
    setBanners(item)
  }

  return (
    <Layout Active={"Home"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color="#4285f4"
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["home_container"]}>

            {
              (isNew == 1 && (accountType == 1 || accountType == 2)) && (
                <div className={HomeClasses["subscription_container"]}>
                  <p id={HomeClasses["content_text"]}>Activate Free Trail to Access The All  Features</p>
                  <button className={HomeClasses["subs_btn"]} onClick={() => FreeTrailActivate()}>
                    Activate Now
                  </button>
                </div>
              )
            }
            {
              (accountType == 1 || accountType == 2) && (
                <>
                  {cancelSubscription === 1 ? (
                    paymentStatus === 0 ? (
                      <div className={HomeClasses["subscription_container"]}>
                        <p id={HomeClasses["content_text"]}>Subscription cancelled </p>
                        <p id={HomeClasses["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    ) : (
                      // sbscriptionStatusdata.isSubscribed === 0 && <button onClick={() => Navigate("/subscription")}>Subscribe Now</button>
                      ""
                    )
                  ) : cancelSubscription === 0 ? (
                    paymentStatus === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={HomeClasses["subscription_container"]}>
                        {/* <p id={HomeClasses["content_text"]}>Subscription cancelled </p> */}
                        <p id={HomeClasses["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    )
                  ) : cancelSubscription === 2 ? (
                    isSubscribed === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={HomeClasses["subscription_container"]}>
                        <p id={HomeClasses["content_text"]}>Subscribe A Plan To Access All The Features</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/subscription")}>
                          Subscribe
                        </button>
                      </div>
                    )
                  )
                    : cancelSubscription === 3 ? (
                      isSubscribed === 1 ? (
                        // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                        ""
                      ) : (
                        <div className={HomeClasses["subscription_container"]}>
                          <p id={HomeClasses["content_text"]}>Subscribe A Plan To Access All The Features</p>
                          <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/subscription")}>
                            Subscribe
                          </button>
                        </div>
                      )
                    ) : null}
                </>
              )
            }
            <div className={HomeClasses["home_dashbord_carousel"]}>

              {
                Object.keys(dashboardImages).length === 0 ? (
                  <div className={HomeClasses["home_slider"]}>
                    <img
                      className={HomeClasses["home_slider_img"]}
                      src={Image}
                      onClick={() => BannerModal()}
                      alt="image"
                    />
                  </div>
                ) : (
                  <div className={HomeClasses["home_slider"]}>
                    <img
                      className={HomeClasses["home_slider_img"]}
                      src={dashboardImages?.dashboardImg}
                      alt="Banner Image"
                      onClick={() => BannerModal(dashboardImages)}
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = Image;
                      }}
                    />
                  </div>
                )
              }
            </div>
            <div className={HomeClasses["home_products_container"]}>
              {allGroupsProducts.length === 0 ? (
                <div className={HomeClasses["empty_products"]}>
                  <div className={HomeClasses["empty_image_container"]}>
                    <img
                      className={HomeClasses["empty_image"]}
                      src={emptygroups}
                      alt="image"
                    />
                  </div>
                  <p className={HomeClasses["empty_portal_1"]}>
                    No groups to show, Create your first Group and add products
                    to define Price's
                  </p>
                </div>
              ) : (
                allGroupsProducts.map((productGroup, index) => {
                  return (
                    <div
                      className={HomeClasses["home_groups_container"]}
                      key={index}
                    >
                      <div className={HomeClasses["home_group_container"]}>
                        <div className={HomeClasses["home_group_div"]}>
                          <h2 id={HomeClasses["home_group_h2"]}>
                            {productGroup.groupName}
                          </h2>
                          <button
                            className={HomeClasses["home_group_btn"]}
                            type="button"
                            onClick={() => viewAllpage(productGroup.groupId)}
                          >
                            View All
                          </button>
                        </div>
                        <div className={HomeClasses["home_product_container"]}>
                          {productGroup.products.length === 0 ? (
                            <div className={HomeClasses["empty_products"]}>
                              <div
                                className={HomeClasses["empty_image_container"]}
                              >
                                <img
                                  className={HomeClasses["empty_image"]}
                                  src={emptyproducts}
                                  alt="image"
                                />
                              </div>
                              <p className={HomeClasses["empty_portal_1"]}>
                                No Products to show, Create your first Group and
                                add products to define Price's
                              </p>
                            </div>
                          ) : (
                            productGroup.products
                              ?.slice(0, 6)
                              .map((product, index) => {
                                return (
                                  <NavLink
                                    className={HomeClasses["view_manage_link"]}
                                    to={`/groupproductdetails/${productGroup.groupId}/${product.productId}`}
                                    key={index}
                                  >
                                    <div
                                      className={
                                        HomeClasses["home_product_box"]
                                      }
                                    >
                                      <div
                                        className={
                                          HomeClasses[
                                          "home_product_img_container"
                                          ]}>

                                        {
                                          product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={product.productImageList[0]}
                                              alt="image"
                                              onError={(e) => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = Image;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={Image}
                                              alt="default image"
                                            />
                                          )
                                        }
                                      </div>
                                      <div
                                        className={
                                          HomeClasses["product_info_c"]
                                        }>
                                        <p
                                          id={HomeClasses["home_product_text1"]}
                                        >
                                          {product.productName}
                                        </p>
                                        {
                                          product.stockQty === 0 && product.status === 2 ? <p className={HomeClasses.outtext}>Out of Stock</p> : <>

                                            {product.productBagWeightInKgs !== 0
                                              && (
                                                <p
                                                  id={
                                                    HomeClasses[
                                                    "home_product_text3"
                                                    ]}>
                                                  {product.productBagWeightInKgs}
                                                  KG'S/
                                                  {product.productMeasuringUnit}
                                                </p>
                                              )}
                                            <p className={HomeClasses["p_price"]}>
                                              ₹{product.price}/{" "}
                                              {product.productPriceUnit}
                                            </p>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </NavLink>
                                );
                              })
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
        {
          showModal &&
          <BannerEdit
            isOpen={showModal}
            setIsOpen={setShowModal}
            onClose={() => setShowModal(false)}
            getcategories={getcategories}
            banner={banners}
          />
        }
      </div>

    </Layout>
  );
};
export default Home;
