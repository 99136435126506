import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login/login";
import SignUp from "./pages/Login/signup";
import SignUpRegister from "./pages/Login/signupRegister";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TanyyaHomepage from "./components/LandingPage/TanyyaHomepage";
import ScrollToTop from "./components/utils/ScrollToTop";
import Home from "./pages/Home/home";
import ViewAll from "./pages/Home/ViewAll";
import ProductView from "./pages/Home/ProductView";
import ManageProducts from "./pages/Home/ManageProducts";
import AddProduct from "./pages/Home/AddProduct";
import EditProduct from "./pages/Home/EditProduct";
import OrderStatuses from "./pages/Home/OrderStatuses";
import SalesStatusses from "./pages/Home/SalesStatusses";
import Orderdetails from "./pages/Home/Orderdetails";
import Groups from "./pages/Users/Groups";
import Ownusers from "./pages/Users/users";
import AddOwnUsers from "./pages/Users/AddUsers";
import CreateGroup from "./pages/Users/CreateGroup";
import AddGroupProducts from "./pages/Users/AddGroupProducts";
import UserDetails from "./pages/Users/UserDetails";
import OwnUserdetails from "./pages/Users/OwnUserdetails";
import UserPermissions from "./pages/Users/UserPermissions";
import GroupproductDetails from "./pages/Users/GroupproductDetails";
import Shop from "./pages/Shop/Shop";
import Manufacturerpage from "./pages/Shop/Manufacturerpage";
import Notification from "./pages/Notifications/Notification";
import UserProfile from "./pages/Home/UserProfile";
import Subscription from "./pages/Login/Subscription";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Pricing from "./pages/Pricing/Pricing";
import Services from "./pages/Services/Services";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Privacy/Privacy";
import ConformPage from "./pages/Login/Subscription/ConformPage";
import SubscriptionConfirmation from "./pages/Login/Subscription/SubscriptionConfirmation";
import PayNow from "./pages/Login/Subscription/PayNow";
import PaymentSuccess from "./pages/Login/Subscription/PaymentSuccess";
import CartPage from "./pages/Shop/CartPage";
import Address from "./pages/Shop/Address";
import Faqs from "./pages/Home/Faqs/Faqs";
import AboutUs from "./pages/Home/Faqs/AboutUs";
import Support from "./pages/Home/Faqs/Support";
import Purchaseorders from "./pages/PurchaseOrders/Purchaseorders";
import PurchaseDetails from "./pages/PurchaseOrders/PurchaseDetails";
import OrderSuccessful from "./pages/Shop/OrderSuccessful";
import Agents from "./pages/Shop/Agents";
import BannerEdit from "./pages/Home/Banner/BannerEdit";
import Productdetails from "./pages/Shop/Productdetails";
import ResetPassword from "./pages/Login/ResetPassword";
import RecheckPassword from "./pages/Login/RecheckPassword";
import PosswordReset from "./pages/Home/Faqs/PosswordReset";
import EmptyPage from "./components/utils/EmptyPage";
import AllUsersList from "./pages/All users/AllUsersList";
import { isMobile } from "react-device-detect";
import useStore from "./store";
import Modal from "./components/utils/warning";
import Addtranport from "./pages/Home/TransportDetails/AddTransports/Addtranport";
import Dashboard from "./pages/Dashboard/Dashboard";
import Subcomponent from "./pages/Dashboard/Subcomponent";

function App() {
  const { isLoginMobile, setIsLoginMobile } = useStore();

  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        {/* comman routes */}
        <Route path="/" element={<TanyyaHomepage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/login" element={<Login />} />
        {!isMobile && (
          <>
            <Route
              path="/verificationcredentials"
              element={<ResetPassword />}
            />
            <Route path="/resetpassword" element={<RecheckPassword />} />
            {/* <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/registration" element={<SignUpRegister />} /> */}
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/Conformpage" element={<ConformPage />} />
            <Route
              path="/subscribeConformation"
              element={<SubscriptionConfirmation />}
            />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/paynow" element={<PayNow />} />
            {/* manufacture and distributor remaining routes */}
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/banners" element={<BannerEdit />} />
            <Route path="/User/" element={<UserProfile />} />
            <Route path="/User/:id" element={<UserProfile />} />
            <Route path="/viewall/:groupID" element={<ViewAll />} />
            <Route path="/viewall/:groupID?page=1" element={<ViewAll />} />
            <Route path="/OrderSuccessful" element={<OrderSuccessful />} />
            <Route
              path="/productview/:groupID/:productID"
              element={<ProductView />}
            />
            <Route path="/productview/:productID" element={<ProductView />} />
            <Route path="/Manage" element={<ManageProducts />} />
            <Route path="/ManageProducts/addProduct" element={<AddProduct />} />
            <Route path="/EditProduct/:productID" element={<EditProduct />} />
            <Route path="/Orders" element={<OrderStatuses />} />
            <Route path="/Sales" element={<SalesStatusses />} />
            <Route
              path="/orderdetails/:orderId/:status"
              element={<Orderdetails />}
            />
            <Route
              path="/purchaseDetails/:orderId/:status"
              element={<PurchaseDetails />}
            />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/rise-tickect" element={<Support />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/Users" element={<Groups />} />
            <Route path="/ownusers" element={<Ownusers />} />
            <Route path="/addownusers" element={<AddOwnUsers />} />
            <Route path="/createGroup" element={<CreateGroup />} />
            <Route
              path="/createGroup/:groupName/:groupId"
              element={<CreateGroup />}
            />
            <Route
              path="/Addgroupproduct/:groupName/:groupId"
              element={<AddGroupProducts />}
            />
            <Route
              path="/userdetails/:groupId/:userId"
              element={<UserDetails />}
            />
            <Route
              path="/ownuserdetails/:userId"
              element={<OwnUserdetails />}
            />
            <Route
              path="/userpermissions/:userId"
              element={<UserPermissions />}
            />
            <Route
              path="/groupproductdetails/:groupId/:productId"
              element={<GroupproductDetails />}
            />

            <Route path="/Shop" element={<Shop />} />
            <Route
              path="/manufacturer/:manufacturerId/:groupId"
              element={<Manufacturerpage />}
            />
            <Route
              path="/shopProduct/:groupId/:productId"
              element={<Productdetails />}
            />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/address" element={<Address />} />
            {/* <Route path="/agents" element={<Agents />} /> */}
            <Route path="/Notifications" element={<Notification />} />
            <Route path="/Purchase" element={<Purchaseorders />} />
            <Route path="/allusers" element={<AllUsersList />} />
            <Route path="/addTransport" element={<Addtranport />} />
            <Route path="/buyerOrders/:Id" element={<Subcomponent />} />
          </>
        )}
        {/* Terms and Privacy */}
        {/* <Route path="/Passwordreset" element={<PosswordReset/>}/> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route path="/privacy&policy" element={<Privacy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="*" element={<EmptyPage />} />
      </Routes>
    </>
  );
}

export default App;
