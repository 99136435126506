import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import LoginClasses from "../Login/login.module.css";
import Tanyya from "../../assets/login/tanyyalogin.png";
import Logo from "../../assets/login/Logotanyya.png";
import { useForm, Controller } from "react-hook-form";
import { SuccessSwal, ToastError } from "../../components/utils/ToastMessages";
import { ToastSuccess } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import { BiHide } from "react-icons/bi";
import { GrView } from "react-icons/gr";
import { GoArrowLeft } from "react-icons/go";

const RecheckPassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const Navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };
  const Submit = async (data) => {
    console.log(data);
    let reqdata = {
      newPassword: data.newPassword,
    };

    Services.ResetPSW(
      "POST",
      JSON.stringify(reqdata),
      token
    ).then((res) => {

      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        localStorage.clear();
        Navigate("/login");
      }
    }).catch((err) => {
      console.log(err);
    })
  };
  return (
    <>
      <div className={LoginClasses["login"]}>
        <div className={LoginClasses["model_image"]}>
          <img
            className={LoginClasses["Tanyya_image"]}
            src={Tanyya}
            alt="Tanyya image"
          />
          <div className={LoginClasses["hero_mask"]}>
            <div className={LoginClasses["model_content"]}>
              <h1 className={LoginClasses["div-row"]}>Possword Resetting</h1>
              <p className={LoginClasses["div-col"]}>
                Traders Ground is a B2B platform owned by Tanyya Tech Solutions
                LLP. The platform is designed to simplify the entire process for
                manufacturers and distributors.
              </p>
            </div>
          </div>
        </div>
        <div className={LoginClasses["login_maindiv"]}>
          <div className={LoginClasses.backscreen}>
            <button
              className={LoginClasses["Add_back_btn"]}
              type="button"
              onClick={() => {
                Navigate(-1);
                localStorage.clear();
              }}
            >
              <GoArrowLeft />
            </button>
          </div>
          <img
            className={LoginClasses["logoText"]}
            src={Logo}
            alt="Logo Text"
          />
          <div className={LoginClasses["login_div"]}>
            <h1 className={LoginClasses["login_h1"]}>Reset Password</h1>
            <form
              onSubmit={handleSubmit(Submit)}
              className={LoginClasses.formcontainer}
            >
              <div className={LoginClasses["password-input"]}>
                <label>New Password </label>
                <Controller
                  name="newPassword"
                  control={control}
                  rules={{
                    required: "New Password is required",
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type={hidePassword ? "text" : "password"}
                        placeholder="New password"
                        minLength={8}
                        maxLength={15}
                      />
                      {hidePassword ? (
                        <GrView onClick={() => setHidePassword(false)} />
                      ) : (
                        <BiHide onClick={() => setHidePassword(true)} />
                      )}
                    </>
                  )}
                />
                {errors.newPassword && <p>{errors.newPassword.message}</p>}
              </div>

              <div className={LoginClasses["password-input"]}>
                <label>Confirm Password </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === watch("newPassword") ||
                      "Passwords do not match",
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type={hidePassword ? "text" : "password"}
                        placeholder="Cofirm Password"
                        minLength={8}
                        maxLength={15}
                      />
                      {hidePassword ? (
                        <GrView onClick={() => setHidePassword(false)} />
                      ) : (
                        <BiHide onClick={() => setHidePassword(true)} />
                      )}
                    </>
                  )}
                />
                {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
              </div>
              <button type="submit" className={LoginClasses["S_btn"]}>
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecheckPassword;
