import React, { useEffect, useState } from 'react'
import HomeClasses from '../Home/home.module.css';
import { useForm } from 'react-hook-form';
import { ScaleLoader } from 'react-spinners';
import { Success } from '../../components/utils/ToastMessages';
import { Services } from '../../Services';
import { ShopServices } from '../Shop/ShopServicess';
import { RxCross2 } from 'react-icons/rx';
const QualityPriceChange = ({ orderDetails, priceModal, orderdetails, onClose }) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [pricess, setPricess] = useState({});
    const products = orderDetails?.Effectedproducts || [];
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [effectedPrice, setEffectedPrice] = useState([]);

    const handleQuantityChange = (productId, value) => {
        setPricess({
            ...pricess,
            [productId]: value
        });
        clearErrors(`productPrice_${productId}`);
    };
    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = async () => {
        try {
            const res = await ShopServices.ConfigDropdowns("GET", null, token);
            if (res.Status === 1) {
                console.log(res.config);
                setDropdownOptions(res.config);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onSubmit = async (data) => {
        let hasError = false;

        const productsData = products.map((product) => {
            const effectedPrice = pricess[product.productId] || 0;
            const originalPrice = product.price;
            let effectedpriceperQuintal = data[`effectedPricePerQuintal_${product.productId}`] || 0;

            if (effectedPrice >= originalPrice) {
                setError(`productPrice_${product.productId}`, {
                    type: "manual",
                    message: `Price must be less than the Product price of ${originalPrice}`
                });
                hasError = true;
            }
            return {
                oderedItemsId: product.oderedItemsId,
                productId: product.productId,
                effectedQuantity: product.effectedQuantity,
                effectedProductPrice: effectedPrice,
                effectedPricePerQuintal: effectedpriceperQuintal,
            };
        });

        if (hasError) return;

        const body = {
            orderId: orderDetails.orderId,
            products: productsData,
            agentQD: 1
        };
        setLoading(true);
        try {
            const res = await Services.QualityDifference("POST", JSON.stringify(body), token)
            setLoading(false);
            if (res.Status === 1) {
                onClose();
                Success(res.Message);
                orderdetails();
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (dropdownOptions.length > 0) {
            const EffectPrices = dropdownOptions?.find(
                (config) => config.configKey === "effectedPricePerQuintal"
            );

            if (EffectPrices) {
                let numbers = Array.from({ length: Number(EffectPrices.configValue) }, (_, i) => i + 1);
                setEffectedPrice(numbers);
                console.log(numbers)
            }
        }
    }, [dropdownOptions]);

    const calculatePrice = (product, effectedPricePerQuintal) => {
        const effectedWeight = product?.effectedWeightInQuintal || 0;
        return effectedPricePerQuintal * effectedWeight;
    };

    const handleDropdownChange = (product, value) => {
        const calculatedPrice = calculatePrice(product, value);
        handleQuantityChange(product.productId, calculatedPrice);
    };

    return (
        <div className={HomeClasses["reject_modal"]}>
            <div
                onClick={() => onClose()}
                className={HomeClasses["overlaye"]}
            ></div>
            <div className={HomeClasses["update_modal_QD"]}>
                <div className={HomeClasses["QD_header"]}>
                    <h2 id={HomeClasses["resct_header"]}>Quality Difference products</h2>
                    <button onClick={() => onClose()}><RxCross2 size={20} /></button>
                </div>
                <div className={HomeClasses["update_input_model_container"]}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={HomeClasses["banner_form"]}
                    >
                        {products.map((product, index) => (
                            <div key={product.id} className={HomeClasses["form-group"]}>
                                <div className={HomeClasses["Qualityproduct_wrapper"]}>
                                    <h3>{product.productName}</h3>
                                    <div className={HomeClasses["QD_Product_wrapper"]}>
                                        <div className={HomeClasses.Qd_product_info}>
                                            <p className={HomeClasses.category_wrapper}>{product?.productCategory}{" . "}{product?.productSubCategory}</p>
                                            <p className={HomeClasses.category_wrapper}>Qty : {product?.qty}</p>
                                            <p className={HomeClasses.category_wrapper}>Effected : {product?.effectedQuantity}</p>
                                        </div>
                                        {
                                            (product?.productPriceUnit === "Quintal" && product?.productPriceUnit === "Bag") &&
                                            <div className={HomeClasses.Qd_product_info}>
                                                <p className={HomeClasses.category_wrapper}>Total Quintal : {product?.qty}</p>
                                                <p className={HomeClasses.category_wrapper}>Effected Quintal : {product?.effectedQuantity}</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                                {(product?.productPriceUnit === "Quintal" && product?.productPriceUnit === "Bag") ? (
                                    <>
                                        <input
                                            type="number"
                                            step="0.01"
                                            value={pricess[product.productId] || ''}
                                            id={product.productId}
                                            min={0.1}
                                            placeholder="Enter price"
                                            readOnly
                                        />
                                        <div className={HomeClasses["dropdown"]}>
                                            <select className={HomeClasses.dropdwons}
                                                {...register(`effectedPricePerQuintal_${product.productId}`, {
                                                    required: false,
                                                    valueAsNumber: true,
                                                    onChange: (e) => handleDropdownChange(product, e.target.value),
                                                })}
                                            >
                                                <option value=""> Effected Price</option>
                                                {
                                                    effectedPrice.map((option, i) => (
                                                        <option key={i} value={option}>₹{option} per Quintal</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={pricess[product.productId] || ''}
                                        id={product.productId}
                                        min={0.1}
                                        placeholder="Enter price"
                                        onChange={(e) => handleQuantityChange(product.productId, e.target.value)}
                                    />
                                )}
                                {errors[`productPrice_${product.productId}`] && (
                                    <p className={HomeClasses["errors"]}>
                                        {errors[`productPrice_${product.productId}`].message}
                                    </p>
                                )}
                            </div>
                        ))}
                        <div className={HomeClasses["banner_form_btns"]}>
                            <button type="submit" className={HomeClasses["banner_e_btn"]} disabled={loading}>
                                {loading ? (
                                    <ScaleLoader
                                        color="#ffffff"
                                        height={20}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default QualityPriceChange