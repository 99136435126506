import { menuItems } from "../SideNav/config";

export const Permissions = (accountType) => {
  const aCCOUNT1 = [
    "Dashboard",
    "Home",
    "Users & Groups",
    "Manage products",
    "Sales Orders",
    "Sales Reports",
    "Notifications",
    "All Users",
    "Account",
  ];
  const aCCOUNT2 = [
    "Dashboard",
    "Home",
    "Shop",
    "Users & Groups",
    "Manage products",
    "Sales Orders",
    "Purchase Orders",
    "Sales Reports",
    "Notifications",
    "All Users",
    "Account",
  ];

  const aCCOUNT3 = [
    "Dashboard",
    "Shop",
    "Purchase Orders",
    "Notifications",
    "Account",
  ];
  const aCCOUNT4 = [
    "Dashboard",
    "Shop",
    "Customers",
    "Purchase Orders",
    "Account",
  ];
  const aCCOUNT5 = [
    "Dashboard",
    "Shop",
    "Customers",
    "Purchase Orders",
    "Account",
  ];
  const aCCOUNT6 = ["Dashboard", "Account"];
  const aCCOUNT7 = [
    "Dashboard",
    "Home",
    "Users & Groups",
    "Manage products",
    "Sales Orders",
    "Sales Reports",
    "Account",
  ];
  const aCCOUNT8 = [
    "Dashboard",
    "Home",
    "Shop",
    "Users & Groups",
    "Manage products",
    "Sales Orders",
    "Purchase Orders",
    "Sales Reports",
    "Account",
  ];

  const aCCOUNT9 = [
    "Dashboard",
    "Shop",
    "Customers",
    "Purchase Orders",
    "Account",
  ];

  let resultArray = [];
  if (accountType == 1) {
    resultArray = menuItems.filter((item) => aCCOUNT1.includes(item.name));
    return resultArray;
  } else if (accountType == 2) {
    resultArray = menuItems.filter((item) => aCCOUNT2.includes(item.name));
    return resultArray;
  } else if (accountType == 3) {
    resultArray = menuItems.filter((item) => aCCOUNT3.includes(item.name));
    return resultArray;
  } else if (accountType == 4) {
    resultArray = menuItems.filter((item) => aCCOUNT4.includes(item.name));
    return resultArray;
  } else if (accountType == 5) {
    resultArray = menuItems.filter((item) => aCCOUNT5.includes(item.name));
    return resultArray;
  } else if (accountType == 6) {
    resultArray = menuItems.filter((item) => aCCOUNT6.includes(item.name));
    return resultArray;
  } else if (accountType == 7) {
    resultArray = menuItems.filter((item) => aCCOUNT7.includes(item.name));
    return resultArray;
  } else if (accountType == 8) {
    resultArray = menuItems.filter((item) => aCCOUNT8.includes(item.name));
    return resultArray;
  } else if (accountType == 9) {
    resultArray = menuItems.filter((item) => aCCOUNT9.includes(item.name));
    return resultArray;
  }
};
