import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/Layout/layout'
import HomeClasses from '../../../Home/home.module.css'
import { useNavigate } from 'react-router-dom'
import { GoArrowLeft } from 'react-icons/go'
import StyleClass from '../transport.module.css'
import { useForm } from 'react-hook-form'
import { FaTruckMoving, FaUser } from 'react-icons/fa'
import { IoCall } from 'react-icons/io5'
import { LiaClipboardListSolid } from 'react-icons/lia'
import { BiPlusCircle } from 'react-icons/bi'
import { LuScale } from 'react-icons/lu'
import { RiCalendarEventFill } from 'react-icons/ri'
import { Services } from '../../../../Services'
import { SuccessSwal, warningSwal } from '../../../../components/utils/ToastMessages'

const Addtranport = () => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();
    const Navigate = useNavigate();
    const [transportDetails, setTransportDetails] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };


    useEffect(() => {
        TransportNames();
    }, [])
    const selecteduser = watch("name");
    const TransportNames = async () => {
        try {
            const res = await Services.getTransports("GET", null, token);
            if (res.Status === 1) {
                setTransportDetails(res.transports);
            } else if (res.Status === 0) {
                warningSwal("warning", res.Message);
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }
    useEffect(() => {
        if (selecteduser) {
            const temp = transportDetails.find(transport => transport.name === selecteduser)
            if (temp) {
                setValue("driverNumber", temp.mobileNumber);
            }
        }
    }, [selecteduser]);

    const onSubmit = (data) => {
        localStorage.setItem("Transport Details", JSON.stringify(data));
        SuccessSwal("Success", "Transport Details Added Successfully");
        Navigate("/cart");
        console.log(data);
    };

    return (
        <Layout>
            <div className={HomeClasses["home_wrapper"]}>
                <div className={HomeClasses["Add_main_container"]}>
                    <div className={HomeClasses["Add_header"]}>
                        <button
                            className={HomeClasses["add_back_btn"]}
                            type="button"
                            onClick={() => {
                                Navigate(-1);
                            }}
                        >
                            <GoArrowLeft />
                        </button>
                        <h1 id={HomeClasses["add_header_h2"]}>Transportation Details</h1>
                    </div>
                    <div className={StyleClass["form_wrapper"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={StyleClass["form-control"]}>
                                <select
                                    placeholder='Name'
                                    {...register("name", {
                                        required: "this field is required",
                                    })}

                                >
                                    <option value="">Select Username</option>
                                    {transportDetails.map((item) => (
                                        <option key={item.name} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <FaUser className={StyleClass.inputicon} />
                                {errors.name && <span>{errors.name.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    placeholder='Vehicle Number '
                                    {...register("vehicaleNumber", {
                                        required: "this field is required",
                                        pattern: {
                                            value: /^[A-Z0-9-]+$/,
                                            message: "Invalid vehicle number format"
                                        },
                                    })}
                                    maxLength={10}
                                />
                                <FaTruckMoving className={StyleClass.inputicon} />
                                {errors.vehicaleNumber && <span>{errors.vehicaleNumber.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    placeholder='Driver Number'
                                    {...register("driverNumber", {
                                        required: "this field is required",
                                        pattern: {
                                            value: /^[6-9]\d{9}$/,
                                            message: "Mobile number must start with 6-9 and be exactly 10 digits"
                                        }
                                    })}
                                    maxLength={10}
                                />
                                <IoCall className={StyleClass.inputicon} />
                                {errors.driverNumber && <span>{errors.driverNumber.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    type='number'
                                    step="0.01"
                                    placeholder='Pre Booking (LF)'
                                    {...register("preBooking", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                    min={1}
                                />
                                <LiaClipboardListSolid className={StyleClass.inputicon} />
                                {errors.preBooking && <span>{errors.preBooking.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    type='number'
                                    step="0.01"
                                    placeholder='Post Booking (LF)'
                                    {...register("postBooking", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                    min={1}
                                />
                                <LiaClipboardListSolid className={StyleClass.inputicon} />
                                {errors.postBooking && <span>{errors.postBooking.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    type='number'
                                    step="0.01"
                                    placeholder='Difference Booking (LF)'
                                    {...register("differenceBooking", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                    min={1}
                                />
                                <LiaClipboardListSolid className={StyleClass.inputicon} />

                                {errors.differenceBooking && <span>{errors.differenceBooking.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    type='number'
                                    step="0.01"
                                    placeholder='Total (LF)'
                                    {...register("total", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                    min={1}
                                />
                                <BiPlusCircle className={StyleClass.inputicon} />
                                {errors.total && <span>{errors.total.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    min={1}
                                    placeholder='Advance (LF)'
                                    {...register("advance", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                />
                                <RiCalendarEventFill className={StyleClass.inputicon} />
                                {errors.advance && <span>{errors.advance.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    type='number'
                                    step="0.01"
                                    placeholder='Balance ( LF)'
                                    {...register("balance", {
                                        required: "this field is required",
                                        valueAsNumber: true
                                    })}
                                    min={1}
                                />
                                <LuScale className={StyleClass.inputicon} />
                                {errors.advance && <span>{errors.advance.message}</span>}
                            </div>
                            {/* <div className={StyleClass["form-control"]}>
                                <input
                                    placeholder='Cash Discount Days (CD/CC)'
                                    {...register("UserName", {
                                        required: "this field is required",
                                    })}
                                />
                                <BiSolidDiscount className={StyleClass.inputicon} />
                                {errors.UserName && <span>{errors.UserName.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    placeholder='Hamali Charges (Quintal)'
                                    {...register("UserName", {
                                        required: "this field is required",
                                    })}
                                />
                                <FaPersonWalking className={StyleClass.inputicon} />
                                {errors.UserName && <span>{errors.UserName.message}</span>}
                            </div>
                            <div className={StyleClass["form-control"]}>
                                <input
                                    placeholder='Quality Difference (QD per Quintal )'
                                    {...register("UserName", {
                                        required: "this field is required",
                                    })}
                                />
                                <PiMedalFill className={StyleClass.inputicon} />
                                {errors.UserName && <span>{errors.UserName.message}</span>}
                            </div> */}
                            <div className={StyleClass["Addtransport"]}>
                                <button type='submit' className={StyleClass.button}>Add</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default Addtranport