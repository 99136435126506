import React, { useEffect, useState } from "react";
import styles from "../../Login/Subscription/subscription.module.css";
import Tanyya from "../../../assets/login/tanyyalogin.png";
import Logo from "../../../assets/login/Logotanyya.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import {
  SuccessSwal,
  ToastError,
  ToastSuccess,
} from "../../../components/utils/ToastMessages";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import { ImCheckmark } from "react-icons/im";
import { Permissions } from "../../../components/utils/Pernissions";

const PaymentSuccess = () => {
  const [billdetails, setBilldetails] = useState(JSON.parse(localStorage.getItem("BillDetails")));
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [accountDeactivatedModal, setAccountDeactivatedModal] = useState(false);
  const Navigate = useNavigate();
  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };
  const fromDate = moment(billdetails.fromDate).format("YYYY-MM-DD");
  const toDate = moment(billdetails.toDate).format("YYYY-MM-DD");
  const accountType = localStorage.getItem("AccountType");

  const deactivateOpen = () => {
    setAccountDeactivatedModal(true);
    setPaymentSuccessModal(false);
  };

  const subscriptionOpen = () => {
    setAccountDeactivatedModal(false);
    setPaymentSuccessModal(true);
  };

  const handleNavigate = () => {
    Navigate(Permissions(accountType)[0].menuLink);
  };

  const DeactivatePlan = async () => {

    Services.DeactivateSubscription("POST", null, token).then((res) => {
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        Navigate("/");
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  return (
    <div className={styles["login"]}>
      <div className={styles["model_image"]}>
        <img
          className={styles["Tanyya_image"]}
          src={Tanyya}
          alt="Tanyya image"
        />
        <div className={styles["hero_mask"]}>
          <div className={styles["model_content"]}>
            <h1 className={styles["div-row"]}>Pay Your Bills Here</h1>
            <p className={styles["div-col"]}>
              Traders Ground is a B2B platform owned by Tanyya Tech Solutions
              LLP. The platform is designed to simplify the entire process for
              manufacturers and distributors.
            </p>
          </div>
        </div>
      </div>
      <div className={styles["login_maindiv"]}>
        <img className={styles["logoText"]} src={Logo} alt="Logo Text" />
        <div className={styles["login_box_div"]}>
          <div className={styles["login_text_container"]}>
            <div className={styles["confirm_check"]}>
              <div className={styles["check_mark"]}>
                <p className={styles["check_container"]}>
                  <ImCheckmark className={styles["tick_mark"]} />
                </p>
              </div>
            </div>
            <h2 className={styles["subscription_text"]}>Payment Successfull</h2>
          </div>
          <div className={styles["bill_container"]}>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Cycle</p>
              <p className={styles["bill_cycle_text"]}>
                {fromDate}
                {" - "}
                {toDate}
              </p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Generated Date</p>
              <p className={styles["bill_cycle_text"]}>{toDate}</p>
            </div>

            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>
                Base Users({billdetails.baseUsers})
              </p>
              <p className={styles["bill_cycle_text"]}>
                ₹{Math.round(billdetails.basePrice)}
              </p>
            </div>
          </div>
          <div className={styles["total_bill"]}>
            <p className={styles["total_bill_headers"]}>Total Bill</p>
            <p className={styles["total_bill_texts"]}>
              <BiRupee className={styles["ruppee_icon"]} />
              {Math.round(billdetails.totalAmount)}
            </p>
          </div>
        </div>
        <div className={styles["confirm_button"]}>
          <button
            className={styles["sub_btn"]}
            type="button"
            onClick={() => handleNavigate()}
          >
            Go to Home
          </button>
        </div>
      </div>

      {paymentSuccessModal && (
        <div className={styles["delete-modal"]}>
          <div className={styles["delete-modal-content"]}>
            {/* <span
              className={styles["close_modal"]}
              onClick={() => setPaymentSuccessModal(false)}
            >
              &times;
            </span> */}
            <p id={styles["delete_text1"]}>Payment Successfull</p>

            <p id={styles["delete_header"]}>
              To Continue using Trader's Ground app, you must renew your
              Subscription
            </p>

            <div className={styles["delete_btns"]}>
              <button
                type="button"
                className={styles["deactivate_model_btn"]}
                onClick={() => deactivateOpen()}
              >
                No,Deactivate
              </button>
              {/* <button
                type="button"
                onClick={() => RenewPlan()}
                className={styles["renew_model_btn"]}
              >
                Yes,Renew
              </button> */}
            </div>
          </div>
        </div>
      )}
      {/* Deactivate Plan  code */}

      {accountDeactivatedModal && (
        <div className={styles["delete-modal"]}>
          <div className={styles["delete-modal-content"]}>
            {/* <span
              className={styles["close_modal"]}
              onClick={() => setAccountDeactivatedModal(false)}
            >
              &times;
            </span> */}
            <p id={styles["delete_text1"]}>Are you Sure</p>

            <p id={styles["delete_header"]}>
              This will Deactivate your account and delete your data
            </p>

            <div className={styles["delete_btns"]}>
              <button
                type="button"
                className={styles["calcel_back_btn"]}
                onClick={() => subscriptionOpen()}
              >
                No
              </button>
              <button
                type="button"
                onClick={() => DeactivatePlan()}
                className={styles["deactivate_btn"]}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
