import React, { useState } from "react";
import HomeClasses from "../home.module.css";
import { Services } from "../../../Services";
import {
  SuccessSwal,
  warningSwal,
} from "../../../components/utils/ToastMessages";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import config from "./config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
window.Buffer = window.Buffer || require("buffer").Buffer;

const BannerEdit = ({ getcategories, isOpen, setIsOpen, onClose, banner }) => {
  const [base64, setbase64] = useState(banner?.dashboardImg || '');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const editBanner = async (data) => {
    if (banner?.imagesId) {
      if (!data?.dashboardImg) {
        alert("Please select Image");
        return;
      }
      try {
        const res = await Services.BannerEdit(
          "PATCH",
          JSON.stringify(data),
          token,
          banner.imagesId
        );
        if (res.Status === 1) {
          SuccessSwal("Success", res.Message);
          onClose();
          getcategories();
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      if (!data.dashboardImg) {
        warningSwal("Warning", "Please select Image");
        return;
      }
      try {
        const res = await Services.AddBanner(
          "POST",
          JSON.stringify(data),
          token
        );
        if (res.Status === 1) {
          SuccessSwal("Success", res.Message);
          onClose();
          getcategories();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };


  function filehandleChange(files) {
    console.log(files.type);
    if (
      files.type === "image/png" ||
      files.type === "image/jpeg" ||
      files.type === "image/webp"
    ) {
      const timestamp = Date.now();
      const newfile = new File(
        [files],
        `${timestamp}.${String(files.type).split("/")[1]}`
      );

      S3FileUpload.uploadFile(newfile, {
        ...config,
        dirName: "dashboard-images",
      })
        .then((res) => {
          setbase64(res.location);
          setValue(
            "dashboardImg",
            `${timestamp}.${String(files.type).split("/")[1]}`
          );
        })
        .catch((err) => {
          alert(err);
          console.log(err);
        });
    } else {
      alert(`${files.name} is neither PNG orjpeg`);
      return false;
    }
    return true;
  }
  const fileUploader = (
    <div className={HomeClasses["add_image_uploader"]}>
      <BsCloudUpload className={HomeClasses["upload_icon"]} />
      <h5>Drag Your Files here</h5>
      <p>(Only *.jpeg ,*.png will be accepted)</p>
    </div>
  );


  return (
    <div className={HomeClasses["reject_modal"]}>
      <div
        onClick={() => onClose()}
        className={HomeClasses["overlaye"]}
      ></div>
      <div className={HomeClasses["Banner_Modal_content"]}>
        <h2 id={HomeClasses["resct_header"]}>Add Banner</h2>
        <div className={HomeClasses["Banner_Container"]}>
          <form
            onSubmit={handleSubmit(editBanner)}
            className={HomeClasses["banner_form"]}
          >
            <div className={HomeClasses["file-upload-wrapper"]}>
              <header>
                <h3>
                  Upload Banner{" "}
                  <span className="important">*</span>
                </h3>
              </header>
              <FileUploader
                classes={HomeClasses["upload-image"]}
                multiple={false}
                handleChange={filehandleChange}
                name="file"
                children={fileUploader}

              />
              <div className={HomeClasses.image_container}>
                <img
                  style={{ width: "100%" }}
                  src={base64}
                  className={HomeClasses["image_uploader"]}
                />
              </div>
            </div>
            <div className={HomeClasses["banner_form_btns"]}>
              <button
                type="button"
                onClick={() => onClose()}
                className={HomeClasses["banner_d_btn"]}
              >
                Cancel
              </button>
              <button type="submit" className={HomeClasses["banner_e_btn"]}>
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

export default BannerEdit;
