import React, { useEffect, useState } from "react";
import Traders_main from "../../assets/landingpage/Tradersground.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";

import "../../components/LandingPage/landingpage.css";
import { Services } from "../../Services";
import { ToastError, warningSwal } from "../utils/ToastMessages";
import { Permissions } from "../utils/Pernissions";

const Navbar = () => {
  const [activeItem, setActiveItem] = useState("Home");
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [homelink, setHomelink] = useState(null);
  const [isLogedIn, setIsLogedIn] = useState(false);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    setMenuOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const token1 = { Authorization: `token ${localStorage.getItem("token")}` };

    if (token !== null) {
      const CheckToken = async () => {

        Services.TokenCheck("GET", null, token1).then((res) => {
          if (res.Status === 1) {
            let type = Number(localStorage.getItem("AccountType"));
            let adminId = Number(localStorage.getItem("adminId"));
            let permissionAccount = Number(
              localStorage.getItem("permissionAccount")
            );

            if (type == 6) {
              if (permissionAccount == 1) {
                type = 7;
              } else if (permissionAccount == 2) {
                type = 8;
              }
            }
            if (type == 1 || type == 2) {
              getSubscription();
            } else if (type == 4 || type == 6) {
              if (adminId == 0) {
                setHomelink("/user");
              } else {
                setHomelink(Permissions(type)[0].menuLink);
              }
            } else {
              setHomelink(Permissions(type)[0].menuLink);
            }
            setIsLogedIn(true);
          } else if (res.Status === 0) {
            warningSwal("Warning", res.Message);
            if (res.Message === "unauthorized User") {
              localStorage.clear();
              navigate("/");
            }
          }
        }).catch((err) => {
          alert("Something went wrong ");
          console.error(err);
        })
      };
      CheckToken();
    } else {
      setIsLogedIn(false);
    }
  }, []);
  console.log(homelink);
  const getSubscription = async () => {
    const accountType = localStorage.getItem("AccountType");
    const token = {
      Authorization: `token ${localStorage.getItem("token")}`,
    };
    Services.SubscriptionStatus("GET", null, token).then((res) => {

      if (res.Status === 1) {
        if (res.isSubscribed === 0) {
          setHomelink(Permissions(accountType)[0].menuLink);
        } else {
          setHomelink(Permissions(accountType)[0].menuLink);
        }
      } else if (res.Status === 0) {
        warningSwal("Warning", res.Message);
        if (res.Message === "unauthorized User") {
          localStorage.clear();
          navigate("/");
        }
      }
      localStorage.setItem("paymentStatus", res?.paymentStatus);
      // console.log(res?.paymentStatus);
    }).catch((err) => {
      alert("Somthing went wrong please try again");
      console.error("error", err);
    })
  };

  return (
    <>
      <nav>
        <div className="Logo">
          <Link to="/">
            <img src={Traders_main} alt="Tanyya logo" />
          </Link>
        </div>
        <ul className={menuOpen ? "open" : ""} id="Navlink-items">
          <li>
            <NavLink
              to="/"
              className={`n_item ${activeItem === "Home" ? "active" : ""}`}
              onClick={() => handleItemClick("Home")}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/pricing"
              className={`n_item ${activeItem === "Pricing" ? "active" : ""}`}
              onClick={() => handleItemClick("Pricing")}
            >
              Pricing
            </NavLink>
          </li>
          {/* <li
            
          >
            <NavLink to="/about" className={`n_item ${activeItem === "About" ? "active" : ""}`}
            onClick={() => handleItemClick("About")}>
              {" "}
              About
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/contact"
              className={`n_item ${activeItem === "Contact" ? "active" : ""}`}
              onClick={() => handleItemClick("Contact")}
            >
              Contact
            </NavLink>
          </li>
          {/* <li
            className={`n_item ${activeItem === "Download" ? "active" : ""}`}
            onClick={() => handleItemClick("Download")}
          >
            Download
          </li> */}

          {isLogedIn === true ? (
            <>
              <li className="n_item">
                <button
                  className="nav_btn"
                  type="button"
                  onClick={() => navigate(homelink)}
                >
                  Dashbord
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="n_item">
                <NavLink to="/login">
                  <button className="nav_btn" type="button">
                    Login
                  </button>
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <button
          className="menu"
          type="submit"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </nav>
    </>
  );
};

export default Navbar;
