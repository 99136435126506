import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, warningSwal } from "../../components/utils/ToastMessages";
import Navbar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";
import Styles from "../../pages/Pricing/pricing.module.css";
import { GiCheckMark } from "react-icons/gi";
import { MdCurrencyRupee } from "react-icons/md";
import ScaleLoader from "react-spinners/ScaleLoader";
import { isMobile } from "react-device-detect";
import useStore from "../../store";
import Modal from "../../components/utils/warning";

function Pricing() {
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isLoginMobile, setIsLoginMobile } = useStore();
  useEffect(() => {
    if (isMobile) {
      setIsLoginMobile(true);
    }
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    try {
      const response = await Services.Subcriptiondetails("GET", null);
      if (response.Status === 1) {
        setSubscriptionDetails(response.subscriptionPlans);
      } else {
        warningSwal("Warning", "Failed to fetch subscription details");
      }
    } catch (error) {
      warningSwal("Warning", "Failed to fetch subscription details");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const signup = () => {
    navigate("/login");
  };

  return (
    <div className={Styles["pricing"]}>
      {isLoginMobile && <Modal />}
      <Navbar />
      <div>
        <div>
          <h1 className={Styles["Pricing_header"]}>
            Affordable Pricing Solutions for Every Business
          </h1>
        </div>

        <div className={Styles["pricing-container"]}>
          {loading ? (
            <div className={Styles["loader-container"]}>
              <ScaleLoader color={"black"} loading={loading} size={150} />
            </div>
          ) : (
            <>
              <div className={Styles["pricing-item-one"]}>
                <div className={Styles["pricing-item"]}>
                  <h5 className={Styles["pricing-item-header_free"]}>Free</h5>
                  <ul className={Styles["pricing_Manu_content"]}>
                    <li>
                      <p className={Styles["pricing_free_header"]}>
                        Installation, registration, and purchasing products from sellers are free
                      </p>
                    </li>

                    <ul className={Styles["pricing-checks"]}>
                      <li className={Styles["pricing_free_list"]}>
                        <GiCheckMark className={Styles["pricing_check_icon"]} />
                        Manufacturers
                      </li>
                      <li className={Styles["pricing_free_list"]}>
                        <GiCheckMark className={Styles["pricing_check_icon"]} />
                        Distributors
                      </li>
                      <li className={Styles["pricing_free_list"]}>
                        <GiCheckMark className={Styles["pricing_check_icon"]} />
                        Retailers
                      </li>
                      <li className={Styles["pricing_free_list"]}>
                        <GiCheckMark className={Styles["pricing_check_icon"]} />
                        Marketers
                      </li>
                      <li className={Styles["pricing_free_list"]}>
                        <GiCheckMark className={Styles["pricing_check_icon"]} />
                        Agents
                      </li>
                    </ul>
                  </ul>
                  <button
                    className={Styles["pricing_button_Dis_one"]}
                    onClick={() => signup()}
                  >
                    Get Started
                  </button>
                </div>
              </div>

              <div className={Styles["pricing-item-two"]}>
                <div className={Styles["pricing-item"]}>
                  <h5 className={Styles["pricing-item-header"]}>
                    Manufacturers
                  </h5>
                  <p className={Styles["pricing_free_header"]}>
                    To sell products to buyers
                  </p>
                  {subscriptionDetails &&
                    subscriptionDetails.map((subscription, index) => {
                      if (subscription.accountType === 1) {
                        return (
                          <div
                            key={subscription.subscriptionId}
                            className={Styles["subscription-detail"]}
                          >
                            <h5 className={Styles["pricing-item-header"]}>
                              {subscription.name}
                            </h5>

                            <div className={Styles["pricing-item-cost"]}>
                              <div>
                                <MdCurrencyRupee
                                  className={Styles["pricing_icon"]}
                                />
                              </div>
                              <div className={Styles["pricing-cost-number"]}>
                                {subscription.monthlyPrice}/month
                              </div>
                            </div>

                            <ul className={Styles["pricing-checks"]}>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Including GST%
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                {subscription.baseUsers} users included
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Multi Vendor
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Individual Pricing
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Groups Creation
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Group Pricing
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Orders Management
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Payments Management
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Inventory managent
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Sales Report
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Download Reports
                              </li>
                              <li>
                                <GiCheckMark
                                  className={Styles["pricing_check_icon"]}
                                />
                                Free Support
                              </li>
                            </ul>
                            <li className={Styles["pricing-list-limit"]}>
                              After {subscription.baseUsers} users, ₹
                              {subscription.pricePerExtraUser} per user per
                              month.
                            </li>
                            <button
                              className={Styles["pricing_button_Dis_two"]}
                              onClick={() => signup()}
                            >
                              Get Started
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>

              <div className={Styles["pricing-item-three"]}>
                <h5 className={Styles["pricing-item-header_Dis"]}>
                  Distributors
                </h5>
                <p className={Styles["pricing_free_header"]}>
                  To sell products to buyers
                </p>

                {subscriptionDetails &&
                  subscriptionDetails.map((subscription, index) => {
                    if (subscription.accountType === 2) {
                      return (
                        <div
                          key={subscription.subscriptionId}
                          className={Styles["subscription-detail"]}
                        >
                          <h5 className={Styles["pricing-item-header"]}>
                            {subscription.name}
                          </h5>

                          <div className={Styles["pricing-item-cost"]}>
                            <div>
                              <MdCurrencyRupee
                                className={Styles["pricing_icon"]}
                              />
                            </div>
                            <div className={Styles["pricing-cost-number"]}>
                              {subscription.monthlyPrice}/month
                            </div>
                          </div>

                          <ul className={Styles["pricing-checks"]}>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Including GST%
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              {subscription.baseUsers} users included
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Multi Vendor
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Individual Pricing
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Groups Creation
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Group Pricing
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Orders Management
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Payments Management
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Inventory managent
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Sales Report
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Download Reports
                            </li>
                            <li>
                              <GiCheckMark
                                className={Styles["pricing_check_icon"]}
                              />
                              Free Support
                            </li>
                          </ul>
                          <li className={Styles["pricing-list-limit"]}>
                            After {subscription.baseUsers} users, ₹
                            {subscription.pricePerExtraUser} per user per month.
                          </li>
                          <button
                            className={Styles["pricing_button_Dis_three"]}
                            onClick={() => signup()}
                          >
                            Get Started
                          </button>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricing;
