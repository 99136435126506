import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import ShopClass from "../Shop/shop.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ShopServices } from "./ShopServicess";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import { GoArrowLeft } from "react-icons/go";
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import PackageSelectionModal from "../../components/Modal/PackageSelectionModal";
import Search from "../../assets/homeimgs/search.png";
import { BsDoorClosedFill, BsDoorOpenFill } from "react-icons/bs";
import Image from '../../assets/noplot.png'
import { Services } from "../../Services";

const ManufacturerPage = () => {
  const { manufacturerId, groupId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [manufactureDetails, setManufactureDetails] = useState([]);
  const [manufacturName, setManufacturName] = useState();
  const [CartManufactureId, setCartManufacturerId] = useState("");
  const [bannerImage, setBanerImage] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [searchItems, setSearchItems] = useState([]);
  const [moq, setMoq] = useState(0);
  const [moqType, setMoqType] = useState();
  const [active, setActive] = useState();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [isNew, setIsNew] = useState();
  const [cancelSubscription, setCancelSubscription] = useState();
  const navigate = useNavigate();

  const getSubscriptionStatus = async () => {
    console.log(accountType)
    try {
      const res = await Services.SubscriptionStatus("GET", null, token);
      if (res.Status === 1) {
        setIsNew(res.isNew)
        setPaymentStatus(res?.paymentStatus);
        setIsSubscribed(res?.isSubscribed);
        setCancelSubscription(res?.cancelSubscription);
        localStorage.setItem("paymentStatus", res?.paymentStatus);
        if (res.isNew == 1) {
          return;
        }

        if (res.cancelSubscription === 1) {
          if (res.paymentStatus === 0) {
            navigate("/paynow");

          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 0) {
          if (res.isSubscribed === 1) {
            navigate(`/manufacturer/${manufacturerId}/${groupId}`);
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 2) {
          if (res.isSubscribed === 1) {
            navigate(`/manufacturer/${manufacturerId}/${groupId}`);
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        } else if (res.cancelSubscription === 3) {
          if (res.isSubscribed === 1) {
            navigate(`/manufacturer/${manufacturerId}/${groupId}`);
          } else if (res.isSubscribed === 0) {
            navigate("/subscription");
          }
        }
      }

      localStorage.setItem("paymentStatus", res?.paymentStatus);
      // console.log(res?.paymentStatus);

    } catch (err) {
      // alert("Somthing went wrong please try again");
      console.error(err);
    }
  };


  useEffect(() => {
    if (accountType == 5) {
      getSubscriptionStatus();
    }
  }, []);


  const Cartpage = () => {
    if (accountType == 5) {
      if (cancelSubscription === 0) {
        if (isSubscribed === 1) {
          navigate("/cart");
        } else if (isSubscribed === 0) {
          warningSwal("Warning", "Access denied due to invalid subscription, please subscribe");
        }
      }
    } else {
      navigate("/cart");
    }
  };


  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");

  useEffect(() => {
    getManufacturerProducts();
  }, [manufacturerId, groupId]);

  useEffect(() => {
    getCartDetails();
  }, []);

  const getManufacturerProducts = async () => {
    try {
      const res = await ShopServices.ManufacturerProducts(
        "GET",
        null,
        token,
        manufacturerId,
        groupId,
        page
      );
      if (res.Status === 1) {
        setSearchItems(res.manufacurerProducts);
        setManufactureDetails(
          res.manufacurerProducts.filter((product) => product.price > 0)
        );
        // setBanerImage(res.banner);
        const selectedGroupProducts = res.manufacurerProducts.filter(
          (product) => product.groupId === parseInt(groupId)
        );
        setFilteredProducts(
          selectedGroupProducts.filter((product) => product.price > 0)
        );
        setMoq(res.MOQ);
        setMoqType(res.moqType);
        setActive(res.isActive);
        setManufacturName(res.manufacturerName);

      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCartDetails = async () => {
    try {
      const res = await ShopServices.getCartItems("GET", null, token);
      if (res.Status === 1) {
        setCartDetails(res.cartItems);
        setCartManufacturerId(res.manufacturerId);
      }
    } catch (err) {
      // alert("Something went wrong, please try again later");
      console.error(err);
    }
  };

  const addToCart = async (product) => {
    try {
      const body = {
        cartItem: {
          manufacturerId: manufacturerId,
          productId: product.productId,
          qty: product.qty + 1,
          groupId: product.groupId,
          price: product.price,
        },
      };
      const res = await ShopServices.AddToCart(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
      }
    } catch (err) {
      // ToastError("Something went wrong, please try again later");
      console.error(err);
    }
  };

  const handleTokenExpiry = (message) => {
    if (message === "Token expired") {
      navigate("/");
      localStorage.clear();
    }
  };

  const openModal = () => {
    if (accountType == 5) {
      if (cancelSubscription === 0) {
        if (isSubscribed === 1) {
          setIsModalOpen(true);
        } else if (isSubscribed === 0) {
          warningSwal("Warning", "Access denied due to invalid subscription, please subscribe");
        }
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const searchHandler = (e) => {
    setLoading(false);
    setpageCount(1);
    const searchValue = e.target.value.toLowerCase();
    const search = searchItems.filter((item) =>
      item.productName.toLowerCase().includes(searchValue)
    );
    console.log(search);

    // console.log(search);
    if (searchValue.length === 0) {
      setFilteredProducts(searchItems);
    } else {
      setFilteredProducts(search);
    }
  };

  const backbutton = () => {
    navigate(-1);
  }
  return (
    <Layout Active={"Shop"}>
      <div className={ShopClass["home_wrapper"]}>
        <div className={ShopClass["Add_main_container"]}>
          <div className={ShopClass["Add_header"]}>
            <button
              className={ShopClass["add_back_btn"]}
              type="button"
              onClick={() => backbutton()}
            >
              <GoArrowLeft />
            </button>
            <h1 id={ShopClass["add_header_h2"]}>
              {manufacturName}
            </h1>
            <div
              className={
                active == 1
                  ? ShopClass["active-shop-open"]
                  : ShopClass["active-shop-close"]
              }
            >
              <p className={ShopClass["shop-active"]}>
                {active == 1 ? <p className={ShopClass.iconopen}><BsDoorOpenFill /> Shop Open</p> : <p className={ShopClass.iconclose}><BsDoorClosedFill /> Shop Close</p>}
              </p>
            </div>
          </div>
          <div className={ShopClass["manufacturer_main_container"]}>
            <div className={ShopClass["manufacturer_products_container"]}>
              <div className={ShopClass["m_products_header"]}>
                <h4 className={ShopClass["m_p_totalcount"]}>
                  Total Products({filteredProducts.length})
                </h4>
                {
                  (moqType != 3 && accountType != 4 && accountType != 5) && (<div className={ShopClass["m_products_header_cart_items"]}>
                    <p className={ShopClass["moq_rupee"]}>
                      Minimum Order Value: {moq} INR
                    </p>
                  </div>)
                }

                <div className={ShopClass["m_products_header_cart_items"]}>
                  <p className={ShopClass["cart_length"]}>
                    ({cartDetails.length}) items Added
                  </p>
                </div>


                <button
                  type="button"
                  className={ShopClass["view_cart"]}
                  onClick={() => Cartpage()}
                >
                  View Cart
                </button>
              </div>
              <div className={ShopClass["manageprod_search_container"]}>
                <div className={ShopClass["manageprod_search"]}>
                  <img
                    className={ShopClass["manage_prod_search"]}
                    src={Search}
                    alt="Search"
                  />
                  <input
                    type={"text"}
                    placeholder="Search using Product Name"
                    onChange={searchHandler}
                  />
                </div>
              </div>
              <div className={ShopClass["Shop_prodcuts_c"]}>
                <div className={ShopClass["manufacturer_products"]}>
                  {filteredProducts
                    .slice(perPage * page, perPage * page + perPage)

                    .map((product, index) => {
                      let cartItem = cartDetails?.filter(
                        (item) =>
                          item.productId == product.productId &&
                          item.groupId == product.groupId
                      );

                      // let discountPrice =
                      //   product.price * (product.discount / 100);
                      // console.log(discountPrice);
                      // let finalPrice = product.price - discountPrice;
                      return (
                        <div
                          className={ShopClass["shop_product_container"]}
                          key={product.productId}
                        >
                          <div className={ShopClass["shop_image_container"]}>

                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={ShopClass["image_shop_product"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={ShopClass["image_shop_product"]}
                                  src={Image}
                                  alt="image"
                                />
                              )
                            }

                          </div>
                          <div className={ShopClass["shop_product_info"]}>
                            <p id={ShopClass["home_product_text1"]}>
                              {product.productName}
                            </p>


                            {product.productBagWeightInKgs !== 0 &&
                              (
                                <p id={ShopClass["s_p_units"]}>
                                  {product.productBagWeightInKgs}KG'S /
                                  {product.productMeasuringUnit}
                                </p>
                              )}
                            {product.productPriceUnit !== "NA" && (
                              <>
                                {product.productPriceUnit === "Quintal" &&
                                  product.productBagWeightInKgs !== 0 &&
                                  (
                                    <p className={ShopClass["p_bag_price"]}>
                                      {product.productMeasuringUnit}
                                      price: ₹
                                      {product.productPriceUnit === "Quintal" &&
                                        (product.productBagWeightInKgs *
                                          product.price) /
                                        100}
                                    </p>
                                  )}
                              </>
                            )}
                            <p id={ShopClass["s_p_price"]}>
                              ₹{product.price}/{product.productPriceUnit}
                            </p>

                            <div className={ShopClass["shop_button_container"]}>
                              {product.stockQty !== 0 && product.status !== 2 ? (
                                <button
                                  type="button"
                                  className={ShopClass["shop_add_cart_btn"]}
                                  onClick={() => {
                                    openModal();
                                    setSelectedProduct(product);
                                  }}
                                >
                                  {cartItem.length > 0
                                    ? cartItem[0].qty
                                    : "Add"}
                                </button>
                              ) : (
                                <p className={ShopClass.outtext}>Out of Stock</p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={ShopClass["view_pagination"]}>
                <span id={ShopClass["view_page_count"]}>
                  Showing{" "}
                  <span className={ShopClass["number"]}>
                    {page * perPage + 1}
                  </span>{" "}
                  -{" "}
                  <span className={ShopClass["number"]}>
                    {Math.min((page + 1) * perPage, manufactureDetails.length)}
                  </span>{" "}
                  transactions from{" "}
                  <span className={ShopClass["number"]}>
                    {manufactureDetails.length}
                  </span>{" "}
                  data
                </span>
                <ReactPaginate
                  containerClassName={ShopClass["pagination"]}
                  activeClassName={ShopClass["active-page"]}
                  pageClassName={ShopClass["page-item"]}
                  onPageChange={(event) => setPage(event.selected)}
                  breakLabel="..."
                  pageCount={Math.ceil(filteredProducts.length / perPage)}
                  previousLabel={
                    <IconContext.Provider
                      value={{
                        color: "#B8C1CC",
                        size: "2rem",
                      }}
                    >
                      <AiFillLeftCircle />
                    </IconContext.Provider>
                  }
                  nextLabel={
                    <IconContext.Provider
                      value={{
                        color: "#B8C1CC",
                        size: "2rem",
                      }}
                    >
                      <AiFillRightCircle />
                    </IconContext.Provider>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <PackageSelectionModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          manufacturerId={manufacturerId}
          CartManufactureId={CartManufactureId}
          cartDetails={cartDetails}
          selectedProduct={selectedProduct}
          getCartItems={getCartDetails}
        // manufactureDetails={manufactureDetails}
        />
      )}
    </Layout>
  );
};

export default ManufacturerPage;
