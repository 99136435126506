import React, { useState } from "react";
import Contact from "../../assets/landingpage/slidecontact.jpeg";
import { useForm, Controller } from "react-hook-form";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoPerson } from "react-icons/io5";
import { ImOffice } from "react-icons/im";
import { MdMessage } from "react-icons/md";
import { ToastError, warningSwal } from "../../components/utils/ToastMessages";
import { FiCheckCircle } from "react-icons/fi";
import { ShopServices } from "../../pages/Shop/ShopServicess";

const Content_5 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const handleContact = async (data) => {
    console.log(data);
    if (!data.name || !data.company || !data.mobileNumber || !data.message) {
      alert("All fields are required");
      return;
    }
    if (!/^[A-Za-z ]+$/.test(data.name) || !/^[A-Za-z ]+$/.test(data.company)) {
      alert("Name and company name should contain only alphabets");
      return;
    }
    if (!/^[6-9]\d{9}$/.test(data.mobileNumber)) {
      alert("Invalid mobile number format");
      return;
    }
    try {
      const res = await ShopServices.CustomerSupport("POST", JSON.stringify(data));
      if (res.Status === 1) {
        setSubmitted(true);
      } else if (res.Status === 0) {
        warningSwal("Warning", res.Message);
      }
    } catch (err) {
      alert("Something went wrong. Please try again later.");
      console.error(err);
    }
  };

  return (


    <div>
      <div className="content_5_header">
        <h1 id="content_5_h1">
          Let's <span id="extra">Connect</span>
        </h1>
        <h6 id="content_5_h5">
          Please share your contact details and our team will call you back!
        </h6>
      </div>
      {submitted ? (
        <div className="content_5_container">

          <div className="content_5_img">
            <img
              className="content_5_image"
              src={Contact}
              alt="Contact_connecting"
            />
          </div>

          <div onSubmit={handleSubmit(handleContact)} className="content_5_contact">
            <div className="Content_5_success">
              <div><FiCheckCircle className="Content_5_success-icon" /></div>
              <div className="Content_5_success_MSG">Thank You For Your Submission!</div>
            </div>
          </div>

        </div>
      ) : (
        <div className="content_5_container">

          <div className="content_5_img">
            <img
              className="content_5_image"
              src={Contact}
              alt="Contact_connecting"
            />
          </div>


          <form onSubmit={handleSubmit(handleContact)} className="content_5_contact">
            <div className="text-field">
              <label htmlFor="name">
                <IoPerson className="person" />
              </label>
              <Controller
                name="name"
                control={control}

                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    id="name"
                    placeholder="Name"
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>
            <div className="text-field">
              <label htmlFor="company">
                <ImOffice className="office" />
              </label>
              <Controller
                name="company"
                control={control}

                render={({ field }) => (
                  <input
                    {...field}
                    id="company"
                    placeholder="Company Name"
                  />
                )}
              />
              {errors.company && <p className="error-message">{errors.company.message}</p>}
            </div>
            <div className="text-field">
              <label htmlFor="mobileNumber">
                <BsFillTelephoneFill className="phone" />
              </label>
              <Controller
                name="mobileNumber"
                control={control}

                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    id="mobileNumber"
                    placeholder="Mobile Number"
                  />
                )}
              />
              {errors.mobileNumber && <p className="error-message">{errors.mobileNumber.message}</p>}
            </div>
            <div className="text-field">
              <label htmlFor="message">
                <MdMessage className="message" />
              </label>
              <Controller
                name="message"
                control={control}

                render={({ field }) => (
                  <input
                    type="message"
                    {...field}
                    id="message"
                    placeholder="How can we help you?"
                  />
                )}
              />
              {errors.message && <p className="error-message">{errors.message.message}</p>}
            </div>
            <div className="content_5_bts">
              <button id="content_5_btn" type="submit">
                Get a Callback
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Content_5;
