import React, { useState } from 'react';
import Styles from './style.module.css';
import { RxCross2 } from 'react-icons/rx';
import { FaMinus, FaPlus } from 'react-icons/fa';

const AddFinalQDPrice = ({ orderdetails, orderId, onClose, finalPrice, OrderDetails, setFinalPrice }) => {
    const [totalAmount, setTotalAmount] = useState(finalPrice);

    const handleIncrement = () => {
        setTotalAmount((prevAmount) => (parseFloat(prevAmount) + 1).toFixed(2));
    };

    const handleDecrement = () => {
        setTotalAmount((prevAmount) => Math.max(0, (parseFloat(prevAmount) - 1).toFixed(2)));
    };

    const onSubmit = async () => {
        localStorage.setItem("TotalQDPrice", totalAmount);
        setFinalPrice(totalAmount);
        OrderDetails();
        onClose();
    };

    return (
        <div className={Styles.modalOverlay}>
            <div className={Styles.modalContent}>
                <div className={Styles.modalHeader}>
                    <h2>Total QD Price</h2>
                    <button className={Styles.closeButton} onClick={onClose}><RxCross2 size={20} /></button>
                </div>

                <div className={Styles.modalBody}>
                    <div className={Styles.amountControls}>
                        <button onClick={handleDecrement} className={Styles.amountButton}><FaMinus size={15} /></button>
                        <input type="number" value={totalAmount} readOnly className={Styles.amountInput} />
                        <button onClick={handleIncrement} className={Styles.amountButton}><FaPlus size={15} /></button>
                    </div>
                </div>

                <div className={Styles.modalFooter}>
                    <button onClick={onSubmit} className={Styles.submitButton}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default AddFinalQDPrice;
