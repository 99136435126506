import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import StyleClass from "../../pages/Notifications/notification.module.css";
import {
  AiFillLeftCircle,
  AiFillRightCircle,
  AiOutlineNotification,
} from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import ReactPaginate from "react-paginate";
import { override } from "../../pages/Home/helper";
import Moment from "react-moment";
import emptyorders from "../../assets/emptyorders.png";
import { RotatingLines } from "react-loader-spinner";
import useStore from "../../store";
const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [perPage] = useState(6);
  const { setNotificationCount } = useStore();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getcategories(page);
  }, [page]);

  useEffect(() => {
    readNotications()
  }, []);

  const getcategories = async (page) => {
    try {
      const res = await Services.Notifications("GET", null, token, page);
      console.log(res)
      setLoading(false);
      if (res.Status === 1) {
        setNotifications(res.notifications);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const readNotications = async () => {
    try {
      const res = await Services.ReadNotifications("POST", null, token);
      if (res.Status === 1) {
        setNotificationCount(0)
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);

    }
  }
  return (
    <Layout Active={"Notifications"}>
      <div className={StyleClass["home_wrapper"]}>
        {loading ? (
          <div className={StyleClass["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={StyleClass["Add_main_container"]}>
            <div className={StyleClass["Add_header"]}>
              {/* <button
                className={StyleClass["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button> */}
              <h1 id={StyleClass["add_header_h2"]}>Notifications</h1>
            </div>
            <div className={StyleClass["Notification_container"]}>
              <div className={StyleClass["notify_box_container"]}>
                {notifications.length === 0 ? (
                  <div className={StyleClass["empty_products"]}>
                    <div className={StyleClass["empty_image_container"]}>
                      <img
                        className={StyleClass["empty_image"]}
                        src={emptyorders}
                        alt="image"
                      />
                    </div>
                    <p className={StyleClass["empty_portal_1"]}>
                      There is No Notifications to show
                    </p>
                  </div>
                ) : (
                  notifications
                    .slice(perPage * page, perPage * page + perPage)
                    .map((notification, index) => {
                      return (
                        <div className={StyleClass["notifi_box"]}>
                          <div className={StyleClass["notification"]}>
                            <div
                              className={StyleClass["user_initial_container"]}
                            >
                              <p className={StyleClass["icon_notification"]}>
                                <AiOutlineNotification />
                              </p>
                            </div>
                            <div className={StyleClass["notification_text"]}>
                              <p className={StyleClass["n_bold"]}>
                                {notification.title}
                              </p>
                              <p className={StyleClass["n_small"]}>
                                {notification.message}
                              </p>
                              <p className={StyleClass["time_count"]}>
                                <Moment fromNow>
                                  {notification.createdAt}
                                </Moment>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
              <div className={StyleClass["view_pagination"]}>
                <ReactPaginate
                  containerClassName={StyleClass["pagination"]}
                  activeClassName={StyleClass["active-page"]}
                  pageClassName={StyleClass["page-item"]}
                  onPageChange={(event) => setPage(event.selected)}
                  breakLabel="..."
                  pageCount={Math.ceil(notifications.length / perPage)}
                  previousLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "1.8rem" }}
                    >
                      <AiFillLeftCircle />
                    </IconContext.Provider>
                  }
                  nextLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "1.8rem" }}
                    >
                      <AiFillRightCircle />
                    </IconContext.Provider>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Notification;
