import React, { useEffect, useState } from 'react'
import { Services } from '../../Services';
import { Success } from '../../components/utils/ToastMessages';
import { useForm } from 'react-hook-form';
import HomeClasses from './style.module.css';
import { RxCross2 } from 'react-icons/rx';
const LoadingDateChange = ({ isOpen, setIsOpen, orderId, onClose, orderDetails, loadingDate }) => {
    const [loading, setLoading] = useState(false);
    const { register,
        formState: { errors },
        handleSubmit,
        setValue } = useForm();

    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (loadingDate) {
            setValue('loadingDate', loadingDate ? new Date(loadingDate).toISOString().split('T')[0] : "");
        }
    }, [loadingDate, setValue]);

    const onSubmit = async (data) => {
        let body = {
            loadingDate: data.loadingDate || loadingDate,
            orderId: orderId
        };

        try {
            const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
            if (res.Status === 1) {
                Success(res.Message);
                onClose();
                orderDetails();
            } else {
                Error(res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div className={HomeClasses["delete-modal"]}>
            <div className={HomeClasses["update_modal_Invoice"]}>
                <div className={HomeClasses["hed_wrapper"]}>
                    <h2 id={HomeClasses["resct_header"]}>Update Loading Date</h2>
                    <button onClick={() => onClose()}><RxCross2 size={20} /></button>
                </div>
                <div className={HomeClasses["update_input_model_container"]}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={HomeClasses["banner_form"]}
                    >
                        <div className={HomeClasses["form-group"]}>
                            <label htmlFor="loadingDate">Loading Date</label>
                            <input
                                type="date"
                                placeholder='LOADING DATE'
                                min={new Date().toISOString().split("T")[0]}
                                {...register("loadingDate", {
                                    required: false,
                                })}
                            />
                            {errors.loadingDate && <span className={HomeClasses["error"]}>{errors.loadingDate.message}</span>}
                        </div>

                        <div className={HomeClasses["banner_form_btns"]}>
                            <button type="submit" className={HomeClasses["banner_e_btn"]}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoadingDateChange;