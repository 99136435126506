import React, { useEffect, useState } from "react";
import { ShopServices } from "../../Shop/ShopServicess";
import { useNavigate } from "react-router-dom";
import {
  SuccessSwal,
  ToastError,
  ToastSuccess,
} from "../../../components/utils/ToastMessages";
import { useForm, Controller } from "react-hook-form";
import Styles from "./faq.module.css";
import { FaBoxes } from "react-icons/fa";
import { BiSolidBox } from "react-icons/bi";

const Moq = () => {
  const [moqs, setMoqs] = useState();
  const [moqType, setMoqType] = useState();
  const Navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const formData = watch();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");
  useEffect(() => {
    getMoqs();
  }, []);

  const getMoqs = async () => {
    try {
      const res = await ShopServices.MOQ("GET", null, token);
      if (res.Status === 1) {
        setMoqs(res.moqDetails);
        setMoqType(res.moqDetails.moqType);
        reset(res.moqDetails);
      }
    } catch (err) {
      // alert("Something went wrong  please try again");
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await ShopServices.EditMOQ(
        "PATCH",
        JSON.stringify(data),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
      }
    } catch (err) {
      // alert("Something went wrong  please try again later");
      console.error(err);
    }
  };
  return (
    <div className={Styles["moq_container"]}>
      <div className={Styles["Add_header"]}>
        <h1 id={Styles["add_header_h2"]}>Minimum Order Value</h1>
      </div>
      <div className={Styles['form_container']}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={Styles["add_register"]}
        >
          <div>
            <Controller
              name="moqType"
              control={control}
              rules={{ required: "  Mov Type is required" }}
              render={({ field }) => (
                <div className={Styles["add_icon"]}>
                  <select defaultValue="" {...field} placeholder="  Mov Type">
                    <option value="" disabled>
                      Mov Type
                    </option>
                    <option value={1}>Price</option>
                    <option value={3}>None</option>
                  </select>
                  <BiSolidBox />
                </div>
              )}
            />
            {errors.status && (
              <p className={Styles["errors"]}>{errors.status.message}</p>
            )}
          </div>

          <div>
            <Controller
              name="MOQ"
              control={control}
              rules={{
                required:
                  formData.moqType === "1"
                    ? "Minimum Order Value is required"
                    : false,
                pattern: {
                  value: /^\d*\.?\d{0,2}$/,
                  message: "MOV contain digits with two decimal points only",
                },
              }}
              render={({ field }) => (
                <div className={Styles["add_icon"]}>
                  <input
                    {...field}
                    placeholder="Minimum Order Value"
                    disabled={formData.moqType == 3}
                    min={1}
                  />
                  <FaBoxes />
                </div>
              )}
            />
            {errors.MOQ && formData.moqType !== "3" && (
              <p className={Styles["errors"]}>{errors.MOQ.message}</p>
            )}
          </div>
          <div className={Styles["moq_btn"]}>
            <button type="submit" className={Styles["add_submit"]}>
              Edit MOV
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Moq;
