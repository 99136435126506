import React, { useEffect, useState } from "react";
import { PurchaseService } from "./PurchaseService";
import { SuccessSwal, ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/layout";
import StatusStyles from "./purchase.module.css";
import emptyorders from "../../assets/emptyorders.png";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import Permissionimg from "../../assets/NoPermissions.png";
import { IconContext } from "react-icons/lib";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Services } from "../../Services";
import Moment from "react-moment";
import { ShopServices } from "../Shop/ShopServicess";

const Purchaseorders = () => {
  const [purchaseOrders, setpurchaseOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pendingpayments, setPendingpayments] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [highlightDays, setHighlightDays] = useState({});

  const [status, setStatus] = useState(
    parseInt(localStorage.getItem("PurchaseStatus")) || 1
  );

  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const permissionAccount = localStorage.getItem("permissionAccount");
  //for Permissions

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 11
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;
  useEffect(() => {
    const getConfigs = async () => {
      try {
        const res = await ShopServices.ConfigDropdowns("GET", null, token);
        if (res.Status === 1) {
          setDropdownOptions(res.config);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getConfigs();
  }, [])
  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, [])

  useEffect(() => {
    if (status === 9) {
      getPendingPayments(status, page);
    } else {
      getOrders(status, page);
    }
    // return () => {
    //   localStorage.removeItem("PurchaseStatus");
    // };
  }, [status, page]);

  const handleOrderStatus = (status) => {
    if (status === 9) {
      setStatus(status);
      localStorage.setItem("PurchaseStatus", status);
    } else {
      getOrders(status);
      setStatus(status);
      localStorage.setItem("PurchaseStatus", status);
    }
  };

  const isActiveButton = (buttonStatus) => {
    return status === buttonStatus ? StatusStyles.active : "";
  };
  const getOrders = async (status, page) => {
    PurchaseService.PurchaseStatus(
      "GET",
      null,
      token,
      status,
      page
    ).then((res) => {
      setLoading(false);
      if (res.Status === 1) {
        setpurchaseOrders(res.orderDetails);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const getPendingPayments = (status, page) => {
    PurchaseService.PurchasePendingPayments("GET", null, token, status, page)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setPendingpayments(res.payments);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Reorder = async (orderId, e) => {
    e.stopPropagation();
    try {
      const res = await PurchaseService.ReOrder(
        "POST",
        JSON.stringify({}),
        token,
        orderId
      );
      if (res.Status === 1) {
        SuccessSwal("Success", "Products Added to Cart successfully");
        Navigate("/cart");
        // setpurchaseOrders(res.orderDetails);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const orderdetails = (orderId) => {
    Navigate(`/purchaseDetails/${orderId}/${status}`);
  };

  useEffect(() => {
    if (dropdownOptions.length > 0) {
      const highletDays = dropdownOptions?.find(
        (config) => config.configId === 15
      );
      setHighlightDays(highletDays);
      console.log(highletDays)
    }
  }, []);

  return (
    <Layout Active={"Purchase Orders"}>
      <div className={StatusStyles["home_wrapper"]}>
        {loading ? (
          <div className={StatusStyles["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={StatusStyles["Order_container"]}>
            {accountType == 6 && PermissionStatus == 0 ? (
              <div className={StatusStyles["permission_container"]}>
                <h2>
                  we,re sorry, But you dont have required permissions to access
                  this page.Please contact the site administrator.
                </h2>
                <img src={Permissionimg} alt="image" />
              </div>
            ) : (
              <>
                <select
                  className={StatusStyles["order_status_select"]}
                  name="userOrdersStatus"
                  id={1}
                  onChange={(e) => handleOrderStatus(parseInt(e.target.value))}
                >
                  {
                    accountType == 5 &&
                    <option value={14}>Sub Broker</option>
                  }
                  <option value={1}>Pending</option>
                  <option value={2}>Processing</option>
                  <option value={3}>Invoice Printed</option>
                  <option value={4}>Transport</option>
                  <option value={8}>Quality Difference</option>
                  <option value={5}>Delivered</option>
                  <option value={6}>Rejected</option>
                  <option value={9}>Pending Payments</option>
                  {
                    accountType == 5 ?
                      <>
                        <option value={14}>Transport Advance Pay</option>
                        <option value={10}>Miller Commisions</option>
                        <option value={11}>Buyer Commisions</option>
                        <option value={12}>Subbroker Commisions</option>
                        <option value={13}>Transport Commisions</option>
                        <option value={7}>Completed</option>
                      </> :
                      <>
                        <option value={11}>Buyer Commisions</option>
                        <option value={7}>Completed</option>
                      </>
                  }

                </select>
                <div className={StatusStyles["Order_statusbtn"]}>
                  {
                    accountType == 5 &&
                    <button
                      className={`${StatusStyles["order_statusbuttons"]
                        } ${isActiveButton(14)}`}
                      onClick={() => handleOrderStatus(14)}
                      type="button"
                    >
                      Sub Broker
                    </button>
                  }
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(1)}`}
                    onClick={() => handleOrderStatus(1)}
                    type="button"
                  >
                    Pending
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(2)}`}
                    onClick={() => handleOrderStatus(2)}
                    type="button"
                  >
                    Processing
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(3)}`}
                    onClick={() => handleOrderStatus(3)}
                    type="button"
                  >
                    Invoice printed
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(4)}`}
                    onClick={() => handleOrderStatus(4)}
                    type="button"
                  >
                    Transport
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(8)}`}
                    onClick={() => handleOrderStatus(8)}
                    type="button"
                  >
                    Quality Difference
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(5)}`}
                    onClick={() => handleOrderStatus(5)}
                    type="button"
                  >
                    Delivered
                  </button>
                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(6)}`}
                    onClick={() => handleOrderStatus(6)}
                    type="button"
                  >
                    Rejected
                  </button>

                  <button
                    className={`${StatusStyles["order_statusbuttons"]
                      } ${isActiveButton(9)}`}
                    onClick={() => handleOrderStatus(9)}
                    type="button"
                  >
                    Pending payments
                  </button>
                  {
                    (accountType == 5 || (accountType == 6 && permissionAccount == 5)) ?
                      <>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(15)}`}
                          onClick={() => handleOrderStatus(15)}
                          type="button"
                        >
                          Transport Advance Pay
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(10)}`}
                          onClick={() => handleOrderStatus(10)}
                          type="button"
                        >
                          Miller Commissions
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(11)}`}
                          onClick={() => handleOrderStatus(11)}
                          type="button"
                        >
                          Buyer Commissions
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(12)}`}
                          onClick={() => handleOrderStatus(12)}
                          type="button"
                        >
                          SubBroker Commissions
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(13)}`}
                          onClick={() => handleOrderStatus(13)}
                          type="button"
                        >
                          Transport Commissions
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(7)}`}
                          onClick={() => handleOrderStatus(7)}
                          type="button"
                        >
                          Completed
                        </button>
                      </> :
                      <>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(11)}`}
                          onClick={() => handleOrderStatus(11)}
                          type="button"
                        >
                          Buyer Commissions
                        </button>
                        <button
                          className={`${StatusStyles["order_statusbuttons"]
                            } ${isActiveButton(7)}`}
                          onClick={() => handleOrderStatus(7)}
                          type="button"
                        >
                          Completed
                        </button>
                      </>
                  }

                </div>
                <div className={StatusStyles["Order_table_container"]}>
                  <table className={StatusStyles["Order_table"]}>
                    <thead className={StatusStyles["sticky-header"]}>
                      {status === 9 ? (
                        <tr>
                          <th>Agency Name</th>
                          <th>Order ID</th>
                          <th>Ordered Date</th>
                          <th>Order Value</th>
                          <th>Payment Received</th>
                          <th>Pending Payment</th>
                        </tr>) : (
                        <tr>
                          <th>Order ID</th>
                          <th>Ordered From</th>
                          <th>Delivery Address</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {status === 9 ? (
                        pendingpayments.length === 0 ? (
                          <tr>
                            <td colSpan={6}>
                              <div className={StatusStyles["empty_cell"]}>
                                <div
                                  className={
                                    StatusStyles["empty_image_container"]
                                  }
                                >
                                  <img
                                    className={StatusStyles["empty_image"]}
                                    src={emptyorders}
                                    alt="image"
                                  />
                                </div>
                                <p className={StatusStyles["empty_portal_1"]}>
                                  No orders to show, Make your first Order
                                </p>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          pendingpayments
                            .slice(perPage * page, perPage * page + perPage)
                            .map((payment, index) => (
                              <tr key={index}
                                className={payment?.dueDays !== null && payment?.dueDays <= 5 ? StatusStyles["highlight-row"] : ""}>
                                <td>{payment.userName}</td>
                                <td>
                                  <button
                                    type="button"
                                    className={StatusStyles["orderbyId_btn"]}
                                    onClick={() =>
                                      orderdetails(payment.orderId)
                                    }
                                  >
                                    {payment.orderId}
                                  </button>
                                </td>
                                <td>
                                  <Moment
                                    format="DD MMM, YYYY">
                                    {new Date(payment.createdAt)}
                                  </Moment>
                                </td>
                                <td>₹{payment?.qualityDifferencePrice !== null && payment?.qualityDifferencePrice !== 0 ? Number(payment?.qualityDifferencePrice).toFixed(2) : Number(payment?.finalAmount).toFixed(2)}</td>
                                <td>₹{Number(payment?.receivedPayement).toFixed(2)}</td>
                                <td>₹{Number(payment?.pendingPayment).toFixed(2)}</td>
                              </tr>
                            ))
                        )
                      ) : purchaseOrders.length === 0 ? (
                        <tr>
                          <td colSpan={6}>
                            <div className={StatusStyles["empty_cell"]}>
                              <div
                                className={
                                  StatusStyles["empty_image_container"]
                                }
                              >
                                <img
                                  className={StatusStyles["empty_image"]}
                                  src={emptyorders}
                                  alt="image"
                                />
                              </div>
                              <p className={StatusStyles["empty_portal_1"]}>
                                No orders to show, Make your first Order
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        purchaseOrders
                          .slice(perPage * page, perPage * page + perPage)
                          .map((item, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => orderdetails(item.orderId)}
                              >
                                <td>{item.orderId}</td>
                                <td>{item.userName}</td>
                                <td>{item.deliveredTo.name}{" "}({item.deliveredTo.accountType === 1
                                  ? "Miller"
                                  : item.deliveredTo.accountType === 2
                                    ? "Distributor"
                                    : item.deliveredTo.accountType === 3
                                      ? "Retailer"
                                      : item.deliveredTo.accountType === 4
                                        ? "Marketing Specialist"
                                        : item.deliveredTo.accountType === 5
                                          ? "Broker"
                                          : "Office Staff"})</td>
                                <td>₹{item?.qualityDifferencePrice > 0 ? Number(item.qualityDifferencePrice).toFixed(2) : Number(item?.finalAmount).toFixed(2)}</td>
                                <td>
                                  {(status === 5 || status === 7
                                    || status === 10 || status === 11 || status === 13)
                                    && (
                                      <button
                                        type="button"
                                        className={StatusStyles["reorder_button"]}
                                        onClick={(e) => {
                                          Reorder(item.orderId, e);
                                        }}
                                      >
                                        Reorder
                                      </button>
                                    )}
                                </td>
                              </tr>
                            );
                          })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={StatusStyles["order_pagination"]}>
                  <ReactPaginate
                    containerClassName={StatusStyles["pagination"]}
                    activeClassName={StatusStyles["active-page"]}
                    pageClassName={StatusStyles["page-item"]}
                    onPageChange={(event) => setPage(event.selected)}
                    breakLabel="..."
                    pageCount={Math.ceil(purchaseOrders.length / perPage)}
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "1.8rem" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "1.8rem" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Purchaseorders;
