import React, { useState } from "react";
import LoginClasses from "../Login/login.module.css";
import Tanyya from "../../assets/login/tanyyalogin.png";
import Logo from "../../assets/login/Logotanyya.png";
import { useForm, Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
import { SuccessSwal, ToastError } from "../../components/utils/ToastMessages";
import { ToastSuccess } from "../../components/utils/ToastMessages";
import { GoArrowLeft } from "react-icons/go";
const ResetPassword = () => {
  const [type, setType] = useState("OTP");
  const [showOtp, setShowOtp] = useState(false);
  const [Otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const Navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleLogin = (data) => {
    console.log(type, showOtp);
    if (type === "OTP" && showOtp) {
      verifyOtp(data);
    } else if (type === "OTP") {
      getOtp(data);
    }
    console.log(data);
  };

  const handleResend = (data) => {
    if (type === "OTP" && showOtp) {
      getOtp(data);
    }
  };

  const getOtp = async (data) => {

    setResendDisabled(true);
    const body = JSON.stringify(data);
    Services.loginOtp("POST", body).then((res) => {
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        setShowOtp(true);
        startResendTimer();
        setTimer(30);
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  const startResendTimer = () => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setResendDisabled(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const verifyOtp = async (data) => {
    const body = JSON.stringify(data);
    Services.userlogin("POST", body).then((res) => {

      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        localStorage.setItem("token", res?.Token);
        Navigate("/resetpassword");
      }
    }).catch((err) => {
      console.log(err);
    })
  };
  return (
    <>
      <div className={LoginClasses["login"]}>
        <div className={LoginClasses["model_image"]}>
          <img
            className={LoginClasses["Tanyya_image"]}
            src={Tanyya}
            alt="Tanyya image"
          />
          <div className={LoginClasses["hero_mask"]}>
            <div className={LoginClasses["model_content"]}>
              <h1 className={LoginClasses["div-row"]}>Resetting Security!</h1>
              <p className={LoginClasses["div-col"]}>
                Traders Ground is a B2B platform owned by Tanyya Tech Solutions
                LLP. The platform is designed to simplify the entire process for
                manufacturers and distributors.
              </p>
            </div>
          </div>
        </div>
        <div className={LoginClasses["login_maindiv"]}>
          <div className={LoginClasses.backscreen}>
            <button
              className={LoginClasses["Add_back_btn"]}
              type="button"
              onClick={() => {
                Navigate(-1);
                localStorage.clear();
              }}
            >
              <GoArrowLeft />
            </button>
          </div>
          <img
            className={LoginClasses["logoText"]}
            src={Logo}
            alt="Logo Text"
          />
          <div className={LoginClasses["login_div"]}>
            <h1 className={LoginClasses["login_h1"]}>Forgot Password</h1>
            <form onSubmit={handleSubmit(handleLogin)}>
              <label>Mobile Number</label>
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: "Mobile number is required",
                  pattern: {
                    value: !/^[6-9]\d{0,9}$/,
                    message: "Invalid mobile number format",
                  },
                }}
                render={({ field }) => (
                  <input
                    className={LoginClasses["login_input"]}
                    type="text"
                    placeholder="Mobile Number"
                    {...field}
                    disabled={showOtp}
                    maxLength={10}
                  />
                )}
              />
              {errors.mobileNumber && (
                <p className={LoginClasses.error}>
                  {errors?.mobileNumber?.message}
                </p>
              )}

              {type === "OTP" && showOtp && (
                <>
                  <label htmlFor="Otp">OTP</label>
                  <Controller
                    name="Otp"
                    control={control}
                    render={({ field }) => (
                      <OtpInput
                        value={Otp}
                        onChange={(otpValue) => {
                          setOtp(otpValue);
                          field.onChange(otpValue);
                        }}
                        numInputs={4}
                        containerStyle={LoginClasses["otp-input-container"]}
                        inputStyle={LoginClasses["otp-input"]}
                        separator={
                          <span className={LoginClasses["otp-separator"]}>
                            -
                          </span>
                        }
                        shouldAutoFocus={true}
                        renderInput={(props, index) => (
                          <input {...field} {...props} />
                        )}
                      />
                    )}
                  />
                  {errors.Otp && (
                    <p className={LoginClasses.error}>{errors?.Otp?.message}</p>
                  )}
                </>
              )}

              <button type="submit" className={LoginClasses["S_btn"]}>
                {type === "OTP" && showOtp
                  ? "Verify OTP"
                  : type === "OTP"
                    ? "Get OTP"
                    : "Login"}
              </button>
              {type === "OTP" && showOtp && (
                <button
                  type="button"
                  className={LoginClasses["type"]}
                  disabled={resendDisabled}
                  onClick={() => handleSubmit(handleResend)()}
                >
                  Resend OTP {resendDisabled && `in (${timer})`}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
