import React, { useEffect, useState } from 'react'
import { Services } from '../../Services';
import { Success } from '../../components/utils/ToastMessages';
import { useForm } from 'react-hook-form';
import { FaTruckMoving } from 'react-icons/fa';
import HomeClasses from './style.module.css';
import { IoCall } from 'react-icons/io5';
import { ScaleLoader } from 'react-spinners';
import { RxCross2 } from 'react-icons/rx';
const UpdateTransports = ({ isOpen, setIsOpen, orderId, onClose, orderDetails, transportDetails }) => {
    const [loading, setLoading] = useState(false);
    const { register,
        formState: { errors },
        handleSubmit,
        setValue } = useForm();

    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (transportDetails) {
            setValue('vehicleNumber', transportDetails.vehicleNumber);
            setValue('driverNumber', transportDetails.driverNumber);
        }
    }, [transportDetails, setValue]);

    const onSubmit = async (data) => {
        let body = {
            transportDetails: {
                ...transportDetails,
                vehicleNumber: data.vehicleNumber,
                driverNumber: data.driverNumber,
            },
            orderId: orderId
        };

        try {
            const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
            if (res.Status === 1) {
                Success(res.Message);
                onClose();
                orderDetails();
            } else {
                Error(res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div className={HomeClasses["delete-modal"]}>
            <div className={HomeClasses["update_modal_Invoice"]}>
                <div className={HomeClasses["hed_wrapper"]}>
                    <h2 id={HomeClasses["resct_header"]}>Update Transport</h2>
                    <button onClick={() => onClose()}><RxCross2 size={20} /></button>
                </div>
                <div className={HomeClasses["update_input_model_container"]}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={HomeClasses["banner_form"]}
                    >
                        <div className={HomeClasses["form-group"]}>

                            <label htmlFor="vehicleNumber">Vehicle Number</label>
                            <input
                                placeholder='Vehicle Number '
                                {...register("vehicleNumber", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/,
                                        message: "Invalid vehicle number format. Format should be like MH12AB1234"
                                    },
                                    onChange: (e) => {
                                        const upperCaseValue = e.target.value.toUpperCase();
                                        setValue('vehicleNumber', upperCaseValue, { shouldValidate: true });
                                    },
                                })}
                                maxLength={10}
                            />
                            <FaTruckMoving className={HomeClasses.inputicon} />
                            {errors.vehicleNumber && <span className={HomeClasses["error"]}>{errors.vehicleNumber.message}</span>}
                        </div>
                        <div className={HomeClasses["form-group"]}>
                            <label htmlFor="driverNumber">Driver Number</label>
                            <input
                                placeholder='Driver Number'
                                {...register("driverNumber", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: "Mobile number must start with 6-9 and be exactly 10 digits"
                                    }
                                })}
                                maxLength={10}
                            />

                            <IoCall className={HomeClasses.inputicon} />
                            {errors.driverNumber && <span className={HomeClasses["error"]}>{errors.driverNumber.message}</span>}
                        </div>
                        <div className={HomeClasses["banner_form_btns"]}>
                            <button type="submit" className={HomeClasses["banner_e_btn"]}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateTransports