import React, { useState } from 'react'
import HomeClasses from '../home.module.css'
import { RxCross2 } from 'react-icons/rx';
import { Services } from '../../../Services';
import { Success, SuccessSwal, warningSwal } from '../../../components/utils/ToastMessages';
import { useForm } from 'react-hook-form';
const QualityDifference = ({ isOpen, setIsOpen, orderDetails, onClose, getOrderdetails }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [notes, setNotes] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const handleCheckboxChange = (product) => {
        const updatedProducts = selectedProducts.includes(product)
            ? selectedProducts.filter(
                (selectedProduct) => selectedProduct.productId !== product.productId
            )
            : [...selectedProducts, product];
        setSelectedProducts(updatedProducts);
    };
    const handleQuantityChange = (productId, value) => {
        setQuantities({
            ...quantities,
            [productId]: value
        });
    };

    const validateForm = () => {
        if (selectedProducts.length === 0) {
            warningSwal("warning", "Please select at least one product.");
            return false;
        }

        const invalidQuantity = selectedProducts.some(product => {
            const quantity = quantities[product.productId];
            const orderedQuantity = product.qty;

            if (!quantity || isNaN(quantity) || parseInt(quantity, 10) <= 0) {
                warningSwal("warning", "Please provide a valid quantity for  selected products.");
                return true;
            }
            if (parseInt(quantity, 10) > orderedQuantity) {
                warningSwal("warning", "Affected quantity should not exceed the ordered quantity.");
                return true;
            }
            return false;
        });

        if (invalidQuantity) {
            return false;
        }

        if (!notes.trim()) {
            warningSwal("warning", "Please provide comments for the quality difference.");
            return false;
        }
        return true;
    };

    const onSubmit = async () => {

        if (!validateForm()) return;
        const productsData = selectedProducts.map((product) => ({
            oderedItemsId: product?.oderedItemsId,
            productId: product?.productId,
            effectedQuantity: parseInt(quantities[product.productId], 10) || 0
        }));


        const body = {
            orderId: orderDetails.orderId,
            qualityDifferenceNotes: notes,
            products: productsData,
            buyerQD: 1
        };

        try {
            const res = await Services.QualityDifference("POST", JSON.stringify(body), token)
            if (res.Status === 1) {
                setIsOpen(false);
                getOrderdetails();
                SuccessSwal(" Your Request is Successfull", res.Message);
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className={HomeClasses["reject_modal"]}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
                className={HomeClasses["overlaye"]}
            ></div>
            <div className={HomeClasses["update_modal_content"]}>
                <div className={HomeClasses["Qty_header"]}>
                    <h2 className={HomeClasses["resct_he"]}> Order Items </h2>
                    <div className={HomeClasses["d_wrapper"]}>
                        <button type="button" onClick={() => onClose()}><RxCross2 size={25} /></button>
                    </div>
                </div>
                <div className={HomeClasses["qty_model_container"]}>
                    <form onSubmit={handleSubmit(onSubmit)} className={HomeClasses.Qualityform}>
                        {orderDetails?.products?.map((product, index) => {
                            const isChecked = selectedProducts.some(
                                (item) => item.productId === product.productId
                            );
                            return (
                                <div
                                    className={HomeClasses["order_prod_container"]}
                                    key={index}
                                >
                                    <div className={HomeClasses["order_prod_box"]}>
                                        <div className={HomeClasses["checkproduct"]}>
                                            <input
                                                type="checkbox"
                                                onChange={() => handleCheckboxChange(product)}
                                                checked={isChecked}
                                            />
                                        </div>
                                        <div className={HomeClasses["order_prog_img_container"]}>
                                            {
                                                product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                                    <img
                                                        className={HomeClasses["order_prod_image"]}
                                                        src={product.productImageList[0]}
                                                        alt="image"
                                                        onError={(e) => {
                                                            e.currentTarget.onerror = null;
                                                            e.currentTarget.src = Image;
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        className={HomeClasses["order_prod_image"]}
                                                        src={Image}
                                                        alt="image"
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className={HomeClasses["order_prod_info"]}>
                                            <h5 id={HomeClasses["order_prod_name"]}>
                                                {product.productName}
                                            </h5>
                                            <p className={HomeClasses["order_prod_Qty"]}>Qty:{product?.qty}</p>
                                            <p className={HomeClasses["order_prod_Qty"]}>
                                                Effected Qty:{" "}
                                                <input
                                                    className={HomeClasses["qty_input"]}
                                                    value={quantities[product.productId] || ''}
                                                    id={product.productId}
                                                    type="text"
                                                    min={1}
                                                    maxLength={5}
                                                    placeholder="qty"
                                                    onChange={(e) => handleQuantityChange(product.productId, e.target.value)}
                                                    disabled={!isChecked}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className={HomeClasses["Quantity_d_wrapper"]}>
                                        <div className={HomeClasses["d_wrapper"]}>
                                            <div className={HomeClasses["discount_tag"]}>
                                                {
                                                    product && (
                                                        <div className={HomeClasses["dicount_pricetag"]}>
                                                            {product?.cashDiscount > 0 ? (
                                                                <>
                                                                    <p className={HomeClasses["Mdicount"]}>{product.discount}%</p>
                                                                    <p className={HomeClasses["Adicount"]}>{product.cashDiscount}% OFF</p>
                                                                </>
                                                            ) : (
                                                                <p className={HomeClasses["Adicount"]}>{product.discount}% OFF</p>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className={HomeClasses["order_prod_price"]}>
                                            {
                                                product?.productPriceUnit === "Quintal" ? <>
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            M Bag Price :
                                                        </p>
                                                        <div className={HomeClasses["order_rupee"]}>
                                                            <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.bagPrice).toFixed(2)}/Bag</p>
                                                        </div>
                                                    </div>
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            M Price :
                                                        </p>
                                                        <div className={HomeClasses["order_rupee"]}>
                                                            <p className={HomeClasses["Discountpice"]}>  ₹{Number(product.price).toFixed(2)}/Quintal</p>
                                                        </div>
                                                    </div>
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            A Bag Price :
                                                        </p>
                                                        <div className={HomeClasses["order_rupee"]}>
                                                            <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.bagDiscountPrice).toFixed(2)}/Bag</p>
                                                        </div>
                                                    </div>
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            A Price :
                                                        </p>
                                                        <div className={HomeClasses["order_rupee"]}>
                                                            <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}/Quintal</p>
                                                        </div>
                                                    </div>
                                                </> : <> <div className={HomeClasses["order_d_price"]}>
                                                    <p id={HomeClasses["order_price_container"]}>
                                                        M  Price :
                                                    </p>
                                                    <div className={HomeClasses["order_rupee"]}>
                                                        <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.price).toFixed(2)}/{product?.productPriceUnit}</p>
                                                    </div>
                                                </div>
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            A  Price :
                                                        </p>
                                                        <div className={HomeClasses["order_rupee"]}>
                                                            <p className={HomeClasses["Discountpice"]}>  ₹{Number(product?.discountedProductPrice).toFixed(2)}/{product?.productPriceUnit}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                product?.packingBag !== null && (
                                                    <div className={HomeClasses["order_d_price"]}>
                                                        <p id={HomeClasses["order_price_container"]}>
                                                            {product?.packingBag?.bagName} Price :
                                                        </p>
                                                        <p className={HomeClasses["Discountpice"]}>
                                                            ₹{product?.packingBag?.price}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className={HomeClasses["form-Quality"]}>
                            <label htmlFor="notes">Comments</label>
                            <textarea
                                rows={5}
                                placeholder='Write Youe Issues'
                                type="text"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </div>
                        <div className={HomeClasses["banner_form_btns"]}>
                            <button
                                type="submit"
                                className={HomeClasses["banner_e_btn"]}
                            >
                                Done
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default QualityDifference;