import React, { useEffect, useState } from "react";
import topnavclasses from "./topNav.module.css";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";
import { SuccessSwal } from "../utils/ToastMessages";
import { Services } from "../../Services";
import { FaSmile } from "react-icons/fa";

function TopNav() {

    const { notificationCount, setNotificationCount, user, setUser, userActive, setUserActive } = useStore();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [currentTime, setCurrentTime] = useState(new Date());
    let accountType = localStorage.getItem("AccountType");
    const Navigate = useNavigate();

    useEffect(() => {
        Services.getUser("GET", null, token).then((response) => {
            if (response.Status === 1) {
                setUserActive(response.user.isActive);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const UserActive = async (data) => {
        console.log(data)
        Services.UserActiveInActive(
            "PATCH",
            null,
            token,
            data
        ).then((res) => {
            if (res.Status === 1) {
                setUserActive(data)
                SuccessSwal("Success", res.Message);
            }
        }).catch((err) => {
            console.error(err);
        })
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    }, []);

    const tick = () => {
        setCurrentTime(new Date());
    };

    const formatTime = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    };

    return (
        <div className={topnavclasses["TopNav"]}>
            <div className={topnavclasses.headercontainer}>
                <div className={topnavclasses.userinter}>
                    <p className={topnavclasses.hitext}>Hi</p>
                    <p className={topnavclasses.nametext}>{user.name}</p>
                    <p className={topnavclasses.smileicon}><FaSmile size={20} /></p>
                </div>
                <div className={topnavclasses.container}>
                    <div className={topnavclasses.usermobilecontainer}>
                        <p className={topnavclasses.mobiletext}>UID :</p>
                        <p className={topnavclasses.mnumer}>{user.UID}</p>
                    </div>
                    <p id={topnavclasses.line}>|</p>
                    <div className={topnavclasses.usermobilecontainer}>
                        <p className={topnavclasses.mobiletext}>Mobile :</p>
                        <p className={topnavclasses.mnumer}>{user.mobileNumber}</p>
                    </div>
                    <p id={topnavclasses.line}>|</p>
                    <div className={topnavclasses.timecontainer}>
                        <p className={topnavclasses.mobiletext}> Time :</p>
                        <p className={topnavclasses.time}>{formatTime(currentTime)} </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopNav;
