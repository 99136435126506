import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import {
  SuccessSwal,
  ToastError,
  ToastSuccess,
} from "../../../components/utils/ToastMessages";
import moment from "moment";
import styles from "../Subscriptionsdata/S.module.css";
import { GoArrowLeft } from "react-icons/go";

const MySubscription = () => {
  const [details, setDetails] = useState({});
  const [billdetails, setBilldetails] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [paybill, setPaybill] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const [step, setStep] = useState(1);
  const [isSubscribed, setIsSubscribed] = useState();
  const [cancelModal, setCancelModal] = useState(false);
  const Navigate = useNavigate();
  const componentMounted = useRef(true);
  const [ownuserCount, setOwnuserCount] = useState();
  const [marketCount, setMarketCount] = useState();
  const [totalCount, setTotalCount] = useState();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  useEffect(() => {
    getcategories();
    getCategories();
  }, []);

  const paymentStatus = localStorage.getItem("paymentStatus");
  console.log(paymentStatus);
  const freetrail = localStorage.getItem("isFreeTrailActive");
  // console.log("...>?", freetrail)

  const getCategories = async () => {
    try {
      const res = await Services.BillDetails("GET", null, token);
      if (res.Status === 1) {
        setPaybill(res.BillDetails);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };
  // subscription
  const getcategories = async () => {
    try {
      const res = await Services.SubscriptionDetails("GET", null, token);
      if (res.Status === 1) {
        setSubscriptions(res.bills);
        setIsSubscribed(res.isSubscribed);
        setDetails(res.mySubscription);
        setOwnuserCount(res.ownCount);
        setMarketCount(res.marketingSpecialistCount);
        setTotalCount(res.userCount);
        localStorage.setItem("isFreeTrailActive", res?.isFreeTrailActive);
        // setId(res.bills[0].monthlyPaymentId);
        console.log();
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const getdetails = async (id) => {
    try {
      const res = await Services.SingleSubscription("GET", null, token, id);
      setShowModel(true);
      if (res.Status === 1) {
        setBilldetails(res.bill);
      }
    } catch (err) {
      // alert("Somthimg went wrong please try again later");
      console.error(err);
    }
  };
  const billfromDate = moment(billdetails.fromDate).format("YYYY-MM-DD");
  const billtoDate = moment(billdetails.toDate).format("YYYY-MM-DD");
  const handleNavigate = () => {
    setStep(1);
  };

  // const AddPayment = async () => {
  //   let body = {
  //     totalAmount: 5000,
  //   };

  //   try {
  //     const res = await Services.AddPaymentMode(
  //       "POST",
  //       JSON.stringify(body),
  //       token
  //     );
  //     if (res.Status === 1) {
  //       options.order_id = res.razorpayOrderId;
  //       var rzp1 = new window.Razorpay(options);
  //       rzp1.open();
  //     } else if (res.Status === 0) {
  //       ToastError(res.Message);
  //       if (res.Message === "Token expired") {
  //         Navigate("/");
  //         localStorage.clear();
  //       }
  //     }
  //   } catch (err) {
  //     alert("Somthing went wrong please try again later");
  //     console.error(err);
  //   }
  // };

  const DeactivatePlan = async () => {
    // e.stopPropagation();
    try {
      const res = await Services.DeactivateSubscription("POST", null, token);
      if (res.Status === 1) {
        getcategories();
        setDetails({});
        setCancelModal(false);
        SuccessSwal("Success", res.Message);
      }
    } catch (err) {
      // alert("Somthing went wrong please try again");
      console.error(err);
    }
  };

  const InvoiceBill = () => {
    window.open(billdetails.subscriptionInvoice, "_blank");
  };

  return (
    <div className={styles["mysubscription_container"]}>
      {step === 1 ? (

        <>
          <div className={styles["mysubscription"]}>

            <div
              className={styles["subscription_plan"]}
            >
              {
                Object.keys(details).length === 0 ? (<div className={styles.Subs_container}>
                  <div className={styles.ss_container}>
                    <p className={styles["Ousers"]}>Own Users = {ownuserCount}</p>
                    <p className={styles["Musers"]}>Marketing Users= {marketCount}</p>
                    <p className={styles["tusers"]}>Total Users = {totalCount}</p>
                  </div>
                  <p className={styles["Inactive_text"]}>There is  No Active Plan!, Please Subscribe</p>
                </div>) : (<>
                  <h3 className={styles["plan_header"]}>
                    Plan upto {details.baseUsers} Users
                  </h3>
                  <p className={styles["plan_users"]}>
                    {details.pricePerExtraUser} {""}per extra user
                  </p>
                  <p className={styles["total_users"]}>
                    <p className={styles["Ousers"]}>Own Users = {ownuserCount}</p>
                    <p className={styles["Musers"]}>Marketing Users= {marketCount}</p>
                    <p className={styles["tusers"]}>Total Users = {totalCount}</p>
                    <div className={styles["valid_plan"]}>
                      <p className={styles["sub_sts"]}>
                        Subscription Status:{" "}
                        {isSubscribed === 1 ? "Subscribed" : "Subscription cancelled"}
                      </p>
                      {isSubscribed === 1 && (
                        <button
                          type="button"
                          className={styles["deactive_btn"]}
                          onClick={() => setCancelModal(true)}
                        >
                          Cancel Plan
                        </button>
                      )}
                    </div>
                  </p>
                </>)}
            </div>
            {
              Object.keys(details).length === 0 ? "" : (freetrail == 1 ?

                <div className={styles.subsstylecontainer}>
                  <div className={styles.stylecontainer}>
                    <h2 className={styles.header}>Free Trail</h2>
                    <p className={styles.sdate}> Starting date:{" "}{moment(details.fromDate).format('DD-MM-YYYY')}
                    </p>
                    <p className={styles.edate}> Ending date:{" "}  {moment(details.toDate).format('DD-MM-YYYY')}
                    </p>
                  </div>
                  <div className={styles.stylecontainer2}>
                    <h2 className={styles.header}>Bill Cycle</h2>

                    <p className={styles.sdate}> Starting date:{" "}{moment(details.toDate).add(1, 'd').format('DD-MM-YYYY')}
                    </p>
                    <p className={styles.edate}> Next Bill date:{" "}  {moment(details.toDate).endOf('month').format('DD-MM-YYYY')} </p>
                    <p className={styles.sbtext}>New bill will be generated last day of the calender month</p>
                  </div>
                </div> : <div className={styles.subsstylecontainer}>
                  <div className={styles.stylecontainer2}>
                    <h2 className={styles.header}>Bill Cycle</h2>
                    <p className={styles.sdate}> Mothly Plan:{" "}₹{details.basePrice}
                    </p>
                    <p className={styles.sdate}> Starting date:{" "}{moment(details.fromDate).format('DD-MM-YYYY')}
                    </p>
                    <p className={styles.edate}> Next Bill date:{" "}  {moment(details.toDate).format('DD-MM-YYYY')} </p>
                    <p className={styles.sbtext}>New bill will be generated last day of the calender month</p>
                  </div>
                </div>)
            }
            <div className={styles["subscriptions_list"]}>
              <h5 className={styles["details_hedaer"]}>
                Previous Subscriptions
              </h5>
              <table className={styles["P_table"]}>
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Total users</th>
                    <th>Bill Payments</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.length === 0 ? <tr>
                    <td colSpan={6}>No Data Here</td></tr> : (
                    subscriptions.map((item, index) => {
                      const date = moment(item.fromDate).format("YYYY-MM-DD");
                      const dateto = moment(item.toDate).format("YYYY-MM-DD");
                      return (
                        <tr
                          key={index}
                          onClick={() => getdetails(item.monthlyPaymentId)}
                        >
                          <td>
                            {date}, <br />
                            {dateto}
                          </td>
                          <td>{item.baseUsers}</td>
                          <td>{item.paymentStatus === 1 ? "Paid" : "Unpaid"}</td>
                        </tr>
                      );
                    }))}
                </tbody>
              </table>

            </div>
          </div>

        </>
      ) : step === 2 ? (
        <div>
          <div className={["payment_details"]}>
            <div className={styles["product_main_header"]}>
              <button
                className={styles["prod_back_btn"]}
                type="button"
                onClick={() => {
                  setStep(1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={styles["product_header_h2"]}>Bill Details</h1>
            </div>
            <div className={styles["payment_container"]}>
              <div className={styles["address_container_2"]}>
                <div className={styles["login_text_container"]}>
                  <h4 className={styles["conform_ammount"]}>
                    ₹{paybill.totalAmount}
                  </h4>
                  <p className={styles["confirm_todate"]}>Due Date :{billtoDate}</p>
                </div>
                <div className={styles["bill_container"]}>
                  <div className={styles["bill_cycle"]}>
                    <p className={styles["bill_cycle_header"]}>Bill Cycle</p>
                    <p className={styles["bill_cycle_text"]}>
                      {billfromDate}
                      {" - "}
                      {billtoDate}
                    </p>
                  </div>
                  <div className={styles["bill_cycle"]}>
                    <p className={styles["bill_cycle_header"]}>
                      Bill Generated Date
                    </p>
                    <p className={styles["bill_cycle_text"]}></p>
                  </div>
                  <div className={styles["bill_cycle"]}>
                    <p className={styles["bill_cycle_header"]}>Total Users</p>
                    <p className={styles["bill_cycle_text"]}>
                      {paybill.totalUsers}
                    </p>
                  </div>
                  <div className={styles["bill_cycle"]}>
                    <p className={styles["bill_cycle_header"]}>
                      {" - "}Base Users({paybill.baseUsers})
                    </p>
                    <p className={styles["bill_cycle_text"]}>
                      ₹{paybill.basePrice}
                    </p>
                  </div>
                  <div className={styles["bill_cycle"]}>
                    <p className={styles["bill_cycle_header"]}>
                      {" - "}Add on Users({paybill.extraUsers}
                      {" x "}₹{paybill.pricePerExtraUser})
                    </p>
                    <p className={styles["bill_cycle_text"]}>
                      {billdetails.extraUsers * billdetails.pricePerExtraUser}
                    </p>
                  </div>
                </div>
                <div className={styles["total_bill"]}>
                  <p className={styles["total_bill_header"]}>Total</p>
                  <p className={styles["total_bill_text"]}>
                    ₹{paybill.totalAmount}
                  </p>
                </div>
                <div className={styles["confirm_button"]}>
                  {paymentStatus == 1 ? (
                    <>
                      {" "}
                      <h4 className={styles["payment_s_text"]}>
                        Your bill will be generated at the month End.
                      </h4>
                    </>
                  ) : (
                    <>
                      <button
                        className={styles["cub_btn"]}
                        type="button"
                        onClick={() => handleNavigate()}
                      >
                        Maybe,Later
                      </button>
                      {/* <button
                        className={styles["sub_btn"]}
                        type="button"
                        onClick={() => AddPayment()}
                      >
                        Paynow
                      </button> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>Success Page </>

      )}

      {showModel && (
        <div className={styles["delete-modal"]}>
          <div className={styles["delete-modal-content"]}>
            <span
              className={styles["close_modal"]}
              onClick={() => setShowModel(false)}
            >
              &times;
            </span>
            <div className={styles["Bill_container_box"]}>
              <div className={styles["Bill_container"]}>
                <h1 style={{ textAlign: "center", fontSize: "1.2rem" }}>Bill Details</h1>
                <div className={styles["Bill_cycle"]}>
                  <p className={styles["Bill_cycle_header"]}>Bill Cycle</p>
                  <p className={styles["Bill_cycle_text"]}>
                    {billfromDate}
                    {" - "}
                    {billtoDate}
                  </p>
                </div>
                <div className={styles["Bill_cycle"]}>
                  <p className={styles["Bill_cycle_header"]}>
                    Bill Generated Date
                  </p>
                  <p className={styles["Bill_cycle_text"]}>{billtoDate}</p>
                </div>
                <div className={styles["Bill_cycle"]}>
                  <p className={styles["Bill_cycle_header"]}>Total Users</p>
                  <p className={styles["Bill_cycle_text"]}>
                    {billdetails.totalUsers}
                  </p>
                </div>
                <div className={styles["Bill_cycle"]}>
                  <p className={styles["Bill_cycle_header"]}>
                    {" - "}Base Users({billdetails.baseUsers})
                  </p>
                  <p className={styles["Bill_cycle_text"]}>
                    ₹{billdetails.basePrice}
                  </p>
                </div>
                <div className={styles["Bill_cycle"]}>
                  <p className={styles["Bill_cycle_header"]}>
                    {" - "}Add on Users({billdetails.extraUsers}
                    {" x "}₹{billdetails.pricePerExtraUser})
                  </p>
                  <p className={styles["Bill_cycle_text"]}>
                    {billdetails.extraUsers * billdetails.pricePerExtraUser}
                  </p>
                </div>
              </div>
              <div className={styles["Total_bill"]}>
                <p className={styles["Total_bill_header"]}>Total</p>
                <p className={styles["Total_bill_text"]}>
                  ₹{Math.round(billdetails.totalAmount)}
                </p>
              </div>
            </div>
            {
              (billdetails.subscriptionInvoice !== "NA") &&
              <div className={styles["delete_btns"]}>
                <button
                  type="button"
                  onClick={() => InvoiceBill()}
                  className={styles["deactivate_btn"]}
                >
                  Download Invoice
                </button>
              </div>
            }

          </div>
        </div>
      )}
      {cancelModal && (
        <div className={styles["reject_modal"]}>
          <div
            onClick={() => setCancelModal(false)}
            className={styles["overlaye"]}
          ></div>
          <div className={styles["update_modal_content"]}>
            <h2 id={styles["resct_header"]}>Cancel Subscription</h2>
            <div className={styles["update_input_model_container"]}>
              <p className={styles["cancel_text"]}>
                Do you want to cancel the Subscription..!
              </p>

              <div className={styles["banner_form_btns"]}>
                <button
                  type="submit"
                  className={styles["banner_btn"]}
                  onClick={() => setCancelModal(false)}
                >
                  No
                </button>
                <button
                  type="submit"
                  className={styles["banner_e_btn"]}
                  onClick={() => DeactivatePlan()}
                >
                  yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MySubscription;
