import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShopServices } from "./ShopServicess";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import Layout from "../../components/Layout/layout";
import { GoArrowLeft } from "react-icons/go";
import ShopClass from "../Shop/shop.module.css";
import emptycart from "../../assets/empty-cart.png";
import Image from '../../assets/noplot.png'
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { Services } from "../../Services";
import { FaEdit } from "react-icons/fa";
import AddTransportModal from "./AddTransportModal";
import Agents from "./Agents";

const CartPage = () => {
  const [cartDetails, setCartDetails] = useState(JSON.parse(localStorage.getItem('cartDetails')) || []);
  const [TotalBill, setTotalBill] = useState(0);
  const [agentorderPrice, setAgentorderPrice] = useState(0);
  const [BusinessName, setBusinessName] = useState("");
  const [groupname, setGroupname] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [cartID, setCartID] = useState();
  const [moq, setMoq] = useState();
  const [moqType, setMoqType] = useState();
  const [manufacturerId, setManufacturerId] = useState();
  const navigate = useNavigate();
  const userId = localStorage.getItem("agentId");
  const [orderTypes, setOrderTypes] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [orderThrough, setOrderThrough] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [totalQuintal, setTotalQuintal] = useState(0);
  const [totalTones, setTotalTones] = useState(0);
  const [millerCommission, setMillerCommission] = useState(0);
  const [distributorCommission, setDistributorCommission] = useState(0);
  const [subBrokerCommissions, setSubBrokerCommissions] = useState(0);
  const [hamalicharges, setHamaliCharges] = useState(0);
  const [priceTagOpen, setPriceTagOpen] = useState(null);
  const [slectedOrderType, setSlectedOrderType] = useState({});
  const [selectedDeliveryType, setSelectedDeliveryType] = useState({})
  const [selectedOrderThrough, setSelectedOrderThrough] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [millerDropdown, setMillerDropdown] = useState([]);
  const [distributorDropdown, setDistributorDropdown] = useState([]);
  const [hamalidropDown, setHamalidropDown] = useState([]);
  const [agentSubtotal, setAgentSubtotal] = useState(null);
  const [bagspricess, setBagspricess] = useState(0);
  const [cashDays, setCashDays] = useState([]);
  const [subBrokerCommision, setSubBrokerCommision] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [agentAddress, setAgentAddress] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const deliveryType = watch("deliveryType");
  const subbroker = watch("orderThrough");
  const transport = JSON.parse(localStorage.getItem("Transport Details"));
  const selectedBuyer = JSON.parse(localStorage.getItem("selectedUser"));
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, []);

  // Permissions for Agent office team
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const CartPermission = addPermissions.filter(
    (item) => item.permissionId === 18
  );
  const Permission = CartPermission.length > 0 ? CartPermission[0].status : 1;

  const getCartItems = async () => {
    try {
      const res = await ShopServices.getCartItems("GET", null, token);
      if (res.Status === 1) {
        setCartDetails(
          res.cartItems.map((item) => {
            // const Quintalprice = () => {
            //   let quintalPrice = item?.price;
            //   if (item?.packingBag) {
            //     if (item.packingBag?.bagId === 1) {
            //       quintalPrice -= parseFloat(item.packingBag?.price);
            //     } else if (item.packingBag?.bagId === 2 || item.packingBag?.bagId === 3) {
            //       quintalPrice += parseFloat(item.packingBag?.price ? item.packingBag?.price : 0);
            //     }
            //   } else {
            //     quintalPrice = item?.price;
            //   }
            //   console.log(`Bag ID: ${item?.packingBag?.bagId}, Bag Price: ${item?.packingBag?.price}, Calculated Quintal Price: ${quintalPrice}`);
            //   return quintalPrice;
            // }

            if (
              String(item.productPriceUnit) === "Quintal" &&
              item.productMeasuringUnit === "Bag"
            ) {
              const bagPrice = (item.productBagWeightInKgs * item.price) / 100;
              const bagdiscountPrice = (item.productBagWeightInKgs * item?.price) / 100;
              return {
                ...item,
                bagPrice: bagPrice,
                bagDiscountPrice: bagdiscountPrice - (bagdiscountPrice * (item?.cashDiscount > 0 ? item?.cashDiscount : item?.discount)) / 100,
                discountedProductPrice: item?.price - (item?.price * (item?.cashDiscount > 0 ? item?.cashDiscount : item.discount)) / 100,
                agentPrice: item?.price
              };
            }
            return {
              ...item,
              discountedProductPrice: item?.price - (item.price * (item?.cashDiscount > 0 ? item?.cashDiscount : item.discount)) / 100,
              agentPrice: item?.price,

            };
          }));

        setCartID(res.cartId);
        setMoq(res.MOQ);
        setMoqType(res.moqType);
        setManufacturerName(res.manufacturerName);
        setGroupname(res.groupname);
        setManufacturerId(res.manufacturerId);
        setBusinessName(res.manufacturerName);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // console.log("cartDetails", cartDetails);
  // console.log("priceCartDetails", priceCartDetails);
  localStorage.setItem("cartDetails", JSON.stringify(cartDetails));
  useEffect(() => {
    if (cartDetails.length > 0) {

      const totalCartWeightInQuintal = cartDetails.reduce((acc, curr) => {
        if (typeof curr.weightInQuintal === 'number') {
          return acc + curr.weightInQuintal;
        }
        return acc;
      }, 0);
      localStorage.setItem("totalQuintalWeight", totalCartWeightInQuintal);
      setTotalQuintal(totalCartWeightInQuintal);

      const totalTonsWeight = cartDetails.reduce((acc, curr) => {
        if (typeof curr.weightInTons === 'number') {
          return acc + curr.weightInTons;
        }
        return acc;
      }, 0);
      setTotalTones(totalTonsWeight);
    }

    if (cartDetails.length > 0) {
      const totalCartPrice = cartDetails.reduce((acc, curr) => {
        if (
          String(curr?.productPriceUnit) === "Quintal"
        ) {
          return curr?.bagPrice * curr?.qty + acc;
        }
        return curr?.price * curr?.qty + acc;
      }, 0);
      setTotalBill(totalCartPrice);
    }
    if (cartDetails.length > 0) {
      let AgentsubTotalPrice = cartDetails.reduce((acc, curr) => {
        if (
          String(curr?.productPriceUnit) === "Quintal"
        ) {
          return curr?.bagDiscountPrice * curr?.qty + acc;
        }
        return curr?.discountedProductPrice * curr?.qty + acc;
      }, 0);
      setAgentSubtotal(AgentsubTotalPrice);
    }
  }, [cartDetails]);

  // Agent price calculation
  useEffect(() => {
    if (cartDetails.length > 0) {
      const totalagentOrderprice = cartDetails.reduce((acc, curr) => {
        if (
          String(curr?.productPriceUnit) === "Quintal" && curr?.packingBag) {
          if (curr?.packingBag?.bagId === 1) {
            return curr?.bagDiscountPrice * curr?.qty - (curr?.packingBag?.price * curr.weightInQuintal) + acc;
          } else if (curr?.packingBag?.bagId === 2 || curr?.packingBag?.bagId === 3)
            return curr?.bagDiscountPrice * curr?.qty + (curr?.packingBag?.price * curr.weightInQuintal) + acc;
        }
        return curr?.discountedProductPrice * curr?.qty + acc;
      }, 0);
      setAgentorderPrice(totalagentOrderprice);
      // console.log('totalprice', totalagentOrderprice);
    }

    if (cartDetails.length > 0) {
      let bag1Price = 0;
      let otherBagsPrice = 0;

      cartDetails.reduce((acc, curr) => {
        if (String(curr?.productPriceUnit) === "Quintal" && curr?.packingBag) {
          const bagPrice = (curr?.packingBag?.price !== null ? curr?.packingBag?.price : 0) * curr.weightInQuintal;

          if (curr?.packingBag?.bagId === 1) {
            bag1Price += bagPrice;
          } else if (curr?.packingBag?.bagId === 2 || curr?.packingBag?.bagId === 3) {
            otherBagsPrice += bagPrice;
          }
        }
        return acc;
      }, 0);

      const netPrice = otherBagsPrice - bag1Price;
      setBagspricess(netPrice);
      // console.log("bagsPrices", netPrice);
    }

  }, [cartDetails])

  useEffect(() => {
    getCartItems();
    getorderTypes();
    getDeliveryTypes();
    getOrderThrough();
    getConfigs();
  }, []);


  // drop down data
  const getorderTypes = async () => {
    try {
      const response = await Services.OrderTypes("GET", null, token);
      if (response.Status === 1) {
        setOrderTypes(response.orderType);
      } else if (response.Status === 0) {
        warningSwal("warning", response.Message);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getDeliveryTypes = async () => {
    try {
      const response = await Services.DeliveryTypes("GET", null, token);
      if (response.Status === 1) {
        setDeliveryTypes(response.deliveryType);
      } else if (response.Status === 0) {
        warningSwal("warning", response.Message);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getOrderThrough = async () => {
    try {
      const response = await Services.OrderThrough("GET", null, token);
      if (response.Status === 1) {
        setOrderThrough(response.deliveryThrough);
      } else if (response.Status === 0) {
        warningSwal("warning", response.Message);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getConfigs = async () => {
    try {
      const res = await ShopServices.ConfigDropdowns("GET", null, token);
      if (res.Status === 1) {
        setDropdownOptions(res.config);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (dropdownOptions.length > 0) {
      const MCommisiondropdown = dropdownOptions?.find(
        (config) => config.configId === 9
      );
      const Dcommisiondropdown = dropdownOptions?.find(
        (config) => config.configId === 8
      );
      const SubBrokerCommisson = dropdownOptions?.find(
        (config) => config.configId === 16
      );
      const Hchargesdropdown = dropdownOptions?.find(
        (config) => config.configId === 11
      );
      const Cashdiscountdays = dropdownOptions?.find(
        (config) => config.configId === 14
      );

      if (MCommisiondropdown) {
        let numbers = Array.from({ length: Number(MCommisiondropdown?.configValue) }, (_, i) => i + 1);
        setMillerDropdown(numbers);
      }
      // console.log(millerDropdown);
      if (Dcommisiondropdown) {
        let numbers = Array.from({ length: parseInt(Dcommisiondropdown?.configValue, 10) }, (_, i) => i + 1);
        setDistributorDropdown(numbers);
      }
      if (SubBrokerCommisson) {
        let numbers = Array.from({ length: parseInt(SubBrokerCommisson?.configValue, 10) }, (_, i) => i + 1);
        setSubBrokerCommissions(numbers);
      }

      if (Hchargesdropdown) {
        let numbers = Array.from({ length: parseInt(Hchargesdropdown?.configValue, 10) }, (_, i) => i + 1);
        setHamalidropDown(numbers);
      }

      if (Cashdiscountdays) {
        const configValue = Cashdiscountdays?.configValue;
        if (configValue.includes('-')) {

          const [from, to] = configValue.split('-').map(Number);

          if (!isNaN(from) && !isNaN(to) && from < to) {
            let numbers = Array.from({ length: to - from + 1 }, (_, i) => from + i);
            setCashDays(numbers);
          }
        }
      }
    }
  }, [dropdownOptions]);
  // remove all cart Items

  const removeItemsFromCart = async () => {
    try {
      const res = await ShopServices.removeAllcartItems(
        "PATCH",
        null,
        token,
        cartID
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        getCartItems();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // remove cart product

  const deleteProduct = async (product) => {
    let body = { cartId: cartID, cartItemsId: product.cartItemsId };
    try {
      const res = await ShopServices.removeCartItems(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        getCartItems();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addToCart = async (product) => {
    try {
      let calculatedQuantity = product.qty;

      if (
        String(product.productPriceUnit).toLowerCase() !==
        String(product.packageType).toLowerCase()
      ) {
        let maxAllowedQuantity =
          product.stockQty;

        maxAllowedQuantity =
          product.productPriceUnit == "Quintal" && maxAllowedQuantity;

        if (calculatedQuantity > maxAllowedQuantity) {
          alert(
            `Max allowed quantity for ${product.productName
            } should be less than are equal  ${Math.floor(maxAllowedQuantity)} stock`
          );
          return;
        }
      } else {
        if (calculatedQuantity >= product.stockQty) {
          alert(
            ` Max allowed quantity for ${product.productName} should be less than are equal  ${product.stockQty} ${product.packageType}`
          );
          return;
        }
      }
      const body = {
        cartItem: {
          manufacturerId: manufacturerId,
          productId: product.productId,
          qty: product.qty + 1,
          groupId: product.groupId,
          price: product.price,
          packageType: product.packageType,
        },
        productId: product.productId,
      };
      const res = await ShopServices.AddToCart(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {

        const updatedCartDetails = [...cartDetails];
        const existingCartItemIndex = updatedCartDetails.findIndex(
          (item) => item.productId === product.productId
        );

        if (existingCartItemIndex !== -1) {
          const existingCartItem = updatedCartDetails[existingCartItemIndex];
          existingCartItem.qty += 1;

          if (existingCartItem.qty < 1) {
            existingCartItem.qty = 1;
          }

          existingCartItem.weightInQuintal = (existingCartItem.productBagWeightInKgs * existingCartItem.qty) / 100;
          existingCartItem.weightInTons = existingCartItem.weightInQuintal / 10;

          existingCartItem.agentPrice = product.agentPrice;
          existingCartItem.cashDiscount = product.cashDiscount;
          existingCartItem.packingBag = product.packingBag;

          // Update the cart details state
          setCartDetails(updatedCartDetails);
        } else {
          setCartDetails((prev) => [...prev, { ...product, qty: 1 }]);
        }

      }
    } catch (err) {
      console.error(err);
    }
  };

  const decrementQty = async (product) => {
    try {
      if (product.qty > 0) {
        const body = {
          cartItem: {
            manufacturerId: manufacturerId,
            productId: product.productId,
            qty: product.qty - 1,
            groupId: product.groupId,
            price: product.price,
            packageType: product.packageType,
          },
          productId: product.productId,
        };
        const res = await ShopServices.AddToCart(
          "POST",
          JSON.stringify(body),
          token
        );
        if (res.Status === 1) {
          const updatedCartDetails = [...cartDetails];
          const existingCartItemIndex = updatedCartDetails.findIndex(
            (item) => item.productId === product.productId
          );

          if (existingCartItemIndex !== -1) {
            const existingCartItem = updatedCartDetails[existingCartItemIndex];
            existingCartItem.qty -= 1;

            if (existingCartItem.qty < 1) {
              existingCartItem.qty = 1;
            }

            existingCartItem.weightInQuintal = (existingCartItem.productBagWeightInKgs * existingCartItem.qty) / 100;
            existingCartItem.weightInTons = existingCartItem.weightInQuintal / 10;

            existingCartItem.agentPrice = product.agentPrice;
            existingCartItem.cashDiscount = product.cashDiscount;
            existingCartItem.packingBag = product.packingBag;

            // Update the cart details state
            setCartDetails(updatedCartDetails);
          }
          if (product.qty === 0) {
            await deleteProduct(product);
          }
        }
      } else {
        warningSwal("warning", "Product quantity is already zero");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const placeOrder = async (data) => {

    if (localStorage.getItem("address")) {
      let updatedBussinessName =
        accountType == 4 || accountType == 5
          ? localStorage.getItem("agentName")
          : localStorage.getItem("BusinessName");

      let selectedAccount =
        accountType == 4 || accountType == 5
          ? JSON.parse(localStorage.getItem("MAaccountType"))
          : JSON.parse(localStorage.getItem("AccountType"));
      let orderDetails = cartDetails.map((item) => {
        if (
          String(item.productPriceUnit) === "Quintal" &&
          item.productMeasuringUnit === "Bag"
        ) {
          return {
            ...item,
            bagPrice: item.bagPrice,
            price: item.price,
            discountedProductPrice: item.discountedProductPrice,
            bagDiscountPrice: item.bagDiscountPrice
          };
        }
        return {
          ...item,
          price: item.price,
          discountedProductPrice: item.discountedProductPrice
        };
      })
      console.log(orderDetails);
      let body = {
        ...data,
        orderType: slectedOrderType,
        deliveryType: selectedDeliveryType,
        orderThrough: selectedOrderThrough,
        subAgent: selectedUser,
        cartId: cartID,
        deliveryAddress: localStorage.getItem("address"),
        userName: updatedBussinessName,
        manufacturerId: manufacturerId,
        accountType: selectedAccount,
        items: orderDetails,
        totalPrice: TotalBill,
        totalPackingBags: bagspricess,
        agentOrderPrice: agentorderPrice,
        agentSubtotalPrice: agentSubtotal,
        orderFor: userId,
        transportDetails: transport,
        totalWeightInQuintal: totalQuintal,
        totalWeightInTons: totalTones,
        transportCommissionPerQuintal: transport?.transportCommissionPerQuintal
      };
      // console.log(totalCartweight);

      if (accountType == 1 || accountType == 2 || accountType == 3) {
        if (moqType == 1) {
          if (TotalBill - totalDiscount < moq) {
            warningSwal("warning", `Please maintain this minimum cart price ₹${moq}`);
            return;
          }
        }
      }
      console.log(body);
      try {
        const res = await ShopServices.PlaceOrder("POST", JSON.stringify(body), token);
        if (res.Status == 1) {
          navigate("/OrderSuccessful");
          localStorage.removeItem("Transport Details");
          localStorage.removeItem("address");
          localStorage.removeItem("cartDetails");
          localStorage.removeItem("selectedUser");
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      warningSwal("warning", "Please select address");
    }
  };

  const AddressPage = () => {
    navigate("/address");
  };

  const AgentsPage = () => {
    navigate("/agents");
  };

  const ModalOpen = () => {
    setIsOpen(true);
    setEdit(false);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const EditTransport = () => {
    setIsOpen(true);
    setEdit(true);
  }

  // for  commisions
  useEffect(() => {
    if (millerCommission && totalQuintal > 0) {

      setValue("millerCommission", parseFloat(
        (totalQuintal * millerCommission)).toFixed(2));
    };

    if (hamalicharges && totalQuintal > 0) {

      setValue("hamaliCharges", parseFloat(
        (totalQuintal * hamalicharges)).toFixed(2));
    };

    if (distributorCommission && totalQuintal > 0) {

      setValue("distributorCommission", parseFloat(
        (totalQuintal * distributorCommission)).toFixed(2));
    }

    if (transport && totalQuintal > 0) {
      setValue("transportCommission", parseFloat(totalQuintal * transport.differenceBooking), 10);
    };

    if (subBrokerCommision && totalQuintal > 0) {
      setValue("subBrokerCommssion", parseFloat(
        (totalQuintal * subBrokerCommision)).toFixed(2));
    };

  }, [totalQuintal, millerCommission, hamalicharges, subBrokerCommision, distributorCommission, transport, setValue]);


  // default bag setting
  useEffect(() => {
    const defaultBag = cartDetails.map((product) => {
      if (!product?.packingBag) {
        const defaultBag = product?.packingBagPrice?.find(
          (bag) => bag.bagId == 2
        );

        if (defaultBag) {
          setCartDetails((curr) => {
            const updatedDetails = [...curr];
            const productIndex = updatedDetails.findIndex(
              (p) => p.productId === product.productId
            );
            updatedDetails[productIndex]["packingBag"] = defaultBag;
            return updatedDetails;
          });
        }
      }
    });
  }, [cartDetails, setCartDetails]);

  // adding agent product price
  const addPrice = (id) => {
    setPriceTagOpen(id);
  };

  // const calculateQuintalPrice = (item) => {
  //   let quintalPrice = item.agentPrice !== item.price ? item.agentPrice : item.price;

  //     if (item.packingBag) {
  //       const bagPrice = parseFloat(item.packingBag.price || 0);
  //       if (item.packingBag.bagId === 1) {
  //         quintalPrice -= bagPrice;
  //       } else if (item.packingBag.bagId === 2 || item.packingBag.bagId === 3) {
  //         quintalPrice += bagPrice;
  //       }
  //     }
  //     return quintalPrice;
  // };

  // Function to calculate all related prices
  const calculatePrices = (item) => {
    // const quintalPrice = calculateQuintalPrice(item);
    const quintalPrice = item.agentPrice !== item.price ? item.agentPrice : item.price;
    const discount = item.cashDiscount > 0 ? item.cashDiscount : item.discount;
    const discountedProductPrice = quintalPrice - (quintalPrice * discount) / 100;
    const bagDiscountPrice = (discountedProductPrice * item.productBagWeightInKgs) / 100;

    return {
      ...item,
      discountedProductPrice,
      bagDiscountPrice,
    };
  };

  // Handle increment action
  const handleIncrement = (product) => {
    setCartDetails((curr) =>
      curr.map((item) => {
        if (item.productId === product.productId) {
          const newAgentPrice = item.agentPrice + 1;
          // localStorage.setItem('agentPrice', newAgentPrice);
          return calculatePrices({ ...item, agentPrice: newAgentPrice });
        }
        return item;
      })
    );
  };

  // Handle decrement action
  const handleDecrement = (product) => {
    setCartDetails((curr) =>
      curr.map((item) => {
        if (item.productId === product.productId && item.agentPrice > 0) {
          const newAgentPrice = item.agentPrice - 1;
          // localStorage.setItem('agentPrice', newAgentPrice);
          return calculatePrices({ ...item, agentPrice: newAgentPrice });
        }
        return item;
      })
    );
  };

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    try {
      const res = await Services.getOwnUsers("GET", null, token);
      if (res.Status === 1) {
        const filteredAgents = res.ownUsers.filter(user => user.accountType === 5);
        setAgents(filteredAgents);
      }
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    if (selectedOrderThrough.orderThroughId === 2) {

      setValue('distributorCommission', null);
      setValue('distributionCommissionPerQuintal', '');
    }
  }, [selectedOrderThrough.orderThroughId, setValue]);

  const AgentModal = () => {
    setAgentAddress(true);
  }

  return (
    <Layout Active={"Shop"}>
      <div className={ShopClass["home_wrapper"]}>
        <div className={ShopClass["Add_main_container"]}>

          <div className={ShopClass["Add_header"]}>
            <button
              className={ShopClass["add_back_btn"]}
              type="button"
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("Transport Details");
                localStorage.removeItem("address");
                localStorage.removeItem("cartDetails");
                localStorage.removeItem("selectedUser");
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={ShopClass["add_header_h2"]}>Order Review</h1>
          </div>
          <div className={ShopClass["form_wrapper"]}>
            <form onSubmit={handleSubmit(placeOrder)}>
              <div className={ShopClass.cart_wrapper}>
                <div className={ShopClass["cart_container"]}>
                  {cartDetails.length === 0 ? (
                    <div className={ShopClass["empty_products"]}>
                      <div className={ShopClass["empty_image_container"]}>
                        <img
                          className={ShopClass["empty_image"]}
                          src={emptycart}
                          alt="image"
                        />
                      </div>
                      <p className={ShopClass["empty_portal_1"]}>
                        Your Cart is empty, Please add products and Place the order
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className={ShopClass["cart_address"]}>
                        <div className={ShopClass["address_boxis"]}>
                          {
                            accountType == 5 ? <h4>Order For</h4> : <h4>Delivered Address</h4>
                          }
                          {
                            accountType == 5 ? (
                              localStorage.getItem("selectedUser") && localStorage.getItem("selectedUser") !== "" ? (
                                <div className={ShopClass.transport_Info}>
                                  <div className={ShopClass["user_image_container"]}>
                                    <div className={ShopClass["group_user_initial"]}>
                                      {selectedBuyer?.name ? selectedBuyer?.name.charAt(0) : ""}
                                    </div>
                                  </div>
                                  <div className={ShopClass["group_user_info"]}>
                                    <p>{selectedBuyer?.name || ""}</p>
                                    <p>
                                      {selectedBuyer?.accountType === 2
                                        ? "Distributor "
                                        : selectedBuyer?.accountType === 3
                                          ? "Retailer"
                                          : selectedBuyer?.accountType === 4
                                            ? "Marketing Specialist"
                                            : "Office Team"}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <p className={ShopClass["emptytext"]}>No Users selected</p>
                              )
                            ) : (<div className={ShopClass["address_boxis"]}>
                              {localStorage.getItem("address")}
                            </div>)
                          }
                        </div>

                        <div className={ShopClass["address_edit"]}>
                          {accountType == 4 || accountType == 5 ? (
                            <button
                              type="button"
                              onClick={() => AgentModal()}
                              className={ShopClass["manage_address"]}
                            >
                              Add Buyer
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => AddressPage()}
                              className={ShopClass["manage_address"]}
                            >
                              Add Address
                            </button>
                          )}
                        </div>
                      </div>

                      <div className={ShopClass["cart_main_box"]}>
                        <div className={ShopClass["m_products_header_cart_items"]}>
                          <p className={ShopClass["moq_rupee"]}>
                            {groupname}-{manufacturerName}
                          </p>
                        </div>
                        {
                          (moqType != 3 && accountType != 4 && accountType != 5) && (<div className={ShopClass["m_products_header_cart_items"]}>
                            <p className={ShopClass["moq_rupee"]}>
                              Minimum Order Value: {moq}.0 INR
                            </p>
                          </div>)
                        }
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="agentRecordNumber">Broker RecordNumber</label>
                          <input
                            type="text"
                            placeholder='Broker RecordNumber'
                            {...register("agentRecordNumber", {
                              required: "this field is required",
                              pattern: {
                                value: /^[a-zA-Z0-9 _.-]+$/,
                                message: "Agent RecordNumber  is Invalid format"
                              },
                            })}
                          />
                          {errors.agentRecordNumber && <span>{errors.agentRecordNumber.message}</span>}
                        </div>

                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="orderType">Order Type</label>
                          <select
                            placeholder='Order Type'
                            {...register("orderType", {
                              required: 'this field is required',
                            })}
                            onChange={(e) => {
                              let selectedType = e.target.value
                              const orderType = orderTypes.find(item => item.orderTypeId == selectedType)
                              setSlectedOrderType(orderType);
                              console.log("orderType", orderType);
                            }}

                          >
                            <option value="" disabled selected> Order Type</option>
                            {orderTypes.map((order, index) => (
                              <option key={order.orderTypeId} value={order.orderTypeId}>{order.orderType}</option>
                            ))}
                          </select>
                          {errors.orderType && <span>{errors.orderType.message}</span>}
                        </div>

                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="deliveryType"> Delivery Type</label>
                          <select
                            placeholder='Delivery Type'
                            {...register("deliveryType", {
                              required: "this field is required",
                            })}

                            onChange={(e) => {
                              let selectedType = e.target.value
                              const deliveryType = deliveryTypes.find(item => item.deliveryTypeId == selectedType)
                              setSelectedDeliveryType(deliveryType);
                              console.log("deliveryType", deliveryType);
                            }}
                          >
                            <option value="" disabled selected>
                              Delivery Type
                            </option>
                            {
                              deliveryTypes.map((delivery, index) => (
                                <option key={delivery.deliveryTypeId} value={delivery.deliveryTypeId}>{delivery.deliveryType}</option>
                              ))}
                          </select>
                          {errors.deliveryType && <span>{errors.deliveryType.message}</span>}
                        </div>
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="loadingDate"> Loading Date</label>
                          <input
                            type="date"
                            placeholder='Loading Date'
                            min={new Date().toISOString().split("T")[0]}
                            {...register("loadingDate", {
                              required: false,
                            })}
                          />
                          {errors.loadingDate && <span>{errors.loadingDate.message}</span>}
                        </div>

                        {
                          selectedDeliveryType.deliveryTypeId === 2 && (
                            localStorage.getItem("Transport Details") ? (
                              <div className={ShopClass.TransportContainer}>
                                <div className={ShopClass.transport_header}>
                                  <h3>Transport details</h3>
                                  <button type="button" className={ShopClass.transportEdit} onClick={() => EditTransport()}>
                                    <FaEdit size={20} />
                                  </button>
                                </div>
                                <div className={ShopClass.transport_Info}>
                                  <div className={ShopClass["user_image_container"]}>
                                    <div className={ShopClass["group_user_initial"]}>
                                      {transport?.name
                                        ? transport?.name.charAt(0)
                                        : ""}
                                    </div>
                                  </div>
                                  <div className={ShopClass["group_user_info"]}>
                                    <p>{transport.name}</p>
                                    <p>{transport.driverNumber}</p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className={ShopClass["Addtarnsportbtn"]}>
                                <button
                                  type="button"
                                  className={ShopClass.addTransport}
                                  onClick={() => ModalOpen()}
                                >
                                  ADD TRANSPORTATION DETAILS
                                </button>
                              </div>
                            )
                          )
                        }
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="orderThrough">Order Through</label>
                          <select
                            placeholder='Order Through'
                            {...register("orderThrough", {
                              required: "this field is required",
                            })}

                            onChange={(e) => {
                              let selectedType = e.target.value
                              const orderthrough = orderThrough.find(item => item.orderThroughId == selectedType)
                              setSelectedOrderThrough(orderthrough);
                              console.log("orderThrough", orderthrough);
                            }}
                          >
                            <option value="" disabled selected>Order Through</option>

                            {
                              orderThrough.map((order, index) => (
                                <option key={order.orderThroughId} value={order.orderThroughId}>{order.orderThrough}</option>
                              ))}
                          </select>
                          {errors.orderThrough && <span>{errors.orderThrough.message}</span>}
                        </div>

                        {
                          selectedOrderThrough.orderThroughId === 2 ?
                            <div className={ShopClass["form-control"]}>
                              <label htmlFor="subAgent">Sub Broker</label>
                              <select
                                {...register("subAgent", {
                                  required: "this field is required",
                                })}

                                onChange={(e) => {
                                  let selectedType = e.target.value
                                  const selectedBroker = agents.find(item => item.userId == selectedType)
                                  setSelectedUser(selectedBroker);
                                  console.log("selectedBroker", selectedBroker);
                                }}
                              >
                                <option value="">Select User</option>
                                {
                                  agents.map((user, index) => (
                                    <option key={user.userId} value={user.userId}>{user.name}</option>
                                  ))}
                              </select>
                              {errors.subAgent && <span>{errors.subAgent.message}</span>}
                            </div> : ""}

                        <div className={ShopClass["cart_items_buttons"]}>
                          <h3 className={ShopClass["cart_items_count"]}>
                            Cart Items ({cartDetails.length})
                          </h3>
                          <div>
                            <button
                              type="button"
                              onClick={() => removeItemsFromCart()}
                              className={ShopClass["cart_items_remove_button"]}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                        {cartDetails.map((product, index) => {
                          return (
                            <div className={ShopClass["cart_product-wrapper"]}>
                              <div className={ShopClass["cart_p_box"]} key={index}>
                                <div className={ShopClass["cart_product"]}>
                                  <div className={ShopClass["cartr_p_img_container"]}>
                                    {
                                      product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                        <img
                                          className={ShopClass["cart_p_image"]}
                                          src={product.productImageList[0]}
                                          alt="image"
                                          onError={(e) => {
                                            e.currentTarget.onerror = null;
                                            e.currentTarget.src = Image;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className={ShopClass["cart_p_image"]}
                                          src={Image}
                                          alt="image"
                                        />
                                      )
                                    }
                                  </div>
                                  <div className={ShopClass["cart_p_info"]}>
                                    <p className={ShopClass["cart_P_name"]}>
                                      {product.productName}
                                    </p>
                                    <p className={ShopClass["cart_P_categorys"]}>
                                      {product?.productCategory} {" . "}{product?.productSubCategory}
                                    </p>
                                    <div className={ShopClass["Product-Q-weight"]}>
                                      <p className={ShopClass["cart_P_qty"]}>
                                        Quintal:
                                      </p>
                                      <p className={ShopClass['weight']}>
                                        {Number(product?.weightInQuintal).toFixed(2)}
                                      </p>
                                    </div>
                                    <div className={ShopClass["Product-Q-weight"]}>
                                      <p className={ShopClass["cart_P_qty"]}>
                                        Tons:
                                      </p>
                                      <p className={ShopClass['weight']}>
                                        {Number(product?.weightInTons).toFixed(2)}
                                      </p>
                                    </div>
                                    {product.productBagWeightInKgs !== 0 &&
                                      (
                                        <p className={ShopClass["cart_P_qty"]}>
                                          Qty: {product.productBagWeightInKgs}
                                          KG'S /
                                          {product.productMeasuringUnit}
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className={ShopClass["cart_btn_conatiner"]}>
                                  <div className={ShopClass["cart_delete_btn"]}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        deleteProduct({
                                          cartId: cartID,
                                          cartItemsId: product.cartItemsId,
                                        })
                                      }
                                      className={ShopClass["cart_p_d"]}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                  <div className={ShopClass["shop_cart_buttons"]}>
                                    <button
                                      type="button"
                                      className={ShopClass["decrement"]}
                                      onClick={() => decrementQty(product)}
                                    >
                                      -
                                    </button>
                                    <span className={ShopClass["cart_count"]}>
                                      {product.qty}
                                    </span>
                                    <button
                                      type="button"
                                      className={ShopClass["increment"]}
                                      onClick={() => addToCart(product)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className={ShopClass["cart_prices_wraper"]}>
                                <div className={ShopClass.cart_prices}>
                                  {product?.productPriceUnit == "Quintal" ?
                                    <>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Miller Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.bagPrice).toFixed(2)}/Bag</p>
                                      </div>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Miller Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.price).toFixed(2)}/Quintal</p>
                                      </div>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Broker Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.bagDiscountPrice).toFixed(2)}/Bag</p>
                                      </div>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Broker Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.discountedProductPrice).toFixed(2)}/Quintal</p>
                                      </div>
                                    </> :
                                    <>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Miller Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.price).toFixed(2)}/{product?.productPriceUnit}</p>
                                      </div>
                                      <div className={ShopClass["prices"]}>
                                        <p className={ShopClass["price_heading"]}>Broker Price :</p>
                                        <p className={ShopClass["pricetag"]}>₹{Number(product?.discountedProductPrice).toFixed(2)}/{product?.productPriceUnit}</p>
                                      </div>
                                    </>
                                  }
                                </div>
                                <div className={ShopClass.cart_prices_wrap}>
                                  {/* prices  with Bag calculations*/}
                                  {/* <div className={ShopClass.cart_info_subwrapper}>
                                  <div className={ShopClass["prices"]}><p className={ShopClass["price_heading"]}>Price :</p><div className={ShopClass.pricetag}>
                                    {
                                      product?.packingBag ?
                                        (product.packingBag?.bagId === 1 ?
                                          <div className={ShopClass.prices}>
                                            <p className={ShopClass.price1}>₹{Number(product?.bagPrice).toFixed(2)}/{product.productMeasuringUnit}</p>
                                            <p className={ShopClass.pricetag}>₹{Number(product?.bagDiscountPrice).toFixed(2)}/ {product.productMeasuringUnit}</p></div> :
                                          (product.packingBag?.bagId === 3 ?
                                            <div className={ShopClass.prices}>
                                              <p className={ShopClass.price1}>₹{Number(product?.bagPrice).toFixed(2)}/{product.productMeasuringUnit}</p>
                                              <p className={ShopClass.pricetag}>₹{Number(product?.bagDiscountPrice).toFixed(2)}/{product.productMeasuringUnit}</p></div> :
                                            (product.packingBag?.bagId === 2 ?
                                              <div className={ShopClass.prices}>
                                                <p className={ShopClass.price1}>₹{Number(product?.bagPrice).toFixed(2)}/{product.productMeasuringUnit}</p>
                                                <p className={ShopClass.pricetag}>₹{Number(product?.bagDiscountPrice).toFixed(2)}/{product.productMeasuringUnit}</p></div> :
                                              ""
                                            )
                                          )
                                        ) : <div className={ShopClass.prices}>
                                          <p className={ShopClass.price1}>₹{product?.productPriceUnit == "Quintal" ? <>{Number(product?.bagDiscountPrice).toFixed(2)}/{product.productMeasuringUnit}</> : <>{Number(product.price).toFixed(2)}/{product.productMeasuringUnit}</>}</p>
                                          <p className={ShopClass.pricetag}>₹{Number(product?.discountedProductPrice).toFixed(2)}/{product.productMeasuringUnit}</p>
                                        </div>
                                    }
                                  </div>
                                  </div>
                                  {
                                    product?.productPriceUnit == "Quintal" &&
                                    < div className={ShopClass["prices"]}><p className={ShopClass["price_heading"]}> Quintal Price :</p><div className={ShopClass.pricetag}>
                                      {
                                        product?.packingBag ?
                                          (product.packingBag?.bagId === 1 ?
                                            <div className={ShopClass.prices}>
                                              <p className={ShopClass.price1}>₹{Number(product?.price).toFixed(2)}</p>
                                              <p className={ShopClass.pricetag}>₹{Number(product?.discountedProductPrice).toFixed(2)}</p></div> :
                                            (product.packingBag?.bagId === 3 ?
                                              <div className={ShopClass.prices}>
                                                <p className={ShopClass.price1}>₹{Number(product?.price).toFixed(2)}</p>
                                                <p className={ShopClass.pricetag}>₹{Number(product?.discountedProductPrice).toFixed(2)}</p></div> :
                                              (product.packingBag?.bagId === 2 ?
                                                <div className={ShopClass.prices}>
                                                  <p className={ShopClass.price1}>₹{Number(product?.price).toFixed(2)}</p>
                                                  <p className={ShopClass.pricetag}>₹{Number(product?.discountedProductPrice).toFixed(2)}</p></div> :
                                                ""
                                              )
                                            )
                                          ) : <div className={ShopClass.prices}>
                                            <p className={ShopClass.price1}>₹{Number(product?.price).toFixed(2)}</p>
                                            <p className={ShopClass.pricetag}>₹{Number(product?.discountedProductPrice).toFixed(2)}</p>
                                          </div>
                                      }
                                    </div>
                                    </div>
                                  }
                                </div> */}
                                  <div className={ShopClass["agentpricesbar"]}>
                                    <button type="button"
                                      className={ShopClass.agentPriceEdit} onClick={() => addPrice(product?.productId)}>Change Price</button>
                                    {
                                      priceTagOpen === product?.productId ?
                                        <div className={ShopClass.shop_cart_buttons}>
                                          <button
                                            type="button"
                                            className={ShopClass["decrement"]}
                                            onClick={() => handleDecrement(product)}
                                          >
                                            -
                                          </button>
                                          <span className={ShopClass["cart_count"]}>
                                            ₹{cartDetails.find(item => item.productId === product.productId)?.agentPrice}
                                          </span>
                                          <button
                                            type="button"
                                            className={ShopClass["increment"]}
                                            onClick={() => handleIncrement(product)}
                                          >
                                            +
                                          </button>
                                        </div> : ""
                                    }
                                  </div>
                                  <div className={ShopClass["input-control"]}>
                                    <select className={ShopClass.dropdwons} defaultValue={product?.cashDiscount > 0 ? product?.cashDiscount : product?.discount}
                                      onChange={(e) => {
                                        const selectedDiscount = parseInt(e.target.value, 10);
                                        // localStorage.setItem('cashdiscount', selectedDiscount);

                                        setCartDetails((curr) => {
                                          const tmp = [...curr];
                                          tmp[index]["cashDiscount"] = selectedDiscount;

                                          const recalculatePrices = (item) => {
                                            let quintalPrice = item.agentPrice !== item.price ? item?.agentPrice : item.price;

                                            // if (item.packingBag) {
                                            //   if (item.packingBag.bagId === 1) {
                                            //     quintalPrice -= parseFloat(item.packingBag.price);
                                            //   } else if (item.packingBag.bagId === 2 || item.packingBag.bagId === 3) {
                                            //     quintalPrice += parseFloat(item.packingBag.price || 0);
                                            //   }
                                            // }

                                            // Calculate bag-related prices
                                            if (
                                              String(item.productPriceUnit) === "Quintal" &&
                                              item.productMeasuringUnit === "Bag"
                                            ) {
                                              const bagPrice = (item.productBagWeightInKgs * item.price) / 100;
                                              const bagDiscountPrice = (item.productBagWeightInKgs * quintalPrice) / 100;

                                              item.bagPrice = bagPrice;
                                              item.bagDiscountPrice =
                                                bagDiscountPrice -
                                                (bagDiscountPrice * (item.cashDiscount > 0 ? item.cashDiscount : item.discount)) / 100;
                                            }
                                            item.discountedProductPrice =
                                              quintalPrice -
                                              (quintalPrice * (item.cashDiscount > 0 ? item.cashDiscount : item.discount)) / 100;

                                            item.agentPrice = item.agentPrice;

                                            return item;
                                          };
                                          tmp[index] = recalculatePrices(tmp[index]);

                                          return tmp;
                                        });
                                      }}>
                                      <option value=""> Cash Discount</option>
                                      <option value={1}>1%</option>
                                      <option value={2}>2%</option>
                                      <option value={3}>3%</option>
                                      <option value={4}>4%</option>
                                      <option value={5}>5%</option>
                                      <option value={6}>6%</option>
                                      <option value={7}>7%</option>
                                      <option value={8}>8%</option>
                                      <option value={9}>9%</option>
                                      <option value={10}>10%</option>
                                    </select>
                                  </div>

                                </div>

                              </div>
                              {product?.productPriceUnit == "Quintal" && product?.packingBagPrice?.length > 0 ? (
                                <>
                                  <div className={ShopClass.Bags_Container}>
                                    <div className={ShopClass["input-control"]}>
                                      <select
                                        defaultValue={product?.packingBag?.bagName || "Miller Bags -Non Metallic"}
                                        onChange={(e) => {
                                          const selectedBagData = product?.packingBagPrice.filter(pricetag => pricetag.bagName == e.target.value)[0];
                                          setSelectedPrice(selectedBagData);
                                          // localStorage.setItem("BuyerBagPrice", JSON.stringify(selectedBagData));
                                          setCartDetails((curr) => {
                                            const tmp = [...curr];
                                            tmp[index]["packingBag"] = selectedBagData;

                                            const recalculatePrices = (item) => {
                                              let quintalPrice = item.agentPrice !== item.price ? item?.agentPrice : item.price;
                                              // if (item.packingBag) {
                                              //   if (item.packingBag.bagId === 1) {
                                              //     quintalPrice -= parseFloat(item.packingBag.price);
                                              //   } else if (item.packingBag.bagId === 2 || item.packingBag.bagId === 3) {
                                              //     quintalPrice += parseFloat(item.packingBag.price || 0);
                                              //   }
                                              // }

                                              if (
                                                String(item.productPriceUnit) === "Quintal" &&
                                                item.productMeasuringUnit === "Bag"
                                              ) {
                                                const bagPrice = (item.productBagWeightInKgs * item.price) / 100;
                                                const bagDiscountPrice = (item.productBagWeightInKgs * quintalPrice) / 100;

                                                item.bagPrice = bagPrice;
                                                item.bagDiscountPrice =
                                                  bagDiscountPrice -
                                                  (bagDiscountPrice * (item.cashDiscount > 0 ? item.cashDiscount : item.discount)) / 100;
                                              }
                                              // Calculate discountedProductPrice
                                              item.discountedProductPrice =
                                                quintalPrice -
                                                (quintalPrice * (item.cashDiscount > 0 ? item.cashDiscount : item.discount)) / 100;
                                              item.agentPrice = item.agentPrice;
                                              return item;
                                            };
                                            tmp[index] = recalculatePrices(tmp[index]);
                                            return tmp;
                                          });
                                        }}
                                      >
                                        <option value="">SELECT BAG TYPE</option>
                                        {
                                          product?.packingBagPrice?.map((item, i) => (
                                            <option key={item.bagId} value={item.bagName}>{item.bagName}</option>
                                          ))
                                        }
                                      </select>
                                      {errors.packingBag && <span>{errors.packingBag.message}</span>}
                                    </div>

                                    <div className={ShopClass["bagsPrice"]}>
                                      <div className={ShopClass["bagsPrices"]}>
                                        <p className={ShopClass["price_head"]}> {product.packingBag?.bagName} Price :</p>
                                        <p className={ShopClass.bagpricetag}>₹ {product.packingBag?.price}/{product?.productPriceUnit}</p>
                                      </div>
                                    </div>
                                  </div>
                                </>) : ""
                              }
                            </div>
                          );
                        })}

                        <div className={ShopClass.Cart_w_prices}>
                          <div className={ShopClass["cart_t_bill"]}>
                            <h4 className={ShopClass["t_bill_text"]}>
                              Total Quintal weight
                            </h4>
                            <h4 className={ShopClass["t_bill_price"]}>
                              {totalQuintal.toFixed(2)}
                            </h4>
                          </div>
                          <div className={ShopClass["cart_t_bill"]}>
                            <h4 className={ShopClass["t_bill_text"]}>
                              Total Tons Weight
                            </h4>
                            <h4 className={ShopClass["t_bill_price"]}>
                              {totalTones.toFixed(2)}
                            </h4>
                          </div>
                          <div className={ShopClass["cart_t_bill"]}>
                            <h4 className={ShopClass["t_bill_text"]}>
                              Order Price
                            </h4>
                            <h4 className={ShopClass["t_bill_price"]}>
                              ₹{TotalBill.toFixed(2) || 0.0}
                            </h4>
                          </div>
                          <div className={ShopClass["cart_t_bill"]}>
                            <h4 className={ShopClass["t_bill_text"]}>
                              Packing Bags Price
                            </h4>
                            <h4 className={ShopClass["t_bill_price"]}>
                              ₹{Number(bagspricess).toFixed(2) || 0.0}
                            </h4>
                          </div>
                          <div className={ShopClass["cart_t_bill"]}>
                            <h4 className={ShopClass["t_bill_text"]}>
                              Broker Order Price
                            </h4>
                            <h4 className={ShopClass["t_bill_price"]}>
                              ₹{agentorderPrice.toFixed(2) || 0.0}
                            </h4>
                          </div>
                        </div>
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="Miller Commision">Miller Commissions</label>
                          <input
                            type='number'
                            step="0.01"
                            placeholder='Select Commision per Quintal'
                            {...register("millerCommission", {
                              required: "Miller Commission is required",
                              valueAsNumber: true
                            })}
                            readOnly
                          />
                          <div className={ShopClass["dropdown"]}>
                            <select className={ShopClass.dropdwons}
                              {...register("millerCommissionPerQuintal", {
                                required: false,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setMillerCommission(e.target.value);
                                },
                              })}
                            >
                              <option value=""> Commission</option>
                              {
                                millerDropdown.map((option, i) => (
                                  <option key={i} value={option}>₹{option}{" Per Quintal"}</option>
                                ))
                              }
                            </select>
                          </div>
                          {errors.millerCommission && <span>{errors.millerCommission.message}</span>}
                        </div>
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="Distributor Commision">Distributor Commissions</label>
                          <input
                            type='number'
                            step="0.01"
                            placeholder='Select Commision per Quintal'
                            {...register("distributorCommission", {
                              required: selectedOrderThrough.orderThroughId !== 2
                                ? "Distributor Commission is required"
                                : false,
                              valueAsNumber: true
                            })}
                            disabled={selectedOrderThrough.orderThroughId === 2}
                            readOnly
                          />
                          <div className={ShopClass["dropdown"]}>
                            <select className={ShopClass.dropdwons}
                              {...register("distributionCommissionPerQuintal", {
                                required: false,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setDistributorCommission(e.target.value);
                                },
                              })}
                              disabled={selectedOrderThrough.orderThroughId === 2}
                            >
                              <option value=""> Commission</option>
                              {
                                distributorDropdown.map((option, i) => (
                                  <option key={i} value={option}>₹{option}{" Per Quintal"}</option>
                                ))
                              }
                            </select>
                          </div>
                          {errors.distributorCommission && <span>{errors.distributorCommission.message}</span>}
                        </div>
                        {
                          selectedDeliveryType.deliveryTypeId === 2 ?
                            <div className={ShopClass["form-control"]}>
                              <label htmlFor="Transport Commisions">Transport Commissions</label>
                              <input
                                type='number'
                                step="0.01"
                                placeholder='Enter Amount per(Quintal)'
                                {...register("transportCommission", {
                                  required: false,
                                  valueAsNumber: true

                                })}
                                readOnly
                              />
                              {errors.transportCommission && <span>{errors.transportCommission.message}</span>}
                            </div> : ""
                        }
                        {
                          selectedOrderThrough.orderThroughId === 2 ?
                            < div className={ShopClass["form-control"]}>
                              <label htmlFor="SubBroker Commision">SubBroker Commssion </label>
                              <input
                                placeholder='Enter Amount per(Quintal)'
                                {...register("subBrokerCommssion", {
                                  required: "SubBroker Commssion is required",
                                  valueAsNumber: true
                                })}
                                readOnly
                              />
                              <div className={ShopClass["dropdown"]}>
                                <select className={ShopClass.dropdwons}
                                  {...register("brokerCommissionPerQuintal", {
                                    required: false,
                                    valueAsNumber: true,
                                    onChange: (e) => {
                                      setSubBrokerCommision(e.target.value);
                                    },
                                  })}
                                >
                                  <option value=""> Commission</option>
                                  {
                                    subBrokerCommissions.map((option, i) => (
                                      <option key={i} value={option}>₹{option}{" Per Quintal"}</option>
                                    ))
                                  }
                                </select>
                              </div>

                              {errors.subBrokerCommssion && <span>{errors.subBrokerCommssion.message}</span>}
                            </div> : ""
                        }
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="cashDiscountDays">Cash DiscountDays</label>
                          <select
                            placeholder='Cash Discount Days (CD/CC)'
                            {...register("cashDiscountDays", {
                              required: "Cash Discount Days (CD/CC) is required",
                              valueAsNumber: true
                            })}
                          >
                            <option value=""> Cash Discount Days (CD/CC)</option>
                            {
                              cashDays.map((option, i) => (
                                <option key={i} value={option}>{option}{"Days"}</option>
                              ))
                            }
                          </select>
                          {errors.cashDiscountDays && <span>{errors.cashDiscountDays.message}</span>}
                        </div>
                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="hamaliCharges">Hamali Charges</label>
                          <input
                            type='number'
                            step="0.01"
                            placeholder='Hamali Charges (Quintal)'
                            {...register("hamaliCharges", {
                              required: false,
                              valueAsNumber: true
                            })}
                            readOnly
                          />
                          <div className={ShopClass["dropdown"]}>
                            <select className={ShopClass.dropdwons}
                              {...register("hamaliChargesPerQuintal", {
                                required: false,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setHamaliCharges(e.target.value);
                                },
                              })}
                            >
                              <option value=""> Charges</option>
                              {
                                hamalidropDown.map((option, i) => (
                                  <option key={i} value={option}>₹{option}{" Per Quintal"}</option>
                                ))
                              }
                            </select>
                          </div>
                          {errors.hamaliCharges && <span>{errors.hamaliCharges.message}</span>}
                        </div>

                        <div className={ShopClass["form-control"]}>
                          <label htmlFor="">Miller & Broker Notes </label>
                          <textarea
                            rows={4}
                            placeholder='Notes'
                            {...register("notes", {
                              required: false,
                            })}
                          />
                          {errors.notes && <span>{errors.notes.message}</span>}
                        </div>
                        {
                          (accountType == 6 && Permission == 0) ? "" :
                            <div className={ShopClass["place_order_btn"]}>

                              <button
                                type="submit"
                                className={ShopClass["cart_order_btn"]}
                                onClick={placeOrder}
                              >
                                Place Order
                              </button>
                            </div>
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>

        </div>
        {
          isOpen && (
            <AddTransportModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onClose={() => closeModal()}
              edit={edit}
              setEdit={setEdit}
              totalQuintalWeight={totalQuintal}
              orderId={0}
              OrderDetails={""}
            />
          )
        }
        {
          agentAddress &&
          <Agents
            isOpen={agentAddress}
            setIsOpen={setAgentAddress}
            onClose={() => setAgentAddress(false)}
          />
        }
      </div >

    </Layout >

  );
};

export default CartPage;
