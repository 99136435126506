import { v4 as uuidv4 } from "uuid";
import { BiBarChartAlt, BiBell } from "react-icons/bi";
import { GiShop } from "react-icons/gi";
import {
  HiAdjustmentsVertical,
  HiOutlineDocumentChartBar,
} from "react-icons/hi2";
import { LiaIdCard } from "react-icons/lia";
import { TbUsersGroup } from "react-icons/tb";
import { LuLayoutDashboard, LuPackageOpen } from "react-icons/lu";
import { RiAccountPinBoxLine, RiHome6Line } from "react-icons/ri";

export const menuItems = [
  {
    id: uuidv4(),
    multiple: false,
    name: "Dashboard",
    menuLink: "/Dashboard",
    active: "Dashboard",
    icon: LuLayoutDashboard,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "Home",
    menuLink: "/Home",
    active: "Home",
    icon: RiHome6Line,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Shop",
    menuLink: "/Shop",
    active: "Shop",
    icon: GiShop,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Users & Groups",
    menuLink: "/Users",
    active: "Users & Groups",
    icon: TbUsersGroup,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Customers",
    menuLink: "/Users",
    active: "Users & Groups",
    icon: TbUsersGroup,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Manage products",
    menuLink: "/Manage ",
    active: "Manage products",
    icon: HiAdjustmentsVertical,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "Sales Orders",
    menuLink: "/Orders",
    active: "Sales Orders",
    icon: BiBarChartAlt,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Purchase Orders",
    menuLink: "/Purchase",
    active: "Purchase Orders",
    icon: LuPackageOpen,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Sales Reports",
    menuLink: "/Sales",
    active: "Sales Reports",
    icon: HiOutlineDocumentChartBar,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "Notifications",
    menuLink: "/Notifications",
    active: "Notifications",
    icon: BiBell,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "All Users",
    menuLink: "/allusers",
    active: "All Users",
    icon: LiaIdCard,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Account",
    menuLink: "/user",
    active: "Account",
    icon: RiAccountPinBoxLine,
  },
];
