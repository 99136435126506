import React, { useEffect, useState } from "react";
import { Services } from "../../../Services";
import { SuccessSwal } from "../../../components/utils/ToastMessages";
import styles from "./faq.module.css";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      const res = await Services.Faqs("GET", null, token);
      if (res.Status === 1) {
        setFaqs(res.FAQs);
        console.log(res.FAQs);
      } else if (res.Status === 0) {
        SuccessSwal("Success", res.Message);
        if (res.Message === "Token expired") {
          Navigate("/");
          localStorage.clear();
        }
      }
    } catch (err) {
      alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles["faqs_container"]}>
      {faqs.map((faq, index) => {
        return (
          <div className={styles["faq_box"]} key={index}>
            <button
              className={
                `${styles.accordion} ${activeIndex === index ? styles.active : ""}`
              }
              onClick={() => handleClick(index)}
            >
              <p className={styles["faq_content"]}>{faq.question}</p>
              <p className={styles["faq_content"]}>
                <FaPlus />
              </p>
            </button>
            <div
              className={`${styles.panel} ${activeIndex === index ? styles.show : ""}`}
            >
              <p className={styles["faqs_answer"]}>{faq.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Faqs;
