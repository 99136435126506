import create from "zustand";

const initialState = {
  showNavbar: true,
  showSeo: false,
  isLoginMobile: false,
  user: {},
  userActive: 1,
  dropdown: "",
  notificationCount: 0,
  shouldAnimate: false,
  prevItemName: null,
  NavItems: [],
  ActiveTab: "",
};
const useStore = create((set) => ({
  ...initialState,
  setshowNavbar: (auth) =>
    set((state) => ({ ...state, showNavbar: !state.showNavbar })),
  setshowNSeo: (auth) =>
    set((state) => ({ ...state, showSeo: !state.showSeo })),
  // setDropdown: (data) => set((state) => ({ ...state, dropdown: data })),
  setToken: (data) => set((state) => ({ ...state, Token: data })),
  setShouldAnimate: (data) =>
    set((state) => ({ ...state, shouldAnimate: data })),
  setPrevItemName: (data) =>
    set((state) => ({ ...state, setPrevItemName: data })),
  setNotificationCount: (data) =>
    set((state) => ({ ...state, notificationCount: data })),
  setUser: (data) => set((state) => ({ ...state, user: data })),
  setDropdown: (data) => set((state) => ({ ...state, dropdown: data })),
  setNavItems: (data) => set((state) => ({ ...state, NavItems: data })),
  setUserActive: (data) => set((state) => ({ ...state, userActive: data })),
  setIsLoginMobile: (data) =>
    set((state) => ({ ...state, isLoginMobile: !state.isLoginMobile })),
  setActiveTab: (Active) => set((state) => ({ ...state, ActiveTab: Active })),
}));
export default useStore;
