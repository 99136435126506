import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { SuccessSwal, ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { GoArrowLeft } from "react-icons/go";
import HomeClasses from "../../pages/Home/home.module.css";

import Layout from "../../components/Layout/layout";
import Image from '../../assets/noplot.png'
import { override } from "./helper";
import { RotatingLines } from "react-loader-spinner";
import Slider from "react-slick";

const ProductView = () => {
  const { productID, groupID } = useParams();
  const [productDetails, setProductDetails] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();
  console.log(productID);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${HomeClasses.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  // Custom arrow component for previous button
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${HomeClasses.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };



  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const deleteProduct = addPermissions.filter(
    (item) => item.permissionId === 3
  );
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 2
  );
  const Permission = deleteProduct.length > 0 ? deleteProduct[0].status : 1;
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  useEffect(() => {
    getProductView(productID);
  }, [productID]);

  const getProductView = (productID) => {
    Services.getProduct("GET", null, token, productID)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setProductDetails(res.product);
          console.log(res);
          //   ToastSuccess(res.Message);
        }
      })
      .catch((err) => {
        // alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const editProduct = (productID) => {
    Navigate(`/EditProduct/${productID}`);
  };

  const delete_product = (productID) => {
    const body = { groupId: groupID };
    Services.deleteProduct("DELETE", JSON.stringify(body), token, productID)
      .then((res) => {
        if (res.Status === 1) {
          // console.lod(res.Status);
          SuccessSwal("Success", res.Message);
          setShowDeleteModal(false);
          Navigate(-1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };
  return (
    <Layout Active={"Manage products"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["product_main_container"]}>
            <div className={HomeClasses["product_main_header"]}>
              <button
                className={HomeClasses["prod_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["product_header_h2"]}>Product Details</h1>
            </div>
            <div className={HomeClasses.product_main_contain}>
              <div className={HomeClasses["product_container"]}>
                <div className={HomeClasses["product_container_2"]}>
                  <div className={HomeClasses["product_container_header"]}>
                    <div className={HomeClasses["prod_image_container"]}>
                      <Slider {...settings}>
                        {productDetails.productImageList && productDetails.productImageList.length > 0 ? (
                          productDetails.productImageList.map((item, index) => (
                            <div key={index} className={HomeClasses["prod_image"]}>
                              <img
                                key={index}
                                className={HomeClasses["prod_img"]}
                                src={item}
                                alt="image"
                                onError={(e) => {
                                  e.currentTarget.onerror = null;
                                  e.currentTarget.src = Image;
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <div className={HomeClasses["prod_image"]}>
                            <img
                              className={HomeClasses["prod_img"]}
                              src={Image}
                              alt="default image"
                            />
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                  <div className={HomeClasses["product_info"]}>
                    <p id={HomeClasses["product_text_1"]}>
                      {productDetails.productName}
                    </p>
                    <p id={HomeClasses["product_text_1"]}>
                      {productDetails.productCategory}{" - "}{productDetails.productSubCategory}
                    </p>
                    {productDetails.productBagWeightInKgs !== 0 &&
                      (
                        <p id={HomeClasses["product_text_2"]}>
                          {productDetails.productBagWeightInKgs}
                          KG'S/
                          {productDetails.productMeasuringUnit}
                        </p>
                      )}
                    <p className={HomeClasses["product-qty"]}>
                      STOCK: {productDetails.stockQty} {" "}{productDetails.productMeasuringUnit}
                    </p>

                    <p id={HomeClasses["product_text_3"]}>
                      {productDetails.status === 1 ? "In Stock" : "Out of Stock"}
                    </p>

                  </div>
                </div>
                <div className={HomeClasses["prod_btn_conatiner"]}>
                  {(productDetails?.userId == localStorage.getItem("userId") ||
                    accountType == 6) && (
                      <>
                        {accountType == 6 && Permission == 0 ? (
                          " "
                        ) : (
                          <button
                            type="button"
                            className={HomeClasses["prod_action_delete"]}
                            onClick={() => handleDeleteClick()}
                          >
                            Delete
                          </button>
                        )}
                        {accountType == 6 && PermissionStatus == 0 ? (
                          " "
                        ) : (
                          <button
                            type="button"
                            className={HomeClasses["prod_action_edit"]}
                            onClick={() => editProduct(productDetails.productId)}
                          >
                            Edit
                          </button>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <div className={HomeClasses["delete-modal"]}>
          <div className={HomeClasses["delete-modal-content"]}>
            <img
              className={HomeClasses["delete-modal-img"]}
              src={productDetails.productImageList[0]}
              alt="image"
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = Image;
              }}
            />
            <p id={HomeClasses["delete_text1"]}>{productDetails.productName}</p>
            <p id={HomeClasses["delete_text2"]}>{productDetails.productCategory}{" - "}{productDetails.productSubCategory}</p>
            <div className={HomeClasses["delete_btns"]}>
              <button
                type="button"
                className={HomeClasses["delete_cbtn"]}
                onClick={() => handleCancelClick()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={HomeClasses["delete_dbtn"]}
                onClick={() => delete_product(productDetails.productId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductView;
