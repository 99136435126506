import React, { useEffect, useState } from "react";
import HomeClasses from "../../pages/Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { SuccessSwal, warningSwal } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import { BsFillHandbagFill } from "react-icons/bs";
import { MdEditDocument } from "react-icons/md";
import { FaBoxes, FaInfoCircle } from "react-icons/fa";
import { BiSolidBox } from "react-icons/bi";
import { LuUpload } from "react-icons/lu";
import { BsBox2Fill } from "react-icons/bs";
import config from "./config";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import { override } from "./helper";
import { RotatingLines } from "react-loader-spinner";
import Layout from "../../components/Layout/layout";
import { RxCross2 } from 'react-icons/rx'
import { IoIosPricetags } from "react-icons/io";
import { GiWeight } from "react-icons/gi";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { ScaleLoader } from "react-spinners";

window.Buffer = window.Buffer || require("buffer").Buffer;

const EditProduct = () => {
  const [imageUrl, setImageUrl] = useState([]);
  const [images, setImages] = useState([]);
  const [productimage, setProductimage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPriceUnit, setSelectedPriceUnit] = useState("");
  const Navigate = useNavigate();
  const [productCategories, setProductCategories] = useState([]);
  const [priceUnits, setPriceUnits] = useState([]);
  const { productID } = useParams();
  const [packingBags, setPackingBags] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [selectedCategoryId, setSeleCtedcategoryId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    register,
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    getPriceUnits();
    getProductCategories();
    getPackingBags();
  }, []);

  const selectedCategoryName = watch("productCategory");
  useEffect(() => {
    if (selectedCategoryName) {
      const category = productCategories.find(cat => cat.categoryName === selectedCategoryName);
      const categoryId = category?.productCategoryId;
      setSeleCtedcategoryId(categoryId);
      fetchSubCategories(categoryId);
    }
  }, [selectedCategoryName]);

  const getPriceUnits = async () => {
    try {
      const res = await Services.priceUnits("GET", null, token);
      if (res.Status === 1) {
        setPriceUnits(res.productPriceUnits);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getProductCategories = async () => {
    try {
      const res = await Services.productCategories("GET", null, token);
      if (res.Status === 1) {
        setProductCategories(res.productCategories);
      }
    } catch (err) {
      console.error(err);
    }
  }


  const fetchSubCategories = async (categoryId) => {
    if (categoryId) {
      try {
        const res = await Services.ProductSubCategories("GET", null, token, categoryId);
        if (res.Status === 1) {
          setProductSubCategories(res?.SubCategories || []);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }


  const getPackingBags = async () => {
    try {
      const res = await Services.PackingBags("GET", null, token);
      if (res.Status === 1) {
        const packing = res.packingBags.map(bag => ({
          ...bag,
          price: 0,
        }))
        setPackingBags(packing);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const formData = watch();
  //for permissions
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionName === "editProduct"
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;
  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    if (productID) {
      console.log(productID);
      Services.getProduct("GET", null, token, productID)
        .then((res) => {
          console.log(res.product);
          setLoading(false);
          if (res.Status === 1) {
            let product = res.product;
            setImages(product.productImageList.map(item => ({
              url: item,
              name: item.split('/').pop()
            })));
            let resetBody = { ...product };
            setSeleCtedcategoryId(product.productCategoryId);
            delete product.productImageList;
            reset(product);
            if (product.productCategoryId) {
              fetchSubCategories(product?.productCategoryId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [productID, Navigate, reset]);
  const onSubmit = async (data) => {
    let body = {
      ...data,
      productImageList: images.map(img => img.name),
      productCategoryId: selectedCategoryId,
      productMeasuringUnit: String(data.productPriceUnit).toLowerCase() == 'quintal' ? 'Bag' : data.productPriceUnit
    };

    if (images.length === 0) {
      warningSwal("Warning", "Image is required");
      return;
    }
    if (data.stockQty <= 0) {
      setError("stockQty", {
        type: "custom",
        message: "Stock quantity cannot be zero or negative",
      });
      return;
    }

    console.log(body);
    try {
      const res = await Services.Editproduct(
        "PATCH",
        JSON.stringify(body),
        token,
        productID
      );

      if (res.Status === 1) {
        SuccessSwal("Success", res.Message);
        console.log(res);
        Navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fileUploadHandler = (files) => {
    let uploadedImages = [];
    // return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = file.type;

      if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
        const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
        console.log(newFileName);
        // return;
        const newFile = new File([file], newFileName);
        console.log(newFile);
        S3FileUpload.uploadFile(newFile, config)
          .then((data) => {
            setImages(prevImages => [...prevImages, { url: data.location, name: newFileName }]);
          })
          .catch((err) => console.error(err));
      }
    };
  }

  const handleDeleteImage = (name) => {
    setImages(prevImages => prevImages.filter(img => img.name !== name));
  };

  const fileUploader = (
    <div className={HomeClasses["add_image_uploader"]}>
      <LuUpload className={HomeClasses["upload_icon"]} />
      <h3 id={HomeClasses["add_uploader_h3"]}>Update Image</h3>
    </div>
  );
  return (
    <Layout Active={"Manageproducts"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color="#4285f4"
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Add_main_container"]}>
            <div className={HomeClasses["Add_header"]}>
              <button
                className={HomeClasses["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["add_header_h2"]}>Edit Product</h1>
            </div>
            <div className={HomeClasses["Addproduct_container"]}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={HomeClasses["add_register"]}
              >
                <div>
                  <Controller
                    name="productCategory"
                    control={control}
                    rules={{
                      required: "ProductCategory is required",
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <select defaultValue="" {...field}>
                          <option value="" disabled>
                            Product Category
                          </option>
                          {
                            productCategories.map((item, index) => (
                              <option
                                key={item.productCategoryId}
                                value={item.categoryName}
                              >
                                {item.categoryName}
                              </option>
                            ))
                          }
                        </select>
                        <BsBox2Fill />
                      </div>
                    )}
                  />
                  {errors.productCategory && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productCategory.message}
                    </p>
                  )}
                </div>

                <div>
                  <Controller
                    name="productSubCategory"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <select defaultValue="" {...field}>
                          <option value="" >
                            Product SubCategory
                          </option>
                          {
                            productSubCategories.map((item, index) => (
                              <option
                                key={item.productSubCategoryId}
                                value={item.subCategoryName}
                              >
                                {item.subCategoryName}
                              </option>
                            ))
                          }

                        </select>
                        <BsBox2Fill />
                      </div>
                    )}
                  />
                  {errors.productSubCategory && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productSubCategory.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="productName"
                    control={control}
                    rules={{
                      required: "Product Name is required",
                      // pattern: {
                      //   value: /^[a-zA-Z0-9\s]+$/,
                      //   message: "Invalid Product Name",
                      // },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input {...field} placeholder="Product Name" />
                        <BsFillHandbagFill />
                      </div>
                    )}
                  />
                  {errors.productName && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productName.message}
                    </p>
                  )}
                </div>

                <div>
                  <Controller
                    name="productPriceUnit"
                    control={control}
                    rules={{
                      required: "productPriceUnit is required",
                      onChange: (e) => {
                        setValue("productMeasuringUnit", String(e.target.value).toLowerCase() == 'quintal' ? 'Bag' : e.target.value);
                        setSelectedPriceUnit(e.target.value);
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <select defaultValue="" {...field}>
                          <option value="" disabled>
                            Product PriceUnit
                          </option>
                          {
                            priceUnits.map((unit, index) => (
                              <option
                                key={unit.productPriceId}
                                value={unit.productPriceUnit}
                              >
                                {unit.productPriceUnit}
                              </option>
                            ))
                          }
                        </select>
                        <BsBox2Fill />
                      </div>
                    )}
                  />
                  {errors.productPriceUnit && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productPriceUnit.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="stockQty"
                    control={control}
                    rules={{
                      required: "Stock Qty is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Stock Qty should only contain digits",
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input
                          type="text"
                          {...field}
                          placeholder="Stock Quantity"
                          onChange={(e) => field.onChange(Number(e.target.value))}
                        />
                        <FaBoxes />
                        <div className={HomeClasses["a_mesuring_unit"]}>
                          <Controller
                            name="productMeasuringUnit"
                            control={control}
                            disabled
                            //   defaultValue={formData.productPriceUnit}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <div className={HomeClasses["add_icon"]}>
                                <select defaultValue="" {...field}>
                                  <option value="" disabled></option>
                                  {
                                    priceUnits.map((unit, index) => (
                                      <option
                                        key={unit.productPriceId}
                                        value={unit.productPriceUnit}
                                      >
                                        {unit.productPriceUnit}
                                      </option>
                                    ))
                                  }
                                </select>
                                {/* <BsBox2Fill /> */}
                              </div>
                            )}
                          />
                          {errors.productMeasuringUnit && (
                            <p className={HomeClasses["errors"]}>
                              {errors.productMeasuringUnit.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  />
                  {errors.stockQty && (
                    <p className={HomeClasses["errors"]}>
                      {errors.stockQty.message}
                    </p>
                  )}
                </div>

                <div>
                  <Controller
                    name="productBagWeightInKgs"
                    control={control}
                    rules={{
                      required: "Product Bag WeightInKgs required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "productBagWeightInKgs should be greater than 0",
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input
                          type="text"
                          {...field}
                          placeholder="productBagWeightInKgs"
                          onChange={(e) => field.onChange(Number(e.target.value))}
                          min={1} />
                        <GiWeight />
                      </div>
                    )}
                  />
                  {errors.productBagWeightInKgs && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productBagWeightInKgs.message}
                    </p>
                  )}
                </div>

                {
                  (formData.productPriceUnit === "Quintal") && (<>
                    {packingBags.map((item, index) => (
                      <div key={item.bagId}>
                        <div className={HomeClasses["info-tag"]}>
                          <span className={HomeClasses["info-icon"]}><FaInfoCircle size={15} /></span>
                          <p>{item.bagId === 1 && "Buyer Bags Reduces your Product Cost."}</p>
                          <p>{item.bagId === 2 && "Miller Bags - Non Metallic Increases your Product Cost."}</p>
                          <p> {item.bagId === 3 && "Miller Bags - Metallic Increases your Product Cost."}</p>
                        </div>
                        <div className={HomeClasses["add_icon"]}>
                          <input
                            type="number"
                            step="0.01"
                            placeholder={`${item.bagName}`}
                            {...register(`packingBagPrice.${index}.price`, {
                              required: item.bagId !== 2 ? `Price is required` : false,
                              validate: item.bagId !== 2
                                ? (value) => {
                                  if (value === null || value <= 0) {
                                    return "Price should be greater than 0";
                                  }
                                  return true;
                                }
                                : "",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message: "Price should only contain digits",
                              },
                              valueAsNumber: true

                            })}
                          />
                          <IoIosPricetags />
                        </div>
                        <input
                          type="hidden"
                          defaultValue={item.bagId}
                          {...register(`packingBagPrice.${index}.bagId`, { valueAsNumber: true })}
                        />
                        <input
                          type="hidden"
                          defaultValue={item.bagName}
                          {...register(`packingBagPrice.${index}.bagName`)}
                        />
                        {item.bagId !== 2 && errors.packingBagPrice?.[index]?.price && (
                          <p className={HomeClasses["errors"]}>
                            {errors.packingBagPrice[index].price.message}
                          </p>
                        )}
                      </div>
                    ))}
                  </>)
                }
                <div>
                  <Controller
                    name="discount"
                    control={control}
                    rules={{
                      required: "Discount is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: " Discount should only contain digits",
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input
                          {...field}
                          placeholder="Discount  In  Percentage"
                          onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                          value={field.value || ''}
                        />
                        <HiMiniReceiptPercent />
                      </div>
                    )}
                  />
                  {errors.discount && (
                    <p className={HomeClasses["errors"]}>
                      {errors.discount.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="HSNCode"
                    control={control}
                    rules={{
                      required: "HSNCode is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid HSNCode ",
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input
                          {...field}
                          placeholder="HSNCode "
                        // maxLength={8}
                        // minLength={8} 
                        />
                        <MdEditDocument />
                      </div>
                    )}
                  />
                  {errors.HSNCode && (
                    <p className={HomeClasses["errors"]}>
                      {errors.HSNCode.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="isActive"
                    control={control}
                    rules={{ required: "Status is required" }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <select defaultValue="" {...field}
                          onChange={(e) => field.onChange(Number(e.target.value))}>
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option value={1}>Active</option>
                          <option value={2}>In Active</option>
                        </select>
                        <BiSolidBox />
                      </div>
                    )}
                  />
                  {errors.isActive && (
                    <p className={HomeClasses["errors"]}>
                      {errors.isActive.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="productImageList"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <div className={HomeClasses["image_preview"]}>
                        <FileUploader
                          handleChange={fileUploadHandler}
                          children={fileUploader}
                          multiple={true}

                          className={HomeClasses["add_image_uploader"]}
                        />
                        <div className={HomeClasses.image_array}>
                          {images.length > 0 ? (
                            images.map((item, index) => (
                              <div key={index} className={HomeClasses.image_container}>
                                <img
                                  key={index}
                                  src={item.url}
                                  alt="Product Preview"
                                  className={HomeClasses.image_uploader}
                                />
                                <button type="button" onClick={() => handleDeleteImage(item.name)} className={HomeClasses['imageremover']}><RxCross2 size={18} /></button>
                              </div>
                            ))
                          ) : ""}
                        </div>
                      </div>
                    )}
                  />
                  {errors.productImageList && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productImageList.message}
                    </p>
                  )}
                </div>
                {accountType == 6 && PermissionStatus == 0 ? (
                  " "
                ) : (
                  <button type="submit" className={HomeClasses["add_submit"]}>
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
        )
        }
      </div >
    </Layout >
  );
};

export default EditProduct;
