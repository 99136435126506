import React, { useState, useEffect } from "react";
import navbarClasses from "./navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../assets/tannya_new.png";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "../../store";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Services } from "../../Services";
import { SuccessSwal } from "../utils/ToastMessages";
import swal from "sweetalert";
import { HiOutlineLogout } from 'react-icons/hi'
import { Permissions } from "../utils/Pernissions";


function Sidenav({ Active, show, setShow }) {
    const { notificationCount, setNotificationCount, setNavItems, NavItems, user, setUser, userActive, setUserActive } = useStore();
    const dropDown = useStore((item) => item.dropdown);
    const setDropdown = useStore((item) => item.setDropdown);
    const shouldAnimate = useStore((item) => item.shouldAnimate);
    const setShouldAnimate = useStore((item) => item.setShouldAnimate);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [adminAccounType, setAdminAccounType] = useState();
    let accountType = localStorage.getItem("AccountType");
    const Navigate = useNavigate();
    const clickhandler = () => {
        setDropdown("");

        setShow((prev) => !prev);
    };
    // console.log(NavItems)

    useEffect(() => {
        Services.AccountTyes("GET", null, token).then((res) => {
            if (res.Status === 1) {
                setAdminAccounType(res?.adminAccounType);

                const permissionAccount = localStorage.getItem("permissionAccount");
                if (accountType == 6) {
                    if (res?.adminAccounType == 0) {
                        accountType = 6;
                    } else if (res?.adminAccounType != 0) {
                        if (permissionAccount == 1) {
                            accountType = 7;
                        } else if (permissionAccount == 2) {
                            accountType = 8;
                        } else if (permissionAccount == 5) {
                            accountType = 9;
                        }
                    }
                }
                if (accountType == 4 || accountType == 6) {
                    let adminId = localStorage.getItem("adminId");
                    if (adminId == 0) {
                        setNavItems([]);
                        return;
                    }
                }
                // navitens!=
                setNavItems(Permissions(accountType));
                setUser(JSON.parse(localStorage.getItem("userobject")));
            }
        }).catch((err) => {
            console.error(err);
        })
    }, []);

    useEffect(() => {
        Services.UnreadNotifications("GET", null, token)
            .then((res) => {
                if (res.Status === 1) {
                    setNotificationCount(res.unreadNotifications);
                }
            })
            .catch((err) => {
                alert("Somthing went wrong please try again");
                console.error(err);
            });
    }, []);

    useEffect(() => {
        Services.getUser("GET", null, token).then((response) => {
            if (response.Status === 1) {
                setUserActive(response.user.isActive);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const UserActive = async (data) => {
        console.log(data)
        Services.UserActiveInActive(
            "PATCH",
            null,
            token,
            data
        ).then((res) => {
            if (res.Status === 1) {
                setUserActive(data)
                SuccessSwal("Success", res.Message);
            }
        }).catch((err) => {
            console.error(err);
        })
    };
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });

    const closeHnadler = () => {
        if (window.innerWidth <= 900 && show) {
            setShow(false);
            setDropdown("");
        }
    };
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        closeHnadler();
    };

    useEffect(() => {
        window.addEventListener("resize", setDimension);

        return () => {
            window.removeEventListener("resize", setDimension);
        };
        // eslint-disable-next-line
    }, [screenSize]);

    const logoutHandler = () => {
        swal("Are You sure you want to Log out?", {
            icon: "warning",
            className: navbarClasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: navbarClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <div>
            <div
                className={`${show ? navbarClasses["backdrop"] : ""}`}
                onClick={() => {
                    setShow(false);
                    setDropdown("");
                }}></div>
            <div
                className={`${navbarClasses["Container"]} ${!show ? navbarClasses["hide"] : ""
                    }`}>
                <div className={navbarClasses["hamburger"]}>
                    <img
                        className={!show && navbarClasses["img-size"]}
                        src={Logo}
                        alt="logo"
                        onClick={() => {
                            window.open("/", "_blank", "noopener,noreferrer");
                        }}
                    />
                    <button
                        className={navbarClasses["hamburger-icon"]}
                        onClick={clickhandler}>
                        <GiHamburgerMenu size={22} color="white" />
                    </button>
                </div>
                {show && <div className={navbarClasses["userdata"]}
                >
                    <h3 className={navbarClasses.userBname}>{(accountType != 6 && accountType != 4) ? user.businessName : localStorage.getItem("officeBName")}</h3>
                    <h5 className={navbarClasses.userName}>{user.name}</h5>
                </div>}

                <div className={navbarClasses.user_active_btn}>{
                    (accountType == 1 || accountType == 2) && (<div className={navbarClasses.user_active_btn}>
                        <label className={navbarClasses["switch"]}>
                            <input type="checkbox"
                                id={navbarClasses["togBtn"]}
                                checked={userActive === 1}
                                onClick={(e) => { console.log(e.target.checked); UserActive(e.target.checked ? 1 : 0) }} />
                            <div className={`${navbarClasses["slider"]} ${navbarClasses["round"]}`}></div>
                        </label>
                    </div>)
                }
                </div>
                {NavItems && NavItems.length > 0 &&
                    <div className={navbarClasses["navlinks"]}>
                        {NavItems.map((menuData) => {
                            if (!menuData.multiple) {
                                if (menuData.name) {
                                    return (
                                        <>
                                            <NavLink
                                                key={menuData.id}
                                                id={menuData.id}
                                                to={menuData.menuLink}
                                                data-tooltip-id={menuData.id}
                                                data-tooltip-content={menuData.name}
                                                data-tooltip-position-strategy={
                                                    "fixed"
                                                }
                                                className={
                                                    Active === menuData.active
                                                        ? navbarClasses["navActive"]
                                                        : navbarClasses[
                                                        "navInactive"
                                                        ]
                                                }>
                                                <div className={navbarClasses["icon_wrapper"]}>
                                                    <menuData.icon
                                                        className={
                                                            navbarClasses["nav-icon"]
                                                        }
                                                    />
                                                    {menuData.name == "Notifications" && (
                                                        <span className={navbarClasses["icon_count"]}>
                                                            {notificationCount}
                                                        </span>
                                                    )}
                                                </div>
                                                {show && (
                                                    <span>{menuData.name}</span>
                                                )}
                                            </NavLink>{" "}
                                            <ReactTooltip
                                                id={menuData.id}
                                                place="left"
                                                variant="info"
                                                hidden={show}
                                            />
                                        </>
                                    );
                                }
                                return null;
                            } else {
                                console.log('122')
                                let allData = [];
                                menuData.items.forEach((item) => {
                                    if (item.name) {
                                        allData.push(item);
                                    }
                                });
                                if (allData.length > 0) {
                                    return (
                                        <div className={navbarClasses["seo"]}>
                                            <div
                                                data-tooltip-id={menuData.id}
                                                data-tooltip-content={menuData.name}
                                                data-tooltip-position-strategy={
                                                    "fixed"
                                                }
                                                className={
                                                    navbarClasses["seo-item"]
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                        dropDown === menuData.name
                                                    ) {
                                                        setDropdown("");
                                                    } else {
                                                        if (!show) {
                                                            setShow(true);
                                                        }
                                                        setDropdown(menuData.name);
                                                    }
                                                }}>
                                                <menuData.icon
                                                    data-tooltip-id={menuData.id}
                                                    data-tooltip-content={
                                                        menuData.name
                                                    }
                                                    data-tooltip-position-strategy={
                                                        "fixed"
                                                    }
                                                    className={
                                                        navbarClasses["nav-icon"]
                                                    }
                                                />
                                                <ReactTooltip
                                                    id={menuData.id}
                                                    place="left"
                                                    variant="info"
                                                    hidden={show}
                                                />
                                                {show && (
                                                    <p>
                                                        {menuData.name}{" "}
                                                        {dropDown ===
                                                            menuData.name ? (
                                                            <RiArrowDropUpFill
                                                                size={30}
                                                            />
                                                        ) : (
                                                            <RiArrowDropDownFill
                                                                size={30}
                                                            />
                                                        )}{" "}
                                                    </p>
                                                )}
                                            </div>
                                            <AnimatePresence
                                                initial={
                                                    !(dropDown === menuData.name)
                                                }>
                                                {dropDown === menuData.name && (
                                                    <motion.div
                                                        key={dropDown}
                                                        initial={{ height: 0 }}
                                                        animate={{ height: "auto" }}
                                                        exit={{ height: 0 }}
                                                        transition={{
                                                            duration: 0.4,
                                                        }}
                                                        onAnimationComplete={() => {
                                                            setShouldAnimate(false);
                                                        }}
                                                        className={`${navbarClasses[
                                                            "seo-item-nav"
                                                        ]
                                                            } ${shouldAnimate
                                                                ? "animate-class"
                                                                : ""
                                                            }`}>
                                                        {allData.map(
                                                            (links, id) => {
                                                                if (links.name) {
                                                                    return (
                                                                        <NavLink
                                                                            key={id}
                                                                            id={1}
                                                                            to={
                                                                                links.menuLink
                                                                            }
                                                                            className={`${Active ===
                                                                                links.active
                                                                                ? navbarClasses[
                                                                                "navActive"
                                                                                ]
                                                                                : navbarClasses[
                                                                                "navInactive"
                                                                                ]
                                                                                } ${!show
                                                                                    ? navbarClasses[
                                                                                    "sub-link-hide"
                                                                                    ]
                                                                                    : ""
                                                                                }`}>
                                                                            {show && (
                                                                                <span
                                                                                    title={
                                                                                        links.name
                                                                                    }>
                                                                                    {
                                                                                        links.name
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </NavLink>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    );
                                }
                                return null;
                            }
                        })}
                    </div>
                }
                <button
                    className={navbarClasses["logout"]}
                    onClick={logoutHandler}>
                    <HiOutlineLogout size={23} />
                    {show && <p className={navbarClasses.logbutton}>Logout</p>}
                </button>
            </div>
        </div>
    );
}

export default Sidenav;
