import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import ShopClass from "../../Shop/shop.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useForm } from "react-hook-form";
import { ShopServices } from "../../Shop/ShopServicess";
import { SuccessSwal, ToastSuccess } from "../../../components/utils/ToastMessages";

const ManageAddress = () => {
    const [address, setAddress] = useState([]);
    const navigate = useNavigate();
    const [addressId, setAddressId] = useState();
    const [selectedAddress, setSelectedAddress] = useState();
    const [edit, setEdit] = useState(false);
    const [step, setStep] = useState(1);
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        getCategories();
    }, []);
    const getCategories = async () => {
        try {
            const res = await ShopServices.getAddress("GET", null, token);
            if (res.Status === 1) {
                setAddress(res.addresses);
                if (res.addresses.length > 0) {
                    setAddressId(res.addresses[0].addressId);
                }
                // reset(res.addresses);
            }
        } catch (err) {
            // alert("Something went wrong please try again");
            console.error(err);
        }
    };

    // const

    const addAddress = async (data) => {
        if (edit) {
            try {
                const res = await ShopServices.EditAddress(
                    "PATCH",
                    JSON.stringify(data),
                    token,
                    selectedAddress.addressId
                );
                if (res.Status === 1) {
                    SuccessSwal("Success", res.Message);
                    setEdit(false);
                    setSelectedAddress();
                    reset({
                        deliveryAddress: "",
                        userName: "",
                        mobileNumber: "",
                        city: "",
                        state: "",
                        area: "",
                        pincode: "",
                        latitude: "",
                        longitude: "",
                    });
                    setStep(1);
                    getCategories();
                }
            } catch (err) {
                // alert("Something went wrong please try again");
                console.error(err);
            }
        } else {
            try {
                const res = await ShopServices.AddAddress(
                    "POST",
                    JSON.stringify(data),
                    token
                );
                if (res.Status === 1) {
                    reset({
                        deliveryAddress: "",
                        userName: "",
                        mobileNumber: "",
                        city: "",
                        state: "",
                        area: "",
                        pincode: "",
                        latitude: "",
                        longitude: "",
                    });
                    SuccessSwal("Success", res.Message);
                    setStep(1);
                }
            } catch (err) {
                // alert("Something went wrong please try again");
                console.error(err);
            }
        }
    };

    const deleteAddress = async (addressId) => {
        try {
            const res = await ShopServices.DeleteAddress(
                "DELETE",
                null,
                token,
                addressId
            );
            if (res.Status === 1) {
                SuccessSwal("Success", res.Message);
                getCategories();
            }
        } catch (err) {
            // alert("Something went wrong please try again");
            console.error(err);
        }
    };
    const editHandler = (id) => {
        setEdit(true);
        setSelectedAddress(address.find((item) => item.addressId == id));
        reset(address.find((item) => item.addressId == id));
        setStep(2);
    };
    return (
        <>
            <div className={ShopClass["Add_main_container"]}>
                <div className={ShopClass["Add_header"]}>
                    <h1 id={ShopClass["add_header_h2"]}>Manage Address</h1>
                </div>
                <div className={ShopClass["address_container"]}>
                    <div className={ShopClass["address_container_2"]}>
                        {step === 1 ? (
                            <>
                                <div className={ShopClass["address_container_3"]}>
                                    {address.map((address, index) => {
                                        return (
                                            <div className={ShopClass["address_box"]} key={index}>
                                                <div className={ShopClass["check_address"]}>
                                                    <input
                                                        type="radio"
                                                        className={ShopClass["address_input"]}
                                                        onClick={() => {
                                                            localStorage.setItem(
                                                                "address",
                                                                address.deliveryAddress
                                                            );
                                                            // navigate(-1);
                                                        }}
                                                    />
                                                </div>
                                                <div className={ShopClass["adress_boxeses"]}>
                                                    <p className={ShopClass["a_address"]}>
                                                        {address.deliveryAddress}
                                                    </p>
                                                    <p className={ShopClass["a_address_2"]}>
                                                        Phone Number: {address.mobileNumber}
                                                    </p>
                                                    <div className={ShopClass["address_btns"]}>
                                                        <button
                                                            type="button"
                                                            className={ShopClass["a_edit"]}
                                                            onClick={(e) => editHandler(address.addressId)}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => deleteAddress(address.addressId)}
                                                            className={ShopClass["r_edit"]}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className={ShopClass["addres_add_container"]}>
                                        <button
                                            type="button"
                                            className={ShopClass["addreess_add_button"]}
                                            onClick={() => setStep(2)}
                                        >
                                            Add Address
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={ShopClass["adrdress_from_container"]}>
                                    <div className={ShopClass["Add_header"]}>
                                        <h1 id={ShopClass["add_header_h2"]}>Address</h1>
                                    </div>
                                    <form
                                        onSubmit={handleSubmit(addAddress)}
                                        className={ShopClass["Address_form"]}
                                    >
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Username"
                                                {...register("userName", {
                                                    required: "Username is required",
                                                })}
                                            />
                                            {errors.userName && <p>{errors.userName.message}</p>}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Delivery Address"
                                                {...register("deliveryAddress", {
                                                    required: "Delivery Address is required",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9 ,.-]+$/,
                                                        message: "Invalid characters in Delivery Address",
                                                    },
                                                })}
                                            />
                                            {errors.deliveryAddress && (
                                                <p>{errors.deliveryAddress.message}</p>
                                            )}
                                        </div>

                                        <div>
                                            <input
                                                type="tel"
                                                placeholder="Mobile number"
                                                {...register("mobileNumber", {
                                                    required: "Mobile number is required",
                                                    pattern: {
                                                        value: /^\d{10}$/,
                                                        message:
                                                            "Please enter a valid 10-digit mobile number",
                                                    },
                                                })}
                                            />
                                            {errors.mobileNumber && (
                                                <p>{errors.mobileNumber.message}</p>
                                            )}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="City"
                                                {...register("city", {
                                                    required: "City is required",
                                                })}
                                            />
                                            {errors.city && <p>{errors.city.message}</p>}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Area"
                                                {...register("area", {
                                                    required: "Area is required",
                                                })}
                                            />
                                            {errors.area && <p>{errors.area.message}</p>}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="State"
                                                {...register("state", {
                                                    required: "State is required",
                                                })}
                                            />
                                            {errors.state && <p>{errors.state.message}</p>}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Pincode"
                                                {...register("pincode", {
                                                    required: "Pincode is required",
                                                })}
                                            />
                                            {errors.pincode && <p>{errors.pincode.message}</p>}
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Latitude"
                                                {...register("latitude", {
                                                    required: false,
                                                })}
                                            />
                                            {errors.latitude && <p>{errors.latitude.message}</p>}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Longitude"
                                                {...register("longitude", {
                                                    required: false,
                                                })}
                                            />
                                            {errors.longitude && <p>{errors.longitude.message}</p>}
                                        </div>
                                        <div className={ShopClass["shop_btns_a"]}>
                                            <button type="submit">Submit</button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setStep(1);
                                                    setEdit(false);
                                                    reset({
                                                        deliveryAddress: "",
                                                        userName: "",
                                                        mobileNumber: "",
                                                        city: "",
                                                        state: "",
                                                        area: "",
                                                        pincode: "",
                                                        latitude: "",
                                                        longitude: "",
                                                    });
                                                }}
                                            >
                                                Back
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageAddress;
