import React from 'react'
import HomeClasses from '../Home/home.module.css';
import moment from 'moment/moment';
const CommissionList = ({ typeLabel, commissions }) => {
    return (
        <div className={HomeClasses["commissions"]}>
            <h4 className={HomeClasses["commision_header"]}>{typeLabel}</h4>
            {commissions.map((commission) => (
                <div key={commission.comissionId} className={HomeClasses["commission-entry"]}>
                    <div className={HomeClasses["commission-details"]}>
                        <div className={HomeClasses["commisions_data"]}>
                            <div className={HomeClasses["payes-names"]}>
                                <h3>{commission.name}</h3>
                                <div className={HomeClasses.subdata}>
                                    Type: <p>{commission.paymentType}</p>
                                </div>
                                {commission.transactionNumber && (
                                    <div className={HomeClasses.subdata}>
                                        Transaction No: <p>{commission.transactionNumber}</p>
                                    </div>
                                )}
                            </div>
                            <div className={HomeClasses["payment-date"]}>
                                <p className={HomeClasses.subheader}>
                                    {moment(commission.paymentDate).format("YYYY/MM/DD")}
                                </p>
                                <p className={HomeClasses.golde}>
                                    ₹{commission.receivedPayement}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

}

export default CommissionList