import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Modal from 'react-modal'
import StyleClass from '../Home/TransportDetails/transport.module.css'
import { FaTruckMoving, FaUser } from 'react-icons/fa'
import { IoCall, IoClose } from 'react-icons/io5'
import { LiaClipboardListSolid } from 'react-icons/lia'
import { BiPlusCircle, } from 'react-icons/bi'
import { LuScale } from 'react-icons/lu'
import { RiCalendarEventFill } from 'react-icons/ri'
import { Services } from '../../Services';
import { Error, Success, SuccessSwal, warningSwal } from '../../components/utils/ToastMessages';
import { MdClose } from 'react-icons/md';
import { ShopServices } from './ShopServicess';
import { parseInt } from 'lodash';
import { ScaleLoader } from 'react-spinners';
const AddTransportModal = ({ isOpen, setIsOpen, onClose, edit, totalQuintalWeight, orderId, OrderDetails }) => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        setError
    } = useForm({
        defaultValues: {
            advance: 0
        }
    });
    const [transportDetails, setTransportDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [preBookingDropdown, setMillerDropdown] = useState([]);
    const [conformationDropdown, setConformationDropdown] = useState([]);
    const [selectedPreBooking, setSelectedPreBooking] = useState(null);
    const [confirmationPrice, setConfirmationPrice] = useState(null)
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        TransportNames();
        getConfigs();
    }, [])

    const selecteduser = watch("name");
    const preBooking = watch('preBooking');
    const postBooking = watch('postBooking');
    const total = watch('total');
    const advance = watch('advance');

    const TransportNames = async () => {
        try {
            const res = await Services.getTransports("GET", null, token);
            if (res.Status === 1) {
                setTransportDetails(res.transports);
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }
    const getConfigs = async () => {
        try {
            const res = await ShopServices.ConfigDropdowns("GET", null, token);
            if (res.Status === 1) {
                setDropdownOptions(res.config);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (dropdownOptions.length > 0) {
            const MCommisiondropdown = dropdownOptions?.find(
                (config) => config.configId === 12
            );
            if (MCommisiondropdown) {
                const configValue = MCommisiondropdown?.configValue;
                if (configValue.includes('-')) {

                    const [from, to] = configValue.split('-').map(Number);

                    if (!isNaN(from) && !isNaN(to) && from < to) {
                        let numbers = Array.from({ length: to - from + 1 }, (_, i) => from + i);
                        setMillerDropdown(numbers);
                    }
                }
            }
        }
    }, [dropdownOptions]);

    useEffect(() => {
        if (selectedPreBooking !== null && dropdownOptions.length > 0) {
            const ConforMation = dropdownOptions?.find(
                (config) => config.configId === 13
            );

            if (ConforMation) {
                const configValue = ConforMation?.configValue;

                if (!isNaN(configValue) && selectedPreBooking <= configValue) {
                    let numbers = Array.from({ length: configValue - selectedPreBooking + 1 }, (_, i) => selectedPreBooking + i);
                    setConformationDropdown(numbers);

                    if (edit && transportData?.transportCommissionPerQuintal) {
                        setConfirmationPrice(transportData?.transportCommissionPerQuintal);
                        setValue("transportCommissionPerQuintal", transportData?.transportCommissionPerQuintal);
                    }
                }
            }
        }
    }, [dropdownOptions, selectedPreBooking]);

    const handlePreBookingChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setSelectedPreBooking(value);
        console.log(value);
        if (accountType === 1) {
            setValue('preBooking', value * totalQuintalWeight);
        } else {
            setValue('preBooking', value * totalQuintalWeight);
        }
    };

    useEffect(() => {
        if (preBooking && confirmationPrice) {
            let price = confirmationPrice * totalQuintalWeight;
            setValue('postBooking', price);
        }
        if (preBooking && postBooking) {
            const difference = postBooking - preBooking;
            setValue('differenceBooking', difference > 0 ? difference : 0);
        }

        if (accountType == 5) {
            if (postBooking) {
                setValue('total', postBooking);
            }
        } else {
            if (preBooking) {
                setValue('total', postBooking);
            }
        }

        if (accountType === 5) {
            if (postBooking) {
                const balanceAmount = postBooking - (advance ? advance : 0);
                setValue('balance', balanceAmount);
            }
        } else {
            if (preBooking) {
                const balanceAmount = preBooking - (advance ? advance : 0);
                setValue('balance', balanceAmount);
            }
        }

    }, [preBooking, postBooking, confirmationPrice, advance, setValue]);


    const onSubmit = async (data) => {
        setLoading(true)
        if (data.advance === null || data.advance === undefined) {
            data.advance = 0;
        }

        if (accountType === 1 && data.advance > data.preBooking) {
            setError("advance", {
                type: "manual",
                message: "Advance amount must be less than the Booking(LF) amount"
            });
            return;
        }

        if (accountType === 5 && data.advance > data.postBooking) {
            setError("advance", {
                type: "manual",
                message: "Advance amount must be less than the total amount"
            });
            return;
        }

        if (accountType === 1) {
            let body = {
                orderId: orderId,
                transportDetails: data
            }
            try {
                const res = await Services.UpdateOrder("POST", JSON.stringify(body), token);
                if (res.Status === 1) {
                    Success(res.Message);
                    setIsOpen(false);
                    setLoading(false);
                    OrderDetails();
                } else if (res.Status === 0) {
                    Error("warning", res.Message);
                }
            } catch (err) {
                console.log(err)
            }
        } else {
            localStorage.setItem("Transport Details", JSON.stringify(data));
            SuccessSwal("Success", "Transport Details Added Successfully");
            setIsOpen(false);
        }
    };
    let transportData = JSON.parse(localStorage.getItem("Transport Details"));
    // console.log(transportData)
    useEffect(() => {
        if (edit) {
            if (transportDetails.length > 0) {
                if (transportData) {

                    if (transportData?.transportCommissionPerQuintal) {
                        setSelectedPreBooking(transportData?.MillerPerQuintal);
                    }
                    if (transportData?.transportCommissionPerQuintal) {
                        setConfirmationPrice(transportData?.transportCommissionPerQuintal);
                    }

                    Object.keys(transportData).forEach(key => setValue(key, transportData[key]));
                }
            }
        }
    }, [edit, transportDetails, setValue]);
    const accountType = parseInt(localStorage.getItem("AccountType"), 10);

    return (
        <div className={StyleClass.modal} onClick={onClose}>
            <div className={StyleClass["modal-content"]} onClick={openhandler}>
                <div className={StyleClass["modal-content-header"]}>
                    <div>
                        <h3>{edit ? "Update Transport Details" : "Add Transport Details"}</h3>
                    </div>
                    <button
                        className={StyleClass["close-btn"]}
                        onClick={onClose}
                    >
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>

                <div className={StyleClass["form_wrapper"]}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="name">Name</label>
                            <select
                                placeholder='Name'
                                {...register("name", {
                                    required: "this field is required",
                                    validate: (value) =>
                                        value.trim() !== "" || "Name cannot start with spaces"
                                })}

                            >
                                <option value="">Select Username</option>
                                {transportDetails.map((item) => (
                                    <option key={item.name} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <FaUser className={StyleClass.inputicon} />
                            {errors.name && <span>{errors.name.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="vehicleNumber">Vehicle Number</label>
                            <input
                                placeholder='Vehicle Number '
                                {...register("vehicleNumber", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/,
                                        message: "Invalid vehicle number format. Format should be like MH12AB1234"
                                    },
                                    onChange: (e) => {
                                        const upperCaseValue = e.target.value.toUpperCase();
                                        setValue('vehicleNumber', upperCaseValue, { shouldValidate: true });
                                    },
                                })}
                                maxLength={10}
                            />
                            <FaTruckMoving className={StyleClass.inputicon} />
                            {errors.vehicleNumber && <span>{errors.vehicleNumber.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="driverNumber">Driver Number</label>
                            <input
                                placeholder='Driver Number'
                                {...register("driverNumber", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: "Mobile number must start with 6-9 and be exactly 10 digits"
                                    }
                                })}
                                maxLength={10}
                            />

                            <IoCall className={StyleClass.inputicon} />
                            {errors.driverNumber && <span>{errors.driverNumber.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="preBooking">Booking Rate (LF)</label>
                            <input
                                type='number'
                                step="0.01"
                                placeholder='Booking Rate (LF)'
                                {...register("preBooking", {
                                    required: "this field is required",
                                    valueAsNumber: true
                                })}
                                min={1}
                                readOnly
                            />
                            <div className={StyleClass["dropdown"]}>
                                <select className={StyleClass.dropdwons}
                                    value={selectedPreBooking || transportData?.MillerPerQuintal}
                                    {...register("MillerPerQuintal", {
                                        required: false,
                                        valueAsNumber: true,
                                        onChange: {
                                            handlePreBookingChange
                                        },
                                    })}
                                    onChange={handlePreBookingChange}>
                                    <option value="">Select Price</option>
                                    {preBookingDropdown.map((item) => (
                                        <option key={item} value={item}>
                                            ₹{item}per Quintal
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <LiaClipboardListSolid className={StyleClass.inputicon} />
                            {errors.preBooking && <span>{errors.preBooking.message}</span>}
                        </div>
                        {
                            accountType !== 1 && <>
                                <div className={StyleClass["form-control"]}>
                                    <label htmlFor="preBooking">Confirmation Rate (LF)</label>
                                    <input
                                        type='number'
                                        step="0.01"
                                        placeholder='Confirmation Rate (LF)'
                                        {...register("postBooking", {
                                            required: "this field is required",
                                            valueAsNumber: true
                                        })}
                                        min={1}
                                        readOnly={true}
                                    />
                                    <div className={StyleClass["dropdown"]}>
                                        <select className={StyleClass.dropdwons}
                                            value={confirmationPrice || transportData?.transportCommissionPerQuintal}
                                            {...register("transportCommissionPerQuintal", {
                                                required: false,
                                                valueAsNumber: true,
                                                onChange: (e) => {
                                                    setConfirmationPrice(e.target.value);
                                                },
                                            })}
                                        >
                                            <option value="">Select Price</option>
                                            {conformationDropdown.map((item) => (
                                                <option key={item} value={item}>
                                                    ₹{item}per Quintal
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <LiaClipboardListSolid className={StyleClass.inputicon} />
                                    {errors.postBooking && <span>{errors.postBooking.message}</span>}
                                </div>
                                <div className={StyleClass["form-control"]}>
                                    <label htmlFor="preBooking">Difference Rate (LF)</label>
                                    <input
                                        type='number'
                                        step="0.01"
                                        placeholder='Difference Rate (LF)'
                                        {...register("differenceBooking", {
                                            required: "this field is required",
                                            valueAsNumber: true
                                        })}
                                        disabled
                                        min={1}
                                    />
                                    <LiaClipboardListSolid className={StyleClass.inputicon} />

                                    {errors.differenceBooking && <span>{errors.differenceBooking.message}</span>}
                                </div>
                                <div className={StyleClass["form-control"]}>
                                    <label htmlFor="preBooking">Total (LF)</label>
                                    <input
                                        type='number'
                                        step="0.01"
                                        placeholder='Total (LF)'
                                        {...register("total", {
                                            required: "this field is required",
                                            valueAsNumber: true
                                        })}
                                        readOnly
                                        min={1}
                                    />
                                    <BiPlusCircle className={StyleClass.inputicon} />
                                    {errors.total && <span>{errors.total.message}</span>}
                                </div>
                            </>
                        }
                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="preBooking">Advance (LF)</label>
                            <input
                                type='number'
                                step="0.01"
                                min={0}
                                placeholder='Advance (LF)'
                                {...register("advance", {
                                    required: false,
                                    valueAsNumber: true,
                                    pattern: {
                                        value: /^[0-9]\d*(\.\d{1,2})?$/,
                                        message: "Only numeric values are allowed with two decimal places"
                                    },
                                })}
                            />
                            <RiCalendarEventFill className={StyleClass.inputicon} />
                            {errors.advance && <span>{errors.advance.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <label htmlFor="preBooking">Balance ( LF)</label>
                            <input
                                type='number'
                                step="0.01"
                                placeholder='Balance ( LF)'
                                {...register("balance", {
                                    required: "this field is required",
                                    valueAsNumber: true
                                })}
                                readOnly
                                min={1}
                            />
                            <LuScale className={StyleClass.inputicon} />
                            {errors.balance && <span>{errors.balance.message}</span>}
                        </div>
                        <div className={StyleClass["Addtransport"]} >
                            <button type='submit' className={StyleClass.button}>
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default AddTransportModal