import { method } from "lodash";
import { ToastError, warningSwal } from "../../components/utils/ToastMessages";

const httpcall = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      ...token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data?.Status === 0 && data?.isTokenExpired === 1) {
        warningSwal("Warning", data?.Message);
        localStorage.clear();
        window.location.href = "/login";
      } else if (data?.Status === 0) {
        warningSwal("Warning", data?.Message);
        if (data?.Message === "Token expired") {
          window.location.href = "/";
          localStorage.clear();
        }
      }
      // ToastError("Your  session expired!,  please  login");
      // window.location.href = "/login";

      // return {Status:0,Message:''}
      return data;
    });
};

const PurchaseStatus = (method, body, token, status) => {
  return httpcall(
    `distributor/getUserOrdersStatus/${status}`,
    method,
    body,
    token
  );
};
const ReOrder = (method, body, token, id) => {
  return httpcall(`distributor/reOrder/${id}`, method, body, token);
};

const CancelOrder = (method, body, token) => {
  return httpcall("distributor/cancelOrder", method, body, token);
};

const PurchasePendingPayments = (method, body, token) => {
  return httpcall("distributor/getUserPendingPayments", method, body, token);
};

const Downloadsalesreports = (method, body, token) => {
  return httpcall("distributor/downLoadSalesReports", method, body, token);
};

export const PurchaseService = {
  PurchaseStatus,
  ReOrder,
  CancelOrder,
  PurchasePendingPayments,
  Downloadsalesreports,
};
