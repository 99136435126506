import React, { useEffect, useState } from "react";
import ShopClass from "./purchase.module.css";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";

import NoAddress from "../../assets/noAddress.png";
import { Success } from "../../components/utils/ToastMessages";
import { ShopServices } from "../Shop/ShopServicess";
import { RxCross2 } from "react-icons/rx";

const AgentAddressChange = ({ orderId, isOpen, setIsOpen, orderdetails, onClose }) => {
    const [agents, setAgents] = useState([]);
    const [agentAddress, setAgentAddress] = useState([]);
    const Navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [isSelected, setIsSelected] = useState(false);
    const userId = localStorage.getItem("agentId");

    useEffect(() => {
        getAgents();
    }, []);

    useEffect(() => {
        getAgentAddress(userId)
    }, [userId]);

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAgents = async () => {
        try {
            const res = await Services.getOwnUsers("GET", null, token);
            if (res.Status === 1) {
                const filteredUsers = res.ownUsers.filter(user => user.accountType !== 5 && user.accountType !== 6);
                setAgents(filteredUsers);
            }
        } catch (err) {
            console.error(err);
        }
    };
    // Agent Address

    const getAgentAddress = async () => {
        try {
            const res = await ShopServices.AgentAddress("GET", null, token, userId);
            if (res.Status === 1) {
                setAgentAddress(res.addresses);
            }
        } catch (err) {
            console.error(err);
        }
    };


    const AddressChange = async (address) => {
        let body = {
            orderId: orderId,
            orderFor: userId,
            deliveryAddress: address
        }
        try {
            const res = await Services.UpdateOrder("POST", JSON.stringify(body), token)
            if (res.Status === 1) {
                Success(res.Message);
                onClose();
                orderdetails();
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (

        <div className={ShopClass["reject_modal"]}>
            <div
                onClick={() => onClose()}
                className={ShopClass["overlaye"]}
            ></div>
            <div className={ShopClass["address_Modal_content"]}>
                <div className={ShopClass["modal-content-header"]}>
                    <h1>Select User</h1>
                    <button type="button" onClick={() => onClose()} className={ShopClass["close-btn"]}><RxCross2 size={20} /></button>
                </div>
                <div className={ShopClass["address_container"]}>
                    <div className={ShopClass["address_container_2"]}>
                        {step === 1 ? (
                            <div className={ShopClass["address_container_3"]}>
                                {agents.map((agent, index) => {
                                    return (
                                        <div className={ShopClass["address_box"]} key={index}>
                                            <div className={ShopClass["check_address"]}>
                                                <input
                                                    name="user"
                                                    type="radio"
                                                    className={ShopClass["address_input"]}
                                                    onClick={() => {
                                                        localStorage.setItem("agentId", agent.userId);
                                                        setIsSelected(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={ShopClass["agent_boxes_container"]}>
                                                <div className={ShopClass["user_image_container"]}>
                                                    {agent.Photo !== "NA" ? (
                                                        <img
                                                            className={ShopClass["group_user_image"]}
                                                            src={agent.Photo}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <div className={ShopClass["group_user_initial"]}>
                                                            {agent.name
                                                                ? agent.name.charAt(0)
                                                                : ""}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={ShopClass["group_user_info"]}>
                                                    <p className={ShopClass["group_user_name"]}>
                                                        {agent.name}
                                                    </p>
                                                    <p id={ShopClass["group_user_number"]}>
                                                        {agent.mobileNumber}
                                                    </p>
                                                    <p id={ShopClass["group_user_type"]}>
                                                        {agent.accountType === 1
                                                            ? "Manufacturer  "
                                                            : agent.accountType === 2
                                                                ? "Distributor  "
                                                                : agent.accountType === 3
                                                                    ? "Retailer"
                                                                    : agent.accountType === 4
                                                                        ? "Marketing Specialist"
                                                                        : agent.accountType === 5
                                                                            ? "Agent"
                                                                            : agent.accountType === 6
                                                                                ? "Office Team "
                                                                                : "null"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className={ShopClass["addres_add_container"]}>
                                    <button
                                        type="button"
                                        disabled={!isSelected}
                                        className={ShopClass["addreess_add_button"]}
                                        onClick={() => setStep(2)}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={ShopClass["address_container_3"]}>
                                    {agentAddress.length === 0 ? (
                                        <div className={ShopClass["empty_products"]}>
                                            <div className={ShopClass["empty_image_container"]}>
                                                <img
                                                    className={ShopClass["empty_image"]}
                                                    src={NoAddress}
                                                    alt="image"
                                                />
                                            </div>
                                            <p className={ShopClass["empty_portal_1"]}>
                                                No Address Found!, Please Ask Your Client to Add
                                                Addresses For Placing The Order.
                                            </p>
                                        </div>
                                    ) : (
                                        agentAddress.map((address, index) => {
                                            return (
                                                <div className={ShopClass["address_box"]} key={index}>
                                                    <div className={ShopClass["check_address"]}>
                                                        <input
                                                            type="radio"
                                                            className={ShopClass["address_input"]}
                                                            onClick={() => AddressChange(address.deliveryAddress)}
                                                        />
                                                    </div>
                                                    <div className={ShopClass["adress_boxeses"]}>
                                                        <p className={ShopClass["a_address"]}>
                                                            {address.deliveryAddress}
                                                        </p>
                                                        <p className={ShopClass["a_address_2"]}>
                                                            Phone Number: {address.mobileNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentAddressChange;
