import React, { useEffect, useState } from 'react'
import StyleClass from './transport.module.css'
import { TbUserFilled } from 'react-icons/tb';
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaEdit, FaUser } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im'
import { GoArrowLeft } from 'react-icons/go';
import { MdBusinessCenter, MdDelete } from 'react-icons/md'
import { Services } from '../../../Services';
import { SuccessSwal, warningSwal } from '../../../components/utils/ToastMessages';
import DeleteModal from './DeleteModal';

const TranportDetails = () => {
    const [step, setStep] = useState(1);
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        TransportNames();
    }, [])

    const TransportNames = async () => {
        try {
            const res = await Services.getTransports("GET", null, token);
            if (res.Status === 1) {
                setData(res.transports);
            } else if (res.Status === 0) {
                warningSwal("warning", res.Message);
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const AddTransport = () => {
        setStep(2);
        setId(null)
        reset({
            name: "",
            mobileNumber: "",
            bussinessName: "",
            address: ""
        });
        setEdit(false);
    }

    const modalopen = (id) => {
        setIsOpen(true);
        setSelectedId(id);
    }

    const onSubmit = async (data) => {

        if (id && edit) {
            try {
                const res = await Services.EditTransport("PATCH", JSON.stringify(data), token, id);
                if (res.Status === 1) {
                    SuccessSwal("Transport Updated successfully", res.Message);
                    setStep(1);
                    reset({
                        name: "",
                        mobileNumber: "",
                        bussinessName: "",
                        address: ""
                    })
                    TransportNames();
                } else if (res.Status === 0) {
                    warningSwal("Warning", res.Message);
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            try {
                const res = await Services.AddTransport("POST", JSON.stringify(data), token);
                if (res.Status === 1) {
                    SuccessSwal("Transport added successfully", res.Message);
                    resetForm();
                    TransportNames();
                } else if (res.Status === 0) {
                    warningSwal("Warning", res.Message);
                }
            } catch (err) {
                console.error(err);
            }
        }
    };


    const editHandler = (transportId) => {
        setEdit(true);
        setId(transportId);
        setStep(2);
        const selectedTransport = data.find(item => item.transportId === transportId);
        reset(selectedTransport);
    };

    const resetForm = () => {
        reset({ name: "", mobileNumber: "", bussinessName: "", address: "" });
        setStep(1);
        setEdit(false);
        setId(null);
    };
    return (
        <div className={StyleClass['transport']}>
            {
                step === 1 ? (<>
                    <h2 className={StyleClass.header}>Transport Names</h2>
                    <div className={StyleClass['transport_details']}>
                        {data.map((detail, index) => (
                            <div key={index} className={StyleClass['transport_detail']}>
                                <div className={StyleClass.data}>
                                    <TbUserFilled />
                                    <h3>{detail.name}</h3>
                                </div>
                                <div className={StyleClass.data}>
                                    <IoCall />
                                    <p>{detail.mobileNumber}</p>
                                </div>
                                <div className={StyleClass.data}>
                                    <MdBusinessCenter />
                                    <p>{detail.bussinessName}</p>
                                </div>
                                <div className={StyleClass.data}>
                                    <FaLocationDot />
                                    <p>{detail.address}</p>
                                </div>
                                <div className={StyleClass["Actionbuttons"]}>
                                    <button type='button'
                                        onClick={() => editHandler(detail.transportId)}
                                        className={StyleClass["edit"]}>Edit</button>
                                    <button type='button'
                                        onClick={() => { modalopen(detail.transportId); console.log(isOpen) }}
                                        className={StyleClass["delete"]}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={StyleClass['Addtransport']}>
                        <button type='button'
                            onClick={AddTransport}
                            className={StyleClass["button"]}>Add</button>
                    </div>
                </>) : (<>
                    <div className={StyleClass["Add_head"]}>
                        <button
                            className={StyleClass["back_btn"]}
                            type="button"
                            onClick={() => {
                                resetForm();
                            }}
                        >
                            <GoArrowLeft size={15} />
                        </button>
                        <h2 className={StyleClass.headerItem}>{edit ? "Edit Transport" : "Add Transport"} </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={StyleClass["AddTransport_container"]}>
                        <div className={StyleClass["form-control"]}>
                            <input
                                placeholder='Name'
                                {...register("name", {
                                    required: "Name field is required",
                                    pattern: {
                                        value: /^[a-zA-Z\s]*$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                            />
                            <FaUser className={StyleClass.inputicons} />
                            {errors.name && <span>{errors.name.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <input
                                placeholder='Mobile Number'
                                {...register("mobileNumber", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: "Invalid mobile number"
                                    }
                                })}
                                maxLength={10}
                            />
                            <IoCall className={StyleClass.inputicons} />
                            {errors.mobileNumber && <span>{errors.mobileNumber.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <input
                                placeholder='Business Name'
                                {...register("bussinessName", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[a-zA-Z\s]*$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                            />
                            <MdBusinessCenter className={StyleClass.inputicons} />
                            {errors.bussinessName && <span>{errors.bussinessName.message}</span>}
                        </div>
                        <div className={StyleClass["form-control"]}>
                            <input

                                placeholder='Address'
                                {...register("address", {
                                    required: "this field is required",
                                    pattern: {
                                        value: /^[^\s][a-zA-Z0-9\s,.-]*$/,
                                        message: "Address cannot start with a space"
                                    }
                                })}
                            />
                            <ImLocation className={StyleClass.inputicons} />
                            {errors.address && <span>{errors.address.message}</span>}
                        </div>

                        <div className={StyleClass.Addbutton}>
                            <button type='submit' className={StyleClass["add"]}>Save</button>
                        </div>
                    </form>
                </>)
            }

            {isOpen &&
                <DeleteModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    setIsOpen={setIsOpen}
                    TransportNames={TransportNames}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                />}
        </div>
    )
}
export default TranportDetails