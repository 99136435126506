import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { GoArrowLeft } from "react-icons/go";
import ShopClass from "../Shop/shop.module.css";
import Layout from "../../components/Layout/layout";
import weightlogo from "../../assets/homeimgs/p2.png";
import { BiRupee } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import Image from '../../assets/noplot.png'
import Slider from "react-slick";

const Productdetails = () => {
  const { groupId, productId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${ShopClass.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  // Custom arrow component for previous button
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${ShopClass.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };


  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getProductView(groupId, productId);
  }, [groupId, productId]);

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const deleteProduct = addPermissions.filter(
    (item) => item.permissionName === "deleteProduct"
  );
  const addProduct = addPermissions.filter(
    (item) => item.permissionName === "editProduct"
  );
  const Permission = deleteProduct.length > 0 ? deleteProduct[0].status : 1;
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  const getProductView = async (groupId, productId) => {
    try {
      setLoading(false);
      const res = await Services.getSingleGroupProduct(
        "GET",
        null,
        token,
        groupId,
        productId
      );

      if (res.Status === 1) {
        setProductDetails(res.groupProduct);
      }
    } catch (err) {

      console.error(err);
    }
  };
  return (
    <Layout Active={"Shop"}>
      <div className={ShopClass["home_wrapper"]}>
        {loading ? (
          <div className={ShopClass["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={ShopClass["user_d_container"]}>
            <div className={ShopClass["user_d_header"]}>
              <button
                className={ShopClass["user_d_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={ShopClass["user_d_header_h1"]}> Product Details</h1>
            </div>
            <div className={ShopClass["product_container"]}>
              <div className={ShopClass["product_container_2"]}>
                <div className={ShopClass["product_container_header"]}>
                  <div className={ShopClass["prod_image_container"]}>
                    <Slider {...settings}>
                      {productDetails.productImageList && productDetails.productImageList.length > 0 ? (
                        productDetails.productImageList.map((item, index) => (
                          <div key={index} className={ShopClass["prod_image"]}>
                            <img
                              key={index}
                              className={ShopClass["prod_img"]}
                              src={item}
                              alt="image"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = Image;
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div className={ShopClass["prod_image"]}>
                          <img
                            className={ShopClass["prod_img"]}
                            src={Image}
                            alt="default image"
                          />
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>
                <div className={ShopClass["product_info"]}>
                  <p id={ShopClass["product_text_1"]}>
                    {productDetails.productName}
                  </p>
                  {productDetails.productBagWeightInKgs !== 0 &&
                    (
                      <p className={ShopClass["product_qty"]}>
                        {productDetails.productBagWeightInKgs}
                        KG'S/
                        {productDetails.productMeasuringUnit}
                      </p>
                    )}

                  <p id={ShopClass["product_text_3"]}>
                    {productDetails.status === 1 ? "In Stock" : "Out of Stock"}
                  </p>
                  <p id={ShopClass["product_price_box"]}>
                    <BiRupee />
                    {productDetails.price}/{productDetails.productPriceUnit}
                  </p>
                  {/* <p id={ShopClass["product_gst"]}>
                    GST({productDetails.gst}%)
                  </p> */}
                  <p id={ShopClass["product_text_4"]}>
                    <img
                      className={ShopClass["prod_details_logo"]}
                      src={weightlogo}
                      alt="image"
                    />
                    <span id={ShopClass["prod_details_text"]}>
                      <p id={ShopClass["prod_mesure_p"]}>Measuring Units</p>
                      <p id={ShopClass["prod_units"]}>
                        {productDetails.productPriceUnit}
                      </p>{" "}
                    </span>
                  </p>
                </div>
                <div className={ShopClass["prod_btn_conatiner"]}>
                  {/* <button
                    type="button"
                    className={ShopClass["prod_action_edit"]}
                  >
                    Add To Cart
                  </button>  */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Productdetails;
